const initialState = {
	schema_info:[],
	drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	login:{
		sts:"",
		msg:"",
		payload:"",
		token:"",
		error:[]
	},
	attendance_selected:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	add_update:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	user_add_update:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	on_selected:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	user_get_data:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	search:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"_id",
		sort_order:-1
	},
	delete:{
		ids:[],
		sts: "",
		msg:"",
		error:""
	},
	upload:{
		sts:"",
		msg:"",
		rslt:"",
	},
	import:{
		sts:"",
		msg:"",
		rslt:""
	},
	sidebar:{
		sidebar: false,
		pro_mode:"ADD",
		pro_id:null,
	},
	get_grands:{
		sts: "2",
		msg:"2",
		rslt:"2",
		error:"2"
	},
	change_pwd:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	forgot_sent_otp:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	forgot_validate_otp:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	reset_pwd:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	inattendance:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	check_in_out:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	prospect_status_update:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	change_log_search:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		header:0,
	},
	change_log_get_info:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	change_log_addUpdate:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	birthday_info:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	process_auth:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	otp_validate:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
}

const user = (state = initialState, action) => {
	switch (action.type) {
		case "get_schema":
			return {...state,
				schema_info:action.rslt,
			}
		case "get_common":
			return {...state,
				drop_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "login":
			return {...state,
				login:{
					sts:action.sts,
					msg:action.msg,
					payload:action.payload,
					token:action.token,
					error:action.error
				}
			}
		case "logout": {
			return {...state,
				login:{
					payload:action.payload,
					token:action.token
				}
			}
		}
		case "validate": {
			return state
		}
		case "attendance_selected":
			return {...state,
				attendance_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "add_update":
			return {...state,
				add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "user_add_update":
			return {...state,
				user_add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "get_data":
			return {...state,
				add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "on_selected":
			return {...state,
				on_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "user_get_data":
			return {...state,
				user_get_data:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "search":
			return {...state,
				search:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
				}
			}
		case "delete":
			return {...state,
				delete:{
					sts:action.sts,
					msg:action.msg,
					error:action.error
				}
			}
		case "upload":
			return {...state,
				upload:{
					sts: action.sts,
					msg:action.msg,
					rslt:action.rslt,
				}
			}
		case "import":
			return {...state,
				import:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt
				}
			}
		case "sidebar":
			return {...state,
				sidebar:{
					sts: action.sidebar,
					pro_mode:action.pro_mode,
					pro_id:action.pro_id,
				}
			}
		case "get_grands":
			return {...state,
				delete:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "change_pwd":
			return {...state,
				change_pwd:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "forgot_sent_otp":
			return {...state,
				forgot_sent_otp:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "forgot_validate_otp":
			return {...state,
				forgot_validate_otp:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "reset_pwd":
			return {...state,
				reset_pwd:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "inattendance":
			return {...state,
				inattendance:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "check_in_out":
			return {...state,
				check_in_out:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "prospect_status_update":
			return {...state,
				prospect_status_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "change_log_search":
			return {...state,
				change_log_search:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "change_log_get_info":
			return {...state,
				change_log_get_info:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "change_log_addUpdate":
			return {...state,
				change_log_addUpdate:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "birthday_info":
			return {...state,
				birthday_info:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "process_auth":
			return {...state,
				process_auth:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "otp_validate":
			return {...state,
				otp_validate:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		default:
			return state
	}
}  
export default user