
export const login = (state = { userRole: "admin" }, action) => {
  switch (action.type) {
  
    case "login": {
      return { ...state, values: action.payload }
    }
    case "logout": {
      return { ...state, values: action.payload,token:action.token}
    }
    case "validate": {
      return state
    }
    default: {
      return state
    }
  }
}
