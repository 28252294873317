const initialState = {
	schema_info:[],
	drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[],
		userId:"",
	},
	add_update:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	team_filter:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		mode:"",
	},
	team_on_change:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	on_selected:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	search:{
		sts:"",
		msg:"",
		loading:true,
		rslt:[],
		header:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"_id",
		sort_order:-1,
		error:{},
		userId:"",
		user_mode:"",
	},
	delete:{
		ids:[],
		sts: "",
		msg:"",
		error:""
	},
	log_data:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
	},
	sidebar:{
		sidebar: false,
		pro_mode:"ADD",
		pro_id:null,
	},
}

const met_review = (state = initialState, action) => {
	switch (action.type) {
		case "get_schema":
			return {...state,
				schema_info:action.rslt,
			}
		case "get_common":
			return {...state,
				drop_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					userId:action.userId,
				}
			}
		case "add_update":
			return {...state,
				add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "get_data":
			return {...state,
				add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}		
		case "team_filter":
			return {...state,
				team_filter:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					mode:action.mode,
				}
			}
		case "team_on_change":
			return {...state,
				team_on_change:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
				}
			}
		case "on_selected":
			return {...state,
				on_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "search":
			return {...state,
				search:{
					sts:action.sts,
					msg:action.msg,
					error:action.error,
					loading:false,	
					rslt: action.rslt,
					header:action.header,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
					userId:action.userId,
					user_mode:action.user_mode,
				}
			}
		case "delete":
			return {...state,
				delete:{
					sts:action.sts,
					msg:action.msg,
					error:action.error
				}
			}
		case "log_data":
			return {...state,
				log_data:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,
					log_schema:action.log_schema,
					expected_schema:action.expected_schema,
					review_schema:action.review_schema,
				}
			}
		case "sidebar":
			return {...state,
				sidebar:{
					sts: action.sidebar,
					pro_mode:action.pro_mode,
					pro_id:action.pro_id,
				}
			}            
		default:
			return state
	}
}  
export default met_review