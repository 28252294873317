const initialState = {
	dump_schema_info:[],
	upload_schema_info:[],
	drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	assign_drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	search:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"_id",
		sort_order:-1
	},
	upload:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	import:{
		sts:"",
		msg:"",
		upload_id:"",
		upd_count:[],
		upd_rslt:[],
		can_process:false,
		error:""
	},
	process_import:{
		sts:"",
		msg:"",
		rslt:"",
	},
	assign_search:{
		sts:"",
		msg:"",
		rslt:"",
		error:"",
		log_id:""
	},
	process_assign:{
		sts:"",
		msg:"",
		rslt:"",
		error:"",
		total_count:0,
		user_count:0
	},
	on_selected:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	map_data:{
		sts:"",
		msg:"",
		rslt:"",
		err:""
	},
	map_download:{
		sts:"",
		msg:"",
		rslt:"",
	},
	untouched:{
		sts:"",
		msg:"",
		rslt:"",
		header:"",
		error:""
	},
	assign_rollback_search:{
		sts:"",
		msg:"",
		rslt:[],
		header:{},
		error:{}
	},
	assign_rollback_view:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	assign_rollback:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	upload_rollback_view:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	upload_rollback:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	assign_log_search:{
		sts:"",
		msg:"",
		rslt:"",
		header:"",
		error:""
	},
	get_assign_common_drop:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	// ON SELECTED - DUMMY
	get_on_selected:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	upload_count_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:""
	},
	delete_log:{
		sts:"",
		msg:"",
		rslt:[],
		error:""
	},
	// ON SELECTED - DUMMY
	upload_get_on_selected:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
}

const dump_data = (state = initialState, action) => {
	switch (action.type) {
		case "get_dump_schema":
			return {...state,
				dump_schema_info:action.rslt,
			}
		case "get_upload_schema":
			return {...state,
				upload_schema_info:action.rslt,
			}
		case "get_common":
			return {...state,
				drop_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "get_assign_common":
			return {...state,
				assign_drop_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "search":
			return {...state,
				search:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
				}
			}
		case "upload":
			return {...state,
				upload:{
					sts: action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "import":
			return {...state,
				import:{
					sts:action.sts,
					msg:action.msg,
					upload_id:action.upload_id,
					upd_count:action.upd_count,
					upd_rslt:action.upd_rslt,
					can_process:action.can_process,
					error:action.error
				}
			}
		case "process_import":
			return {...state,
				process_import:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt
				}
			}
		case "assign_search":
			return {...state,
				assign_search:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					log_id:action.log_id
				}
			}
		case "on_selected":
			return {...state,
				on_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "process_assign":
			return {...state,
				process_assign:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "map_data":
			return {...state,
				map_data:{
					sts: action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "map_download":
			return {...state,
				map_download:{
					sts: action.sts,
					msg:action.msg,
					rslt:action.rslt,
				}
			}
		case "untouched":
			return {...state,
				untouched:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "assign_rollback_search":
			return {...state,
				assign_rollback_search:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "assign_rollback_view":
			return {...state,
				assign_rollback_view:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "assign_rollback":
			return {...state,
				assign_rollback:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "upload_rollback_view":
			return {...state,
				upload_rollback_view:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "upload_rollback":
			return {...state,
				upload_rollback:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "assign_log_search":
			return {...state,
				assign_log_search:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "get_assign_common_drop":
			return {...state,
				get_assign_common_drop:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
	
		case "upload_count":
			return {...state,
				upload_count_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "delete_log":
			return {...state,
				delete_log:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "get_on_selected":
			return {...state,
				get_on_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "upload_get_on_selected":
			return {...state,
				upload_get_on_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		default:
			return state
	}
}
export default dump_data