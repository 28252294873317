const initialState = {
	team_filter:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		mode:"",
	},
	team_on_change:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	get_info:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	save_todo:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
	},
	save_todo_month:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
	},
}

const todo = (state = initialState, action) => {
	switch (action.type) {
		case "team_filter":
			return {...state,
				team_filter:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					mode:action.mode,
				}
			}
		case "team_on_change":
			return {...state,
				team_on_change:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
				}
			}
		case "get_info":
			return {...state,
				get_info:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "save_todo":
			return {...state,
				save_todo:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
				}
			}
		case "save_todo_month":
			return {...state,
				save_todo_month:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
				}
			}			
		default:
			return state
	}
}  
export default todo