const initialState = {
	get_grands:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	is_granted:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
}

const grands = (state = initialState, action) => {
	switch (action.type) {
		case "get_grands":
			return {...state,
				get_grands:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			} 
		case "is_granted":
			return {...state,
				is_granted:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		default:
			return state
	}
}  
export default grands