import React from 'react'
import * as strap from "reactstrap"
import * as icon from "react-feather"
import Avatar from "../../components/@vuexy/avatar/AvatarComponent"
import { get_process_env } from '../../redux/actions/a_common/init'
const api_url = get_process_env().slice(0, -4)

//CONVERSATION lIST CARD RENDER
export const conversation_list = ({keys, convo_itm, convoRowClick, onlineUser}) => {
	var today        	 = new Date();
	var toDate       	 = today.toISOString()
	var condi_date_1 	 = toDate.substring(0, 10)
	var item_date        = convo_itm.date
	var condi_date_2     = item_date.substring(0, 10)
	var time_date_format = [];
	if( condi_date_1 === condi_date_2){
		var time         = convo_itm.date
		var indian_stand_time = new Date(time).toLocaleString('en-GB', {timeZone: 'Asia/Kolkata'});
		time_date_format = indian_stand_time.slice(11, 17)
	}else{       
		var date = new Date(convo_itm.date)
		time_date_format = date.toLocaleDateString('en-GB')
	}

	//AVATAR
	var matches = convo_itm.user_rslt.name.match(/\b(\w)/g);
	if(matches.length === 1){ 
		matches = [convo_itm.user_rslt.name.substring(0,2)]
	};
	var avatar = matches.join('');
	avatar     = avatar.replace(/[#_]/g,"").substring(0, 2).toUpperCase();

	var online = onlineUser.includes(convo_itm.user_rslt._id) //SHOW ONLINE USER
	
	return(
		<strap.Card key={keys} onClick={() => convoRowClick(convo_itm)} style={{width:'240',backgroundColor:'white',borderRadius:'5px',cursor:'pointer', margin:'0 0 6px 0',borderBottom:'2px solid #b8b894', padding:'8px 5px 5px 5px'}}> 
			<strap.Row style={{display:'flex', alignItems:'center'}}>
				<strap.Col md={2} sm={2} xs={2}>
				{convo_itm.type === 'chat' ?
					<>{online
					? <span style={{margin:'3px',height:'11px',width:'11px',backgroundColor:'#39e600',borderRadius:'50%',display:'inline-block', position:'absolute',zIndex:'99'}}></span> 
					: <span style={{margin:'3px',height:'11px',width:'11px',backgroundColor:'#ff1a1a',borderRadius:'50%',display:'inline-block', position:'absolute',zIndex:'99',border:'1px solid white'}}></span> 
					}</> : null }
					<Avatar className='avatar-md' color="primary" content={avatar}/>
				</strap.Col>
				<strap.Col md={10} sm={10} xs={10}>
					<strap.Row style={{display:'flex',justifyContent:'flex-start',margin:'0 0 -6px -15px', color:'#3c3c3c', fontWeight:'600',}}>
						<strap.Col md={9} sm={9} xs={9} style={{padding:'0'}}>
							<h6 style={{fontWeight:'500'}} >{convo_itm.user_rslt.name.slice(0,20)}</h6>
						</strap.Col>	
						{convo_itm.unread !== 0 ? 
							<strap.Col md={1} sm={1} xs={1} style={{padding:'0',display:'flex',justifyContent:'flex-end'}} >
								<strap.Badge size='20' style={{fontSize:'13px',backgroundColor:'#00e600',color:'white',fontWeight:'550', padding:'1px 9px', marginRight:'-42px',minWidth:'34px',height:'16px'}}>{convo_itm.unread}</strap.Badge>
							</strap.Col>
						: null}	
					</strap.Row>
					<strap.Row style={{display:'flex',justifyContent:'flex-start',margin:'0 0 0 -15px', color:'#3c3c3c'}}>
						<strap.Col md={10} sm={10} xs={10} style={{padding:'0'}}> 
							{convo_itm.msgType === 2 
								? <p style={{display:'flex',fontWeight:'400',justifyContent:'flex-start',margin:'0px',color:'grey',fontSize:'13px'}}><icon.FileText size='17' style={{margin:'2px 3px 3px 0'}}/>{convo_itm.lastMessage.slice(0,22)}</p>
								: <p style={{display:'flex',fontWeight:'400',justifyContent:'flex-start',margin:'0px',color:'grey',fontSize:'13px'}}>{convo_itm.lastMessage.slice(0,22)}</p>
							}
						</strap.Col>
						<strap.Col md={1} sm={1} xs={1} style={{display:'flex',justifyContent:'flex-end'}}>
							<span style={{fontSize:'13px',color:'grey',marginRight:'-25px'}}>{time_date_format}</span>
						</strap.Col>
					</strap.Row>
				</strap.Col>
			</strap.Row>
		</strap.Card>
	)
}

//USER LIST CARD RENDER
export const user_list = ({keys,user_itm,userRowClick}) => {
	var matches  = user_itm.name.match(/\b(\w)/g);
		if(matches.length === 1){ 
			matches = [user_itm.name.substring(0,2)]
		};
	var avatar = matches.join('');
	avatar     = avatar.replace(/[#_]/g,"").substring(0, 2).toUpperCase();

	var user_name = user_itm.display_name.split("-") 
	return(
		<strap.Card key={keys} onClick={() => userRowClick(user_itm)} style={{width:'240',backgroundColor:'white',borderRadius:'5px',cursor:'pointer', margin:'0 0 6px 0',borderBottom:'2px solid #adad85', padding:'5px'}}> 
			<strap.Row style={{display:'flex', alignItems:'center'}}>
				<strap.Col md={2} sm={2} xs={2}>
					<Avatar className='avatar-md' color="primary" content={avatar}/>
				</strap.Col>
				<strap.Col md={10} sm={10} xs={10}>
					<strap.Row style={{display:'flex',justifyContent:'flex-start',margin:'0 0 -8px -15px', color:'#3c3c3c', fontWeight:'600'}}>
						<h6>{user_name[0]}</h6> 
					</strap.Row>
					<strap.Row style={{display:'flex',justifyContent:'flex-start',margin:'0 0 0 -15px', color:'#3c3c3c'}}>
						<span style={{fontSize:'12px',fontWeight:'500', color:'#8c8c8c'}}>{user_name[1]}</span> 
					</strap.Row>
				</strap.Col>
			</strap.Row>
		</strap.Card>
	)
}

//AVATAR
export const avatar = (name) => {
	var matches  = name.match(/\b(\w)/g);
		if(matches.length === 1){ 
			matches = [name.substring(0,2)]
		};
	var avatar    = matches.join('');
	return avatar = <span style={{color:'#ff704d', fontWeight:'bold', cursor:'default'}}>{avatar.replace(/[#_]/g,"").substring(0, 2).toUpperCase()}</span>
}

export const chatAvatar = (name) => {
	var matches  = name.match(/\b(\w)/g);
		if(matches.length === 1){
			matches = [name.substring(0,2)]
		};
	var avatar    = matches.join('');
	return avatar = <span key={Math.random()} style={{color:'#ff704d', fontWeight:'bold', cursor:'default'}}>{avatar.replace(/[#_]/g,"").substring(0, 2).toUpperCase()}</span>
}

//CHAT MSG BUBBLE
export const msg_bubble = ({message, user_id, get_msg_details, convo_snd_msg, convo_itm, userlist_snd_msg, create_grp_snd_msg }) => {
	var bubble_time       = message.date
	var indian_stand_time = new Date(bubble_time).toLocaleString('en-GB', {timeZone: 'Asia/Kolkata'});
	var msg_bubble_time   = indian_stand_time.slice(11, 17)//TIME
	//DETECT LINK
	var expression = /[-a-zA-Z0-9@:%._]{1,256}[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_~#?&=]*)?/gi;
	var regex      = new RegExp(expression);

	//CENTER NOTIFICATION FOR ADD/REMOVE USER
	if(message.info === 2){
		return(
			<>
			<strap.Row style={{ display:'flex', justifyContent:'center'}}>
				<div style={{display:'flex', justifyContent:'center',fontSize:'11px',padding:'7px',minWidth:'80px',minHeight:'10px',maxWidth:'300px', textAlign:'center', color:'black'}}>
					{message.message}
				</div>
			</strap.Row>
			</>
		)
	}else
	if(message.sender === user_id){ // MESSAGE BUBBLE - RIGHT
		if(message.msgType === 1){
			if(message.message.match(regex)){
				return(
					<strap.Row style={{marginRight:'15px', display:'flex', justifyContent:'flex-end'}}>
						<strap.Col md='12' sm='12' xs='12' style={{display:'flex', justifyContent:'flex-end', padding:'0',fontSize:'11px'}}>{msg_bubble_time}
							<icon.Eye className={`${(convo_snd_msg && convo_itm.type === 'group') || (create_grp_snd_msg) ? "show" : "hidden" }`} size='14' type="button" style={{margin:'0 0 3px 3px'}} onClick={()=>get_msg_details(message)}/>
							<icon.Check className={`${((convo_snd_msg && convo_itm.type === 'chat') || (userlist_snd_msg)) ? "show" : "hidden" }`} color={message.unread > 0 ? '#4d4d4d' : '#33cc33'} size='16' style={{marginLeft:'3px'}} />
						</strap.Col>
						<strap.Card style={{marginBottom:'5px',padding:'10px',minWidth:'50px',maxWidth:'210px', textAlign:'center',borderRadius:'15px 0px 15px 15px', borderBottom:'1px solid grey'}}>
							<a href={message.message} download target="_blank" rel="noopener noreferrer" key={Math.random()}>{message.message}</a>
						</strap.Card>
					</strap.Row>
				)
			}else{
				return(
				   <>
				   <strap.Row style={{marginRight:'15px', display:'flex', justifyContent:'flex-end'}}>
					   <strap.Col md='12' sm='12' xs='12' style={{display:'flex', justifyContent:'flex-end', padding:'0',fontSize:'11px'}}>{msg_bubble_time}
							<icon.Eye className={`${(convo_snd_msg && convo_itm.type === 'group') || (create_grp_snd_msg) ? "show" : "hidden" }`} size='14' type="button" style={{margin:'0 0 3px 3px',}} onClick={() => get_msg_details(message)}/>
							<icon.Check className={`${((convo_snd_msg && convo_itm.type === 'chat') || (userlist_snd_msg)) ? "show" : "hidden" }`} color={message.unread > 0 ? '#4d4d4d' : '#33cc33'} size='16' style={{marginLeft:'3px'}} />
					   </strap.Col>
					   <strap.Card style={{marginBottom:'5px',padding:'10px',minWidth:'50px',maxWidth:'210px', textAlign:'center',borderRadius:'15px 0px 15px 15px', borderBottom:'1px solid grey'}}>
						   {message.message}
					   </strap.Card>
				   </strap.Row>
				   </>
				)
			}
		}else
		if(message.msgType === 2){
			var path1 =  message.filePath.substring(9)
			if((message.filePath).includes(".jpg") || (message.filePath).includes(".jpeg") || (message.filePath).includes(".png") || (message.filePath).includes(".svg") || (message.filePath).includes(".mp4")){
				if((message.filePath).includes(".mp4")){
					return(
						<strap.Row style={{marginRight:'15px',marginBottom:'5px', display:'flex', justifyContent:'flex-end'}}>
							<strap.Col md='12' sm='12' xs='12' style={{display:'flex', justifyContent:'flex-end', padding:'0',fontSize:'11px'}}>{msg_bubble_time}
							<icon.Eye className={`${(convo_snd_msg && convo_itm.type === 'group') || (create_grp_snd_msg) ? "show" : "hidden" }`} size='14' type="button" style={{margin:'0 0 3px 3px'}} onClick={()=>get_msg_details(message)}/>
								<icon.Check className={`${((convo_snd_msg && convo_itm.type === 'chat') || (userlist_snd_msg)) ? "show" : "hidden" }`} color={message.unread > 0 ? '#4d4d4d' : '#33cc33'} size='16' style={{marginLeft:'3px'}} />
							</strap.Col>
							<strap.Card style={{height:'110px',marginBottom:'5px',padding:'10px',width:'105px',textAlign:'center',borderRadius:'15px 0px 15px 15px', borderBottom:'1px solid grey'}}>
							<icon.Film size='50' style={{marginLeft:'17px'}} />
							<span style={{marginTop:'6px'}}>{(message.message.slice(0,10))}</span>
							</strap.Card>
							<a href={api_url + path1} target='blank' download={message.message} style={{position:'absolute', margin:'110px 0 0 0',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'0 0 15px 15px',width:'105px',color:'white',background:'#ff704d',height:'22px', fontSize:'13px'}} >
								View
							</a>
						</strap.Row>
					)
				}else{
					return(
						<strap.Row style={{marginRight:'15px',marginBottom:'5px', display:'flex', justifyContent:'flex-end'}}>
							<strap.Col md='12' sm='12' xs='12' style={{display:'flex', justifyContent:'flex-end', padding:'0',fontSize:'11px'}}>{msg_bubble_time}
							<icon.Eye className={`${(convo_snd_msg && convo_itm.type === 'group') || (create_grp_snd_msg) ? "show" : "hidden" }`} size='14' type="button" style={{margin:'0 0 3px 3px'}} onClick={()=>get_msg_details(message)}/>
								<icon.Check className={`${((convo_snd_msg && convo_itm.type === 'chat') || (userlist_snd_msg)) ? "show" : "hidden" }`} color={message.unread > 0 ? '#4d4d4d' : '#33cc33'} size='16' style={{marginLeft:'3px'}} />
							</strap.Col>
							<div style={{height:'110px',marginBottom:'7px',width:'auto',maxWidth:'200px',textAlign:'center'}}>
								<a href={api_url + path1} target='blank'>
									<img src={api_url + path1} alt="message" style={{objectFit:'contain',height:'100%', width:'100%',borderRadius:'25px 0px 25px 25px'}} />
								</a>
							</div>
						</strap.Row>
					)
				}
			}else{
				var path2 =  message.filePath.substring(9)
				return(
					<strap.Row style={{marginRight:'15px',marginBottom:'5px', display:'flex', justifyContent:'flex-end'}}>
						<strap.Col md='12' sm='12' xs='12' style={{display:'flex', justifyContent:'flex-end', padding:'0',fontSize:'11px'}}>{msg_bubble_time}
						<icon.Eye className={`${(convo_snd_msg && convo_itm.type === 'group') || (create_grp_snd_msg) ? "show" : "hidden" }`} size='14' type="button" style={{margin:'0 0 3px 3px'}} onClick={()=>get_msg_details(message)}/>
							<icon.Check className={`${((convo_snd_msg && convo_itm.type === 'chat') || (userlist_snd_msg)) ? "show" : "hidden" }`} color={message.unread > 0 ? '#4d4d4d' : '#33cc33'} size='16' style={{marginLeft:'3px'}} />
						</strap.Col>
						<strap.Card style={{height:'110px',marginBottom:'5px',padding:'10px',width:'105px',textAlign:'center',borderRadius:'15px 0px 15px 15px', borderBottom:'1px solid grey'}}>
						<icon.FileText size='50' style={{marginLeft:'17px'}} />
						<span style={{marginTop:'6px'}}>{(message.message.slice(0,10))}</span>
						</strap.Card>
						<a href={api_url + path2} target='blank' download={message.message} style={{position:'absolute', margin:'110px 0 0 0',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'0 0 15px 15px',width:'105px',color:'white',background:'#ff704d',height:'22px', fontSize:'13px'}} >
							Download
						</a>
					</strap.Row>
				)
			}
		}
	}else{
		if(message.msgType === 1){ //MESSAGE BUBBLE - LEFT
			if(message.message.match(regex)){
				return(
					<strap.Row style={{marginLeft:'15px', display:'flex', justifyContent:'flex-start'}}>
						<strap.Col md='12' sm='12' xs='12' style={{padding:'0', fontSize:'11px', marginLeft:'3px'}}>{msg_bubble_time} {message.sender_name}</strap.Col>
						<strap.Card style={{marginBottom:'5px',padding:'10px',minWidth:'50px',maxWidth:'210px', textAlign:'center', borderRadius:'0px 15px 15px 15px', borderBottom:'1px solid grey'}}>
							<a href={message.message} target="_blank" rel="noopener noreferrer" >{message.message}</a>
						</strap.Card>
					</strap.Row>
				)
			}else{
				return(
					<>
					<strap.Row style={{marginLeft:'15px', display:'flex', justifyContent:'flex-start'}}>
						<strap.Col md='12' sm='12' xs='12' style={{padding:'0', fontSize:'11px', marginLeft:'3px'}}>{msg_bubble_time} {message.sender_name}</strap.Col>
						<strap.Card style={{marginBottom:'5px',padding:'10px',minWidth:'50px',maxWidth:'210px', textAlign:'center', borderRadius:'0px 15px 15px 15px', borderBottom:'1px solid grey'}}>
							{message.message}
						</strap.Card>
					</strap.Row>
					</>
				)
			}
		}else
		if(message.msgType === 2){
			if((message.filePath).includes(".jpg") || (message.filePath).includes(".jpeg") || (message.filePath).includes(".png") || (message.filePath).includes(".svg") || (message.filePath).includes(".mp4")){
				var path3 =  message.filePath.substring(9)
				if((message.filePath).includes(".mp4")){
					return(<strap.Row style={{marginLeft:'15px',marginBottom:'8px', display:'flex', justifyContent:'flex-start'}}>
						<strap.Col md='12' sm='12' xs='12' style={{padding:'0', fontSize:'11px', marginLeft:'3px'}}>{msg_bubble_time} {message.sender_name}</strap.Col>
						<strap.Card style={{height:'110px',marginBottom:'5px',padding:'10px',width:'105px',textAlign:'center',borderRadius:'0px 15px 15px 15px', borderBottom:'1px solid grey'}}>
						<icon.Film size='50' style={{marginLeft:'17px'}} />
						<span style={{marginTop:'6px'}}>{(message.message.slice(0,10))}</span>
						</strap.Card>
						<a href={api_url + path3} target='blank' style={{position:'absolute', margin:'110px 0 0 0',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'0 0 15px 15px',width:'105px',color:'white',background:'#ff704d',height:'22px', fontSize:'13px'}} >
							View
						</a>
					</strap.Row>)
				}else{
					return(
						<strap.Row style={{marginLeft:'15px', display:'flex', justifyContent:'flex-start'}}>
							<strap.Col md='12' sm='12' xs='12' style={{padding:'0', fontSize:'11px', marginLeft:'3px'}}>{msg_bubble_time} {message.sender_name}</strap.Col>
							<div style={{height:'110px',margin:'0 0 7px -6px',width:'auto',maxWidth:'200px',textAlign:'center'}}>
								<a href={api_url + path3} target='blank'>
								<img src={api_url + path3} alt="message" style={{objectFit:'contain',height:'100%', width:'100%',borderRadius:'0px 25px 25px 25px'}} />
								</a>
							</div>
						</strap.Row>
					)
				}
			}else{
				var path4 =  message.filePath.substring(9)
				return(
					<strap.Row style={{marginLeft:'15px',marginBottom:'8px', display:'flex', justifyContent:'flex-start'}}>
						<strap.Col md='12' sm='12' xs='12' style={{padding:'0', fontSize:'11px', marginLeft:'3px'}}>{msg_bubble_time} {message.sender_name}</strap.Col>
						<strap.Card style={{height:'110px',marginBottom:'5px',padding:'10px',width:'105px',textAlign:'center',borderRadius:'0px 15px 15px 15px', borderBottom:'1px solid grey'}}>
						<icon.FileText size='50' style={{marginLeft:'17px'}} />
						<span style={{marginTop:'6px'}}>{(message.message.slice(0,10))}</span>
						</strap.Card>
						<a href={api_url + path4} target='blank' download={message.message} style={{position:'absolute', margin:'110px 0 0 0',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'0 0 15px 15px',width:'105px',color:'white',background:'#ff704d',height:'22px', fontSize:'13px'}} >
							Download
						</a>
					</strap.Row>
				)
			}

		}
	}
}

export const center_item = {height:'30px',display:'flex', alignItems:'center', justifyContent:'center'};

//GROUP USER LIST CARD RENDER
export const group_members = ({user_itm, onlineUser}) => {
	var matches  = user_itm.name.match(/\b(\w)/g);
		if(matches.length === 1){ 
			matches = [user_itm.name.substring(0,2)]
		};
	var avatar = matches.join('');
	avatar     = avatar.replace(/[#_]/g,"").substring(0, 2).toUpperCase();
	if(onlineUser){
		var online = onlineUser.includes(user_itm._id) //SHOW ONLINE USER
	}
	return(
		<strap.Card style={{width:'380',backgroundColor:'white',borderRadius:'5px',cursor:'pointer', margin:'5px 0 6px 0', borderBottom:'2px solid #b8b894', padding:'5px'}}> 
			<strap.Row style={{display:'flex', alignItems:'center'}}>
				<strap.Col md={2} sm={2} xs={2}>
					{online ? <span style={{margin:'4px',height:'11px',width:'11px',backgroundColor:'#39e600',borderRadius:'50%',display:'inline-block', position:'absolute',zIndex:'99'}}></span> : null }
					<Avatar className='avatar-md' color='primary' content={avatar}/>
				</strap.Col>
				<strap.Col md={10} sm={10} xs={10}>
					<h6 style={{display:'flex',justifyContent:'flex-start',margin:'0 0 0 -15px', color:'#3c3c3c'}}>{user_itm.display_name}</h6> 
				</strap.Col>
			</strap.Row>
		</strap.Card>
	)
}

//ONCHANGE EVENT FOR CHECKBOX ADD USER 
export function group_add_change_comp(pro_data,group_add_usr,user_list,group_add_usr_all){
	if(pro_data.pro_from === "add"){
		if(group_add_usr.groupMember[pro_data.user_id]){
			delete group_add_usr.groupMember[pro_data.user_id]                
		}else{
			group_add_usr.groupMember[pro_data.user_id] = pro_data.user_id 
		}
		return group_add_usr
	}else
	if(pro_data.pro_from === "all"){
		user_list.map((user_info)=>{
			if(group_add_usr_all){
				return group_add_usr.groupMember[user_info._id] = user_info._id                    
			}else{
				return delete group_add_usr.groupMember[user_info._id]
			}
		})
		return group_add_usr
	}
}

//ONCHANGE EVENT FOR CHECKBOX REMOVE USER
export function group_remove_change_comp(pro_data,group_remove_usr,user_list,group_remove_usr_all,userId){
	if(pro_data.pro_from === "remove"){
		if(group_remove_usr.groupMember[pro_data.user_id]){
			delete group_remove_usr.groupMember[pro_data.user_id]                
		}else{
			group_remove_usr.groupMember[pro_data.user_id] = pro_data.user_id 
		}
		return group_remove_usr
	}else
	if(pro_data.pro_from === "all"){
		user_list.map((user_info)=>{
			if(group_remove_usr_all && user_info._id !== userId){
				return group_remove_usr.groupMember[user_info._id] = user_info._id                    
			}else{
				return delete group_remove_usr.groupMember[user_info._id]
			}
		})
		return group_remove_usr
	}
}

//SELECT ALL CHECKBOX RENDER COMP - ADD
export const select_all_checkbox_add = ({group_add_change, group_add_usr_all}) => {
	return(
		<strap.ListGroupItem style={{padding:"5px 22px",borderRadius:"4px",marginTop:"5px",backgroundColor:'white', borderBottom:'2px solid #b8b894'}}>
			<table style={{width:"100%"}}>
				<tbody> 
					<tr>
						<td rowSpan="2" >
							<strap.Input
								type="checkbox"
								name="all"
								id="all"
								onChange={()=>group_add_change({pro_from:"all"})}
								checked={group_add_usr_all ? true :false} 
								style={{width:"15px",height:"15px",margin:"-5px -16px"}}
							/>
						</td>
						<td rowSpan="2" style={{width:"10px"}}><Avatar color='primary' content='ALL'/></td>
						<td colSpan="2" ><h6 style={{marginTop:'5px', color:'#3c3c3c'}}>Select All Members</h6></td>
					</tr>
				</tbody>
			</table>
		</strap.ListGroupItem>
	)
}

//ADD USER CHECKBOX RENDER COMP
export const checkbox_add = ({user_name,icon,user_id,group_add_change,group_add_usr}) => {
	return(
		<strap.ListGroupItem style={{padding:"5px 22px",borderRadius:"4px",marginTop:"5px",backgroundColor:'white', borderBottom:'2px solid #b8b894'}}>
			<table style={{width:"100%"}}>
				<tbody> 
					<tr>
						<td rowSpan="2" >
							<strap.Input
								type="checkbox"
								name="add"
								id="add"
								onChange={()=>group_add_change({pro_from:"add",user_id:user_id})}
								checked={group_add_usr.groupMember[user_id] ? true :false} 
								style={{width:"15px",height:"15px",marginTop:"-7px"}}
							/>
						</td>
						<td rowSpan="2" style={{width:"10px"}}><Avatar color='primary' content={icon}/></td>
						<td colSpan="2" ><h6 style={{marginTop:'5px', color:'#3c3c3c'}}>{user_name[0]}</h6></td>
					</tr>
				</tbody>
			</table>
		</strap.ListGroupItem>
	)
}

//SELECT ALL CHECKBOX RENDER COMP - REMOVE
export const select_all_checkbox_remove = ({group_remove_change, group_remove_usr_alls}) => {
	return(
		<strap.ListGroupItem style={{padding:"5px 22px",borderRadius:"4px",marginTop:"5px",backgroundColor:'white', borderBottom:'2px solid #b8b894'}}>
			<table style={{width:"100%", }}>
				<tbody> 
					<tr>
						<td rowSpan="2" >
							<strap.Input
								type="checkbox"
								name="all"
								id="all"
								onChange={()=>group_remove_change({pro_from:"all"})}
								checked={group_remove_usr_alls ? true : false} 
								style={{width:"15px",height:"15px",margin:"-5px -16px"}}
							/>
						</td>
						<td rowSpan="2" style={{width:"10px"}}><Avatar color='primary' content='ALL'/></td>
						<td colSpan="2" ><h6 style={{marginTop:'5px', color:'#3c3c3c'}}>Select All Members</h6></td>
					</tr>
				</tbody>
			</table>
		</strap.ListGroupItem>
	)
}

//REMOVE USER CHECKBOX RENDER COMP
export const checkbox_remove = ({user_name,icon,user_id,group_remove_change,group_remove_usr}) => {
	return(
		<strap.ListGroupItem style={{padding:"5px 22px",borderRadius:"4px",marginTop:"5px",backgroundColor:'white', borderBottom:'2px solid #b8b894'}}>
			<table style={{width:"100%", }}>
				<tbody> 
					<tr>
						<td rowSpan="2" >
							<strap.Input
								type="checkbox"
								name="remove"
								id="remove"
								onChange={()=>group_remove_change({pro_from:"remove",user_id:user_id})}
								checked={group_remove_usr.groupMember[user_id] ? true :false} 
								style={{width:"15px",height:"15px",marginTop:"-7px"}}
							/>
						</td>
						<td rowSpan="2" style={{width:"10px"}}><Avatar color='primary' content={icon}/></td>
						<td colSpan="2" ><h6 style={{marginTop:'5px', color:'#3c3c3c'}}>{user_name[0]}</h6></td>
					</tr>
				</tbody>
			</table>
		</strap.ListGroupItem>
	)
}

export const style = {
	show: {
	//   display: 'block',
	//   width:'436px',
	//   padding:'10px',
	//   height:"420px",
	//   borderRadius:"4px",
	//   position:"fixed",
	//   bottom:"74px",
	//   right:"90px",
	//   zIndex:999,
	},
	showNo: {
	  display: 'none',
	},
  };

  export const previewFile = ({previewClose, fileName}) => {
		return(
			// <strap.Card style={{width:'295px', height:'110px', margin:'0 0 0px 13px'}}>
				<strap.Card style={{width:'100px', height:'100px',margin:'3px 0 10px 12px',display:'flex', justifyContent:'center', alignItems:'center', border:'1px solid black'}}>
					<icon.XCircle style={{position:'absolute',margin:'0 76px 73px 0',cursor:'pointer'}} size={17} onClick={()=> previewClose()} />
					<icon.FileText size='50' />
					<strap.Row>{(fileName.slice(0,10))}</strap.Row>
					{/* <div style={{background:'#ff704d', width:'100px', height:'16px', position:'absolute', marginTop:'94px', borderRadius:'0 0 10px 10px'}}></div> */}
				</strap.Card>
			// </strap.Card>
		)
  }
  export const previewVideo = ({previewClose, fileName}) => {
		return(
			// <strap.Card style={{width:'295px', height:'110px', margin:'0 0 0px 13px'}}>
				<strap.Card style={{width:'100px', height:'100px',margin:'3px 0 10px 12px',display:'flex', justifyContent:'center', alignItems:'center', border:'1px solid black'}}>
					<icon.XCircle style={{position:'absolute',margin:'0 76px 73px 0',cursor:'pointer'}} size={17} onClick={()=> previewClose()} />
					<icon.Film size='50' />
					<strap.Row>{(fileName.slice(0,10))}</strap.Row>
				</strap.Card>
			// </strap.Card>
		)
  }

  export const previewImage = ({previewClose, imageUrl}) => {
		return(
			<div style={{width:'150px', maxWidth:'150px', overflow:'hidden',height:'100px',margin:'0 0 5px 13px',display:'flex', justifyContent:'flex-start', alignItems:'flex-start'}}>
				<icon.XCircle style={{position:'absolute',margin:'6px 0 0 6px',cursor:'pointer'}} size={20} onClick={()=> previewClose()} />
				<img src={imageUrl} alt='preview' color='black' width='auto' height='100' />
			</div>
		)
  }

						
//==================================== CHAT - END ====================================