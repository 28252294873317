import { io } from "socket.io-client";
import { get_process_env } from "../../redux/actions/a_common/init";
//SOCKET CONNECTION
const token = localStorage.getItem('token');
const CHAT_IP = get_process_env().slice(0,-4);

var socketConnection = {}
if(token){
    socketConnection = io.connect(CHAT_IP, {query: `token=${token}`});
}

export default socketConnection

