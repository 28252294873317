const initialState = {
	drop_list:{
		sts:"",
		msg:"",
		mode:"",
		rslt:[],
		error:[]
	},
	on_selected:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	available_count:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	process_transfer:{
		sts:"",
		msg:"",
		rslt:[],
		error:[],
		header:[]
	}
}

const lead_transfer = (state = initialState, action) => {

	switch (action.type) {
		case "drop_list":
			return {...state,
			drop_list:{
				sts:action.sts,
				mode:action.mode,
				msg:action.msg,
				rslt:action.rslt,
				error:action.error
			}
		}
		case "on_selected":
			return {...state,
			on_selected:{
				sts:action.sts,
				msg:action.msg,
				rslt:action.rslt,
				error:action.error
			}
		}
		case "available_count":
			return {...state,
			available_count:{
				sts:action.sts,
				msg:action.msg,
				rslt:action.rslt,
				error:action.error
			}
		}
		case "process_transfer":
			return {...state,
			process_transfer:{
				sts:action.sts,
				msg:action.msg,
				rslt:action.rslt,
				error:action.error,
				header:action.header
			}
		}
		default:
			return state
	}
}  
export default lead_transfer