const initialState = {
	drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	on_change_event:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	usr_login_schema:[],
	usr_login_info:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"_id",
		sort_order:-1
	},
	basic_filter:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	firstcall_disposition:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	firstcall_disposition_list:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},	
	updated_disposition:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	updated_disposition_list:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},	
	get_appt_type_count:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	get_appt_type_list:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},	
	get_appt_mode_count:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	get_appt_mode_list:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	get_met_activity_count:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	get_met_activity_list:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},	
	overall_activity_count:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	call_log:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},	
	vendor_dump_count:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	ncd_hot_warm_count:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	ncd_hot_warm_list:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	todo_count:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		pro_mode:{},
		error:{}
	},
	expected_buiz:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	expected_buiz_list:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	team_filter:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		mode:"",
	},
	team_on_change:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	activity_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	followup_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	assign_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	assign_tracker_onchange:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	performance_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[],
		total_avg:[]
	},
	todo_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	attendance_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	todo_rollback:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	todo_rollback_today:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	todo_rollback_month:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	mobile_history:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	buiz_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	buiz_tracker_view:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	jfw_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	jfw_tracker_view:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	member_wise_jfw:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	member_wise_jfw_view:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	date_wise_jfw:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	date_wise_jfw_view:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	date_wise_fresh_met:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	date_wise_fresh_met_view:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	date_wise_fresh_met_zero:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	lead_type_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	review_count:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	activity_charts:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	activity_charts_subDisposition:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	business_sts:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	business_sts_list:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	vendor_lead_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	zero_days_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	zero_days_list:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	vendor_dump_drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	brokerage_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	time_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	open_and_met_tracker:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
	brokerage_tracker_view:{
		sts:"",
		msg:"",
		rslt:{},
		error:{},
		header:[]
	},
}

const reports = (state = initialState, action) => {
	switch (action.type) {
		case "drop_list":
			return {...state,
				drop_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "on_change_event":
			return {...state,
				on_change_event:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "usr_login_schema":
			return {...state,
				schema_info:action.rslt,
			}
		case "usr_login_info":
			return {...state,
				usr_login_info:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
				}
			}
		case "basic_filter":
			return {...state,
				basic_filter:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "firstcall_disposition":
			return {...state,
				firstcall_disposition:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "firstcall_disposition_list":
			return {...state,
				firstcall_disposition_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "updated_disposition":
			return {...state,
				updated_disposition:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "updated_disposition_list":
			return {...state,
				updated_disposition_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}			
		case "get_appt_type_count":
			return {...state,
				get_appt_type_count:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "get_appt_type_list":
			return {...state,
				get_appt_type_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}			
		case "get_appt_mode_count":
			return {...state,
				get_appt_mode_count:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "get_appt_mode_list":
			return {...state,
				get_appt_mode_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "get_met_activity_count":
			return {...state,
				get_met_activity_count:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "get_met_activity_list":
			return {...state,
				get_met_activity_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "overall_activity_count":
			return {...state,
				overall_activity_count:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "vendor_dump_count":
			return {...state,
				vendor_dump_count:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "overall_activity_list":
			return {...state,
				overall_activity_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "call_log":
			return {...state,
				call_log:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "ncd_hot_warm_count":
			return {...state,
				ncd_hot_warm_count:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "ncd_hot_warm_list":
			return {...state,
				ncd_hot_warm_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "todo_count":
			return {...state,
				todo_count:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					pro_mode:action.pro_mode,
					error:action.error
				}
			}
		case "expected_buiz":
			return {...state,
				expected_buiz:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "expected_buiz_list":
			return {...state,
				expected_buiz_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "team_filter":
			return {...state,
				team_filter:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					mode:action.mode,
				}
			}
		case "team_on_change":
			return {...state,
				team_on_change:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
				}
			}
		case "activity_tracker":
			return {...state,
				activity_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "followup_tracker":
			return {...state,
				followup_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "assign_tracker":
			return {...state,
				assign_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "assign_tracker_onchange":
			return {...state,
				assign_tracker_onchange:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
				}
			}
		case "performance_tracker":
			return {...state,
				performance_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header,
					total_avg:action.total_avg,
				}
			}
		case "todo_tracker":
			return {...state,
				todo_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "attendance_tracker":
			return {...state,
				attendance_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "todo_rollback":
			return {...state,
				todo_rollback:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}	
		case "todo_rollback_today":
			return {...state,
				todo_rollback_today:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}	
		case "todo_rollback_month":
			return {...state,
				todo_rollback_month:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "mobile_history":
			return {...state,
				mobile_history:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "buiz_tracker":
			return {...state,
				buiz_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "buiz_tracker_view":
			return {...state,
				buiz_tracker_view:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "jfw_tracker":
			return {...state,
				jfw_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "jfw_tracker_view":
			return {...state,
				jfw_tracker_view:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "member_wise_jfw":
			return {...state,
				member_wise_jfw:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "member_wise_jfw_view":
			return {...state,
				member_wise_jfw_view:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "date_wise_jfw":
			return {...state,
				date_wise_jfw:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "date_wise_jfw_view":
			return {...state,
				date_wise_jfw_view:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "date_wise_fresh_met":
			return {...state,
				date_wise_fresh_met:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "date_wise_fresh_met_view":
			return {...state,
				date_wise_fresh_met_view:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "date_wise_fresh_met_zero":
			return {...state,
				date_wise_fresh_met_zero:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "lead_type_tracker":
			return {...state,
				lead_type_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "review_count":
			return {...state,
				review_count:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "activity_charts":
			return {...state,
				activity_charts:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}	
		case "activity_charts_subDisposition":
			return {...state,
				activity_charts_subDisposition:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "business_sts":
			return {...state,
				business_sts:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}	
		case "business_sts_list":
			return {...state,
				business_sts_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "vendor_lead_tracker":
			return {...state,
				vendor_lead_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "zero_days_tracker":
			return {...state,
				zero_days_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}	
		case "zero_days_list":
			return {...state,
				zero_days_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "vendor_dump_drop_list":
			return {...state,
				vendor_dump_drop_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "brokerage_tracker":
			return {...state,
				brokerage_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "time_tracker":
			return {...state,
				time_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "open_and_met_tracker":
			return {...state,
				open_and_met_tracker:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		case "brokerage_tracker_view":
			return {...state,
				brokerage_tracker_view:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error,
					header:action.header
				}
			}
		default:
			return state
	}
}  
export default reports