const initialState = {
	drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	on_selected:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	search_filter:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	churning_process:{
		sts:"",
		msg:"",
		rslt:"",
		error:"",
		total_count:0,
		user_count:0
	},
	churning_log_id:{
		sts:"",
		msg:"",
		rslt:{},
		header:{},
		error:{}
	},
	delete:{
		sts: "",
		msg:"",
		rslt:[],
		error:""
	},
	exist_upload:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	}
}

const churning = (state = initialState, action) => {
	switch (action.type) {
		case "get_common":
			return {...state,
				drop_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "on_selected":
			return {...state,
				on_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "search_filter":
			return {...state,
				search_filter:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "churning_process":
			return {...state,
				churning_process:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "churning_log_id":
			return {...state,
				churning_log_id:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					header:action.header,
					error:action.error
				}
			}
		case "delete":
			return {...state,
				delete:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "exist_upload":
			return {...state,
				exist_upload:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		default:
			return state
	}
}  
export default churning