import { combineReducers } from "redux"
import customizer          from "./customizer/"
import auth                from "./auth/"
import navbar              from "./navbar/Index"
import grands              from "./grands"
import designation         from "./designation"
import gender              from "./gender"
import cafs_branch         from "./cafs_branch"
import cafs_channel        from "./cafs_channel"
import cafs_location       from "./cafs_location"
import cafs_time           from "./cafs_time"
import reason              from "./reason"
import reason_type         from "./reason_type"
import l_and_p             from "./l_and_p"
import approval_status     from "./approval_status"
import state               from "./state"
import city                from "./city"
import category            from "./category"
import sub_category        from "./sub_category"
import annual_income       from "./annual_income"
import base_name           from "./base_name"
import grade_name          from "./grade_name"
import lead_status         from "./lead_status"
import lead_type           from "./lead_type"
import prospect            from "./prospect"
import main_disposition    from "./main_disposition"
import sub_disposition     from "./sub_disposition"
import appointment_mode    from "./appointment_mode"
import appointment_type    from "./appointment_type"
import appointment_area    from "./appointment_area"
import appointment_status  from "./appointment_status"
import met_status          from "./met_status"
import dial_for            from "./dial_for"
import dial_category       from "./dial_category"
import module_list         from "./module_list"
import module_permission   from "./module_permission"
import payment_location    from "./payment_location"
import user                from "./user"
import dump_data           from "./dump_data"
import vendor              from "./vendor"
import assign_count        from "./assign_count"
import cold_calling        from "./cold_calling"
import lead_transfer       from "./lead_transfer"
import todo                from "./todo"
import reports             from "./reports"
import attendance          from "./attendance"
import mls                 from "./mls"
import resignation         from "./resignation"
import met_review          from "./met_review"
import service_for         from "./service_for"
import service_mode        from "./service_mode"
import service_status      from "./service_status"
import service_type        from "./service_type"
import meeting_review_type from "./meeting_review_type"
import service             from "./service"
import churning            from "./churning"
import churning_log        from "./churning_log"
import message_content     from "./message_content"
import band                from "./band"
import grade               from "./grade"
import b2b                 from "./b2b"
import chat                from "./chat"
import upload_limit        from "./upload_limit"
import map_vendor          from "./map_vendor"
import assign_limit        from "./assign_limit"
import brokerage_plan      from "./brokerage_plan"
import brokerage_info      from "./brokerage_info"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  grands:grands,
  designation:designation,
  gender:gender,
  cafs_branch:cafs_branch,
  cafs_channel:cafs_channel,
  cafs_location:cafs_location,
  cafs_time:cafs_time,
  reason:reason,
  reason_type:reason_type,
  l_and_p:l_and_p,
  approval_status:approval_status,
  state:state,
  city:city,
  category:category,
  sub_category:sub_category,
  annual_income:annual_income,
  base_name:base_name,
  grade_name:grade_name,
  lead_status:lead_status,
  lead_type:lead_type,
  prospect:prospect,
  main_disposition:main_disposition,
  sub_disposition:sub_disposition,
  appointment_mode:appointment_mode,
  appointment_type:appointment_type,
  appointment_area:appointment_area,
  appointment_status:appointment_status,
  met_status:met_status,
  dial_for:dial_for,
  dial_category:dial_category,
  module_list:module_list,
  module_permission:module_permission,
  payment_location:payment_location,
  user:user,
  vendor:vendor,
  assign_count:assign_count,
  dump_data:dump_data,  
  cold_calling:cold_calling,
  lead_transfer:lead_transfer,
  todo:todo,
  reports:reports,
  attendance:attendance,
  mls:mls,
  resignation:resignation,
  met_review:met_review, 
  service_for:service_for,
  service_mode:service_mode,
  service_status:service_status,
  service_type:service_type,
  meeting_review_type:meeting_review_type,
  service:service,
  churning:churning,
  churning_log:churning_log,
  message_content:message_content,
  band:band,
  grade:grade,
  b2b:b2b,
  chat:chat,
  upload_limit:upload_limit,
  map_vendor:map_vendor,
  assign_limit:assign_limit,
  brokerage_plan:brokerage_plan,
  brokerage_info:brokerage_info
})

export default rootReducer
