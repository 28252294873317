import { init_data,get_token,send_post,get_rslt } from "../a_common/init.js"
const api_url = "/chat";

//GET CONVERSATION
export const get_conversation_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_conversation'
					pro_data.frm       = "get_conversation"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET USERLIST
export const get_user_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/user_list'
					pro_data.frm       = "user_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//CREATE GROUP
export const add_update_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/addUpdate'
					pro_data.frm       = "add_update"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET MESSAGE
export const get_message_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_message'
					pro_data.frm       = "get_message"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GROUP USER LIST 
export const group_user_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/group_user_list'
					pro_data.frm       = "group_user_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//ADD & REMOVE USER
export const group_user_changes_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/group_user_changes'
					pro_data.frm       = "group_user_changes"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//ADD & REMOVE USER
export const exit_group_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/exit_group'
					pro_data.frm       = "exit_group"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET MSG DETAILS
export const get_msg_details_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_msg_details'
					pro_data.frm       = "get_msg_details"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//total_unread_messages
export const total_unread_messages_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/total_unread_messages'
					pro_data.frm       = "total_unread_messages"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

export const send_message = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/send_message'
					pro_data.frm       = "send_message"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}