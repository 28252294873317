import { init_data,get_token,send_post,get_rslt } from "../a_common/init.js"
const api_url 		  = "/reports";
const met_review_url  = "/met_review";
const vendor_dump_url = "/dump_data";

//GET COMMON DROP 
export const drop_action = (drop_list) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(drop_list){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					drop_list.pro_by   = user_info.id;

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/particular_common_drop'
					pro_data.frm       = "drop_list"
					pro_data.form_data = {drop_list:drop_list}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}


//ONCAHNGE SEARCH FILTER
export const on_change_event_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/on_change_event'
					pro_data.frm       = "on_change_event"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET USER LOGIN INFO SCHEME 
export const usr_login_schema_action = () => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});
	return async dispatch => {
		await get_token(null, async function(token_rslt){
			if(token_rslt){
				var user_token     = token_rslt.token
				var pro_data       = init_info.pro_data
				pro_data.token     = user_token
				pro_data.url       = api_url+'/usr_login_schema'
				pro_data.frm       = "usr_login_schema"
				await send_post(pro_data,function(post_rslt){
					dispatch(post_rslt)
				});
			}
		});
    }
}

//USR LOGIN INFORMATION
export const usr_login_info_action = (search_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});
	return async dispatch => {
		if(search_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					if(parseInt(search_data.page_count) > 0){
						search_data.page_count = search_data.page_count - 1;
					}
					search_data.skip = search_data.page_count*search_data.per_page;
				
					search_data.sort_order = -1;
					if(search_data.sort_order === "asc"){
						search_data.sort_order = 1;
					}
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/usr_login_info'
					pro_data.frm       = "usr_login_info"
					pro_data.form_data = search_data
					await send_post(pro_data,async function(post_rslt){
						post_rslt.search_value =  search_data.search_value;
						post_rslt.page_count   =  search_data.page_count;
						post_rslt.per_page     =  search_data.per_page;
						post_rslt.sort_by      =  search_data.sort_by;
						post_rslt.sort_order   =  search_data.sort_order;
						await dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
	}
}

//BASIC FILTER DATA
export const basic_filter_action = (basic_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(basic_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/basic_filter'
					pro_data.frm       = "basic_filter"
					pro_data.form_data = basic_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//FIRSTCALL DISPOSITION 
export const firstcall_disposition_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/firstcall_disposition'
					pro_data.frm       = "firstcall_disposition"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//FIRSTCALL DISPOSITION  LIST
export const firstcall_disposition_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/firstcall_disposition_list'
					pro_data.frm       = "firstcall_disposition_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//UPDATED DISPOSITION
export const updated_disposition_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/updated_disposition'
					pro_data.frm       = "updated_disposition"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//UPDATED DISPOSITION LIST
export const updated_disposition_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/updated_disposition_list'
					pro_data.frm       = "updated_disposition_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET COUNT BY APPOINTMENT DATE & MET NCD / POSTPOND NCD DATE 
export const get_appt_type_count_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_appt_type_count'
					pro_data.frm       = "get_appt_type_count"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET COUNT BY APPOINTMENT DATE & MET NCD / POSTPOND NCD DATE  LIST
export const get_appt_type_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_appt_type_list'
					pro_data.frm       = "get_appt_type_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET APPOINTMENT MODE COUNT BY MET DATE COUNT 
export const get_appt_mode_count_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_appt_mode_count'
					pro_data.frm       = "get_appt_mode_count"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET APPOINTMENT MODE COUNT BY MET DATE COUNT LIST
export const get_appt_mode_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_appt_mode_list'
					pro_data.frm       = "get_appt_mode_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET MET ACTIVITY COUNT
export const get_met_activity_count_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_met_activity_count'
					pro_data.frm       = "get_met_activity_count"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET MET ACTIVITY COUNT LIST
export const get_met_activity_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_met_activity_list'
					pro_data.frm       = "get_met_activity_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET OVER ALL ACTIVITY COUNT
export const overall_activity_count_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/overall_activity_count'
					pro_data.frm       = "overall_activity_count"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET OVER ALL ACTIVITY LIST
export const overall_activity_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/overall_activity_list'
					pro_data.frm       = "overall_activity_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET COLD CALLING LOG
export const call_log_action = (cold_calling_id) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(cold_calling_id){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/call_log'
					pro_data.frm       = "call_log"
					pro_data.form_data = {cold_calling_id:cold_calling_id}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET NCD BSED HOT & WARM COUNT
export const ncd_hot_warm_count_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/ncd_hot_warm_count'
					pro_data.frm       = "ncd_hot_warm_count"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET NCD BSED HOT & WARM LIST
export const ncd_hot_warm_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/ncd_hot_warm_list'
					pro_data.frm       = "ncd_hot_warm_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET TODO DATA 
export const todo_count_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/todo_count'
					pro_data.frm       = "todo_count"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}


//GET EXPECTED BUIZ AMOUNT
export const expected_buiz_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/expected_buiz_amount'
					pro_data.frm       = "expected_buiz"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET EXPECTED BUIZ AMOUNT LIST
export const expected_buiz_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/expected_buiz_list'
					pro_data.frm       = "expected_buiz_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//VENDOR BASE COUNT
export const vendor_dump_count_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/vendor_based_dump_count'
					pro_data.frm       = "vendor_dump_count"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//REPORT FILTER FOR TEAM 
export const team_filter_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/team_filter'
					pro_data.frm       = "team_filter"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//ONCAHNGE SEARCH FILTER
export const team_on_change_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/team_on_change'
					pro_data.frm       = "team_on_change"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY USER/TEAM
export const activity_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/activity_tracker'
					pro_data.frm       = "activity_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY USER/TEAM
export const followup_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/followup_tracker'
					pro_data.frm       = "followup_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY USER/TEAM
export const assign_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/assign_tracker'
					pro_data.frm       = "assign_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//ONCAHNGE SEARCH FILTER
export const assign_tracker_onchange_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/assign_tracker_onchange'
					pro_data.frm       = "assign_tracker_onchange"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY USER/TEAM
export const performance_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/performance_tracker'
					pro_data.frm       = "performance_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//TODO REPORT BY USER/TEAM
export const todo_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/todo_tracker'
					pro_data.frm       = "todo_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//ATTENDANCE REPORT BY USER/TEAM
export const attendance_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/attendance_tracker'
					pro_data.frm       = "attendance_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}


//OVER ALL REPORT BY USER/TEAM
export const todo_rollback_search_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/todo_rollback_search'
					pro_data.frm       = "todo_rollback"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY USER/TEAM
export const todo_rollback_today_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					form_data.pro_by   = user_info.id;
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/todo_rollback'
					pro_data.frm       = "todo_rollback_today"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY USER/TEAM
export const todo_rollback_month_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					form_data.pro_by   = user_info.id;
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/todo_month_rollback'
					pro_data.frm       = "todo_rollback_month"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//MOBILE TRACKER
export const mobile_history_action = (mobile) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(mobile){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/report_based_mobile'
					pro_data.frm       = "mobile_history"
					pro_data.form_data = mobile
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET  BUIZ TRACKER
export const buiz_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/buiz_tracker'
					pro_data.frm       = "buiz_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//BUIZ_TRACKER_VIEW
export const buiz_tracker_view_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/buiz_tracker_view'
					pro_data.frm       = "buiz_tracker_view"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//JFW
export const jfw_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/jfw_tracker'
					pro_data.frm       = "jfw_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//JFW_TRACKER_VIEW
export const jfw_tracker_view_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/jfw_tracker_view'
					pro_data.frm       = "jfw_tracker_view"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//MEMBER-WISE JFW
export const member_wise_jfw_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/member_wise_jfw'
					pro_data.frm       = "member_wise_jfw"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//MEMBER-WISE VIEW JFW
export const member_wise_jfw_view_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/member_wise_jfw_view'
					pro_data.frm       = "member_wise_jfw_view"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//DATE-WISE JFW
export const date_wise_jfw_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/date_wise_jfw'
					pro_data.frm       = "date_wise_jfw"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//DATE-WISE VIEW JFW
export const date_wise_jfw_view_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/date_wise_jfw_view'
					pro_data.frm       = "date_wise_jfw_view"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//DATE-WISE FRESH MET
export const date_wise_fresh_met_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/date_wise_fresh_met'
					pro_data.frm       = "date_wise_fresh_met"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//DATE-WISE FRESH MET VIEW 
export const date_wise_fresh_met_view_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/date_wise_fresh_met_view'
					pro_data.frm       = "date_wise_fresh_met_view"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//DATE-WISE FRESH MET ZERO 
export const date_wise_fresh_met_zero_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/date_wise_fresh_met_zero'
					pro_data.frm       = "date_wise_fresh_met_zero"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY LEAD TYPE TRACKER
export const lead_type_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/lead_type_tracker'
					pro_data.frm       = "lead_type_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET MET REVIEW PENDING DATA
export const mls_status_count_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = met_review_url+'/review_pending_count'
					pro_data.frm       = "review_count"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//ACTIVITY CHARTS
export const activity_charts_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/activity_charts'
					pro_data.frm       = "activity_charts"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//ACTIVITY CHARTS
export const activity_charts_subDisposition_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/activity_charts_subDisposition'
					pro_data.frm       = "activity_charts_subDisposition"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY USER/TEAM
export const business_sts_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/business_sts'
					pro_data.frm       = "business_sts"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY USER/TEAM
export const business_sts_list_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/business_sts_list'
					pro_data.frm       = "business_sts_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY VENDOR LAED TRACKER
export const vendor_lead_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/vendor_lead_tracker'
					pro_data.frm       = "vendor_lead_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY ZERO DAYS TRACKER
export const zero_days_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/zero_days'
					pro_data.frm       = "zero_days_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY ZERO DAYS TRACKER
export const zero_days_list_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/zero_days_list'
					pro_data.frm       = "zero_days_list"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET COMMON DROP 
export const vendor_dump_drop_action = () => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		await get_token(null,async function(token_rslt){
			if(token_rslt){
				var user_token     = token_rslt.token
				var user_info      = token_rslt.decoded
				var pro_data       = init_info.pro_data
				pro_data.token     = user_token
				pro_data.url       = vendor_dump_url+'/get_assign_common_drop'
				pro_data.frm       = "vendor_dump_drop_list"
				pro_data.form_data = {userId:user_info.id}
				await send_post(pro_data,function(post_rslt){
					dispatch(post_rslt)
				});
			}
		});
    }
}

//OVER ALL REPORT BY USER/TEAM
export const brokerage_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/brokerage_tracker'
					pro_data.frm       = "brokerage_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY USER/TEAM
export const time_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/time_tracker'
					pro_data.frm       = "time_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OVER ALL REPORT BY OPEN AND MET TRACKER
export const open_and_met_tracker_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/open_and_met_tracker'
					pro_data.frm       = "open_and_met_tracker"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}


//BROKERAGE TRACKER VIEW
export const brokerage_tracker_view_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/brokerage_tracker_view'
					pro_data.frm       = "brokerage_tracker_view"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}