import { history } from "../../../history"
import jwt_decode from "jwt-decode";
import axios from "axios"

// GET API URL FROM PROCESS ENV
export function get_process_env(){
    var api_url = process.env.REACT_APP_API_PATH_LOCAL;
    if((window.location.href).includes("https://360.cafsindia.com")){
        api_url = process.env.REACT_APP_API_PATH_LIVE
    }
    return api_url
}

//INTI DATA FOR ALL POST AND GET
export function init_data(pro_data,callback){
    return callback({
        pro_data:{
            method:"POST",
            token:null,
            url:null,
            form_data:null,
            frm:null,
            rslt_info:{
                sts:false,
                msg:"",
                rslt:[],
                error:[],
                header:[],

                // COMMON
                user_count:0,
                log_schema:[],
                mode:"",
                pro_mode:"",
                total_avg:"",
                upload_id:[],
				upd_count:0,
				upd_rslt:[],
				can_process:false,

                //REQUIRED FOR SEARCH
                loading:false,
                total_count:0,
                search_value:"",
                page_count:1,
                per_page:10,
                sort_by:"_id",
                sort_order:-1,
                
            }
        },
        response:null,
        frm:null
    })
}

//SIDE BAR COMMON MODE
export function sidebar_init(pro_data,callback){
    var pro_list = []
	pro_list[1]  = "RESET";
	pro_list[2]  = "DROP_CLOSE";
	pro_list[3]  = "BTN_CLOSE";
	pro_list[4]  = "ADD";
	pro_list[5]  = "UPD";
    var pro_mode = "UNK";
	var tr_info  = null;
	if(pro_data){
		pro_mode = pro_list[pro_data.pro_mod];
		tr_info  = pro_data.pro_info
	}
    var final_rlst = {pro_mode:pro_mode,tr_info:tr_info}
    return callback(final_rlst);
}

//GET TOKEN AND DECODE 
export function get_token(req_token,callback){
    var token = null
    if(req_token){
        token = req_token
    }else{
        token = localStorage.getItem('token');
    }
    if(token){
        var rslt = {token:token,decoded:jwt_decode(token)}
        return callback(rslt);
    }else{
        localStorage.removeItem("token")
        history.push("/login")
        //return callback(false)
    }
}

//RETURN RESPONSE
export async function get_rslt(rslt,callback){
    var pro_data = rslt.pro_data;
    var response = rslt.response;
    var from     = rslt.frm;

    var msg = "Unable to process your request."
    if(from === "rslt"){
        msg = "No response form server. Please refresh and retry"
    }else
    if(from === "invalid_formdata"){
        msg = "Invalid form data check form data"
    }else
    if(from === "invalid_frm"){
        msg = "Invalid from check in action"
    }
    var final_rlst = pro_data.rslt_info;
    if((response)&&(response.data)){
        var post_rslt = response.data;
        if(post_rslt.send_to){
            history.push(post_rslt.send_path)
        }else{
            (async () => {
                final_rlst.type = pro_data.frm;
                Object.keys(final_rlst).map(function(rslt_key) {
                    if(post_rslt[rslt_key]){
                        final_rlst[rslt_key] = post_rslt[rslt_key];
                    }
                    return true
                });
            })();
            return callback(final_rlst);
        }
    }else{
        final_rlst.type = pro_data.frm;
        final_rlst.msg  = msg;
        return callback(final_rlst);
    }
}

//SENT POST / GET
export async function send_post(pro_data,callback){
    if(pro_data.frm){
        var post_data = {
            method:pro_data.method,
            headers:{"Authorization":pro_data.token},
            url:get_process_env() + pro_data.url,
        }
        if(pro_data.form_data){
            post_data.data = pro_data.form_data
        }
        await axios(post_data).then(response => {
            var send_data = {pro_data:pro_data,response:response,frm:"rslt"}
            get_rslt(send_data,function(post_rslt){
                return callback(post_rslt);
            });
        }).catch(function (error) {
            var send_data = {pro_data:pro_data,response:null,frm:"catch"}
            get_rslt(send_data,function(post_rslt){
                return callback(post_rslt);
            });
        });
    }else{
        var send_data = {pro_data:pro_data,response:null,frm:"invalid_frm"}
        await get_rslt(send_data,function(post_rslt){
            localStorage.removeItem("token")
            history.push("/login")
            return callback(post_rslt);
        });
    }
}



