import React from 'react'
import * as strap from "reactstrap"
import * as icon from "react-feather"
import Select from "react-select"

//==================================== COMPONENT - START ==================================
// COMMON HEADER TO ALL PAGES
export const Header = props => {
	return(
		<div  className="container-fluid" style={{padding: "0px"}}>
			<div className="row">
				<div className ="col-md-6 col-sm-12" style={{padding: "0px"}}>
					<h3 className="text-primary">{props.page_header}</h3>
				</div>
				<div className ="col-md-6 col-sm-12">
					<div className="row">
						<div className={`col-sm-12 ${parseInt(props.grands_info.search) === 1 ? "col-md-6" : "col-md-12" }`} style={{textAlign: "right"}}>
							<strap.Button.Ripple id='delete_btn' className={`mr-1 mb-1 rounded-circle ${parseInt(props.grands_info.delete) === 1 ? "show" : "hidden" }`} color="danger" onClick={() =>  props.handelDelete()}>
								<icon.Trash size={18} />
								<strap.UncontrolledTooltip placement="bottom" target="delete_btn">
									Select record and delete
								</strap.UncontrolledTooltip>
							</strap.Button.Ripple>
							<strap.Button.Ripple id='add_btn' className={`sidebar_action mr-1 mb-1 rounded-circle ${parseInt(props.grands_info.add) === 1 ? "show" : "hidden" }`} color="success" onClick={() =>  
								props.value.sidebar_action({pro_mod:4,pro_info:null})
								}>
								<icon.Plus size={18} />
								<strap.UncontrolledTooltip placement="bottom" target="add_btn">
									Add New record
								</strap.UncontrolledTooltip>
							</strap.Button.Ripple>
						</div>
						<div className={`col-md-6 col-sm-12 ${parseInt(props.grands_info.search) === 1 ? "show" : "hidden" }`}>
							<strap.Input value={props.search_value} onChange={e => props.handleFilter(e)} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

//SELECT AND MULTI SELECT CUSTOM
export const CustomSelect = props => {
	var select_val = []
	if(props.option){
		props.option.map((option_val, i) => {
			if(props.multi){
				if(props.select_val){
					props.select_val.map((drop_val, m) => {
						if(drop_val === "all"){
							if((option_val.value) && (option_val.value !== "all")){
								select_val.push(props.option[i])
							}
						}else
						if(option_val.value === drop_val){
							select_val.push(props.option[i])
						}
						return true
					});
				}
				
			}else{
				if((props.select_val) && (option_val.value === props.select_val)){
					select_val.push(props.option[i])
				}
			}
			return true
		})
	}
		
	if(props.multi){
		return(
			<Select
				isMulti
				value            = {select_val}
				className        = "React"
				classNamePrefix  = "select"
				id               = {props.id_name}
				name             = {props.id_name}
				placeholder      = {props.place_holder}
				options          = {props.option}
				isOptionDisabled = {(option) => option.isdisabled === true}
				onChange         = {props.handleInputChange({pro_for:props.id_name,pro_as:"mulit_select"})}
				isLoading        = {props.Loading}
				isDisabled       = {props.isDisabled}
			/>
		)
	}else{
		return(
			<Select
				value     		 = {select_val}
				className        = "React"
				classNamePrefix  = "select"
				id               = {props.id_name}
				name             = {props.id_name}
				placeholder      = {props.place_holder}
				options          = {props.option}
				isOptionDisabled = {(option) => option.isdisabled === true}
				onChange         = {props.handleInputChange({pro_for:props.id_name,pro_as:"select"})}
				isLoading        = {props.Loading}
				isDisabled       = {props.isDisabled}
			/>
		)
	}
}

//==================================== COMPONENT - END ====================================

//==================================== STYLE - START ==================================

//DATA TABLE
export const Search_styles = {
	headCells: {
	  style: {
		borderTop: '1px solid #4a48481f',
		borderLeft: '1px solid #4a48481f',
		':last-child': {
			borderRight: '1px solid #4a48481f',
		},
	  },
	},
	cells: {
	  style: {
		borderLeft: '1px solid #4a48481f',
		':last-child': {
			borderRight: '1px solid #4a48481f',
		}
	  },
	},
};

//DATA TABLE USER AND MODULE_PERMISSIION
export const customStyles = {
	headCells: {
	  style: {
		borderTop: '1px solid #4a48481f',
		borderLeft: '1px solid #4a48481f',
		whiteSpace: "unset !important",
		overflow: "unset",
		padding: "4px 17px",
		':last-child': {
			borderRight: '1px solid #4a48481f',
		},
	  },
	},
	cells: {
	  style: {
		borderLeft: '1px solid #4a48481f',
		whiteSpace: "unset !important",
		overflow: "unset",
		padding: "4px 17px",
		':last-child': {
			borderRight: '1px solid #4a48481f',
		},
		'&:hover': {
			cursor: 'pointer',
		  },
	  },
	},
};
		 
//DATA TABLE USER AND MODULE_PERMISSION
export const conditionalRowStyles = [{
	when: row => row,
	style: {
	"div:first-child":{
		whiteSpace: "unset !important",
		overflow: "initial",
		textOverflow:"unset !important"
	}
	},
}];

export const bar_fill = [
    {
        match: {
            id: 'Ladies'
        },
        id: 'lines1'
    },
    {
        match: {
            id: 'Low Profile'
        },
        id: 'lines3'
    },
    {
        match: {
            id: 'Aged People'
        },
        id: 'lines4'
    },
    {
        match: {
            id: 'Out of station'
        },
        id: 'lines2'
    },
    {
        match: {
            id: 'Agents'
        },
        id: 'lines5'
    },
    {
        match: {
            id: 'Agent'
        },
        id: 'lines5'
    },
    {
        match: {
            id: 'Students'
        },
        id: 'lines6'
    },
   
    {
        match: {
            id: 'Already Lot Of Commitments'
        },
        id: 'lines7'
    },
    {
        match: {
            id: 'Woe'
        },
        id: 'lines1'
    },
    {
        match: {
            id: 'Has Own Advisor'
        },
        id: 'lines2'
    },
    {
        match: {
            id: 'Full Explanation'
        },
        id: 'lines3'
    },
    {
        match: {
            id: 'Dnd'
        },
        id: 'lines4'
    },
    {
        match: {
            id: 'Half Explained'
        },
        id: 'lines5'
    },
    {
        match: {
            id: 'Has Star Health'
        },
        id: 'lines7'
    },
    {
        match: {
            id: 'Has Corporate Policy'
        },
        id: 'lines8'
    },
    {
        match: {
            id: 'Has Other Health Insurance'
        },
        id: 'lines9'
    },
    {
        match: {
            id: 'No Money After Explained'
        },
        id: 'lines10'
    },
];

export const bar_def = [
    {
        id: 'lines1',
        type: 'patternLines',
        background: '#ffffff',
        color: '#ff3300',
        size: 4,
        padding: 1,
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        stagger: true
    },
    {
        id: 'lines2',
        type: 'patternLines',
        background: '#ffffff',
        color: '#ffff00',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines3',
        type: 'patternLines',
        background: '#ffffff',
        color: '#ff9900',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines4',
        type: 'patternLines',
        background: '#ffffff',
        color: '#33ff99',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines5',
        type: 'patternLines',
        background: '#ffffff',
        color: '#d98cb3',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines6',
        type: 'patternLines',
        background: '#ffffff',
        color: '#a3a375',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines7',
        type: 'patternLines',
        background: '#ffffff',
        color:'#cc0066',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines8',
        type: 'patternLines',
        background: '#ffffff',
        color:'#00ffcc',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines9',
        type: 'patternLines',
        background: '#ffffff',
        color:'#3333cc',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines10',
        type: 'patternLines',
        background: '#ffffff',
        color:'#ff0066',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    }
];

export const pie_def = [
    {
        id: 'lines1',
        type: 'patternLines',
        background: '#ffffff',
        color: '#ff3300',
        size: 4,
        padding: 1,
        stagger: true,
        rotation: -45,
        lineWidth: 6,
        spacing: 10
    },
    {
        id: 'lines2',
        type: 'patternLines',
        background: '#ffffff',
        color: '#ffff00',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines3',
        type: 'patternLines',
        background: '#ffffff',
        color: '#ff9900',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines4',
        type: 'patternLines',
        background: '#ffffff',
        color: '#33ff99',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines5',
        type: 'patternLines',
        background: '#ffffff',
        color: '#3385ff',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines6',
        type: 'patternLines',
        background: '#ffffff',
        color: '#a3a375',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines7',
        type: 'patternLines',
        background: '#ffffff',
        color:'#cc0066',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines8',
        type: 'patternLines',
        background: '#ffffff',
        color:'#66d9ff',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
        size: 4,
        padding: 1,
        stagger: true,
    }
];

export const pie_fill = [
	{
		match: {
			label:"Does Not Exist"
		},
		id: 'lines1'
	},
	{
		match: {
			label:"Call waiting"
		},
		id: 'lines2'
	},
	{
		match: {
			label: "Rnr"
		},
		id: 'lines3'
	},
	{
		match: {
			label: 'Switched Off'
		},
		id: 'lines4'
	},
	{
		match: {
			label: 'Network Error'
		},
		id: 'lines5'
	},
	
	{
		match: {
			label:"Fresh Investment"
		},
		id: 'lines1'
	},
	{
		match: {
			label:"Term"
		},
		id: 'lines2'
	},
	{
		match: {
			label: 'Health'
		},
		id: 'lines4'
	},
	{
		match: {
			label: 'Portfolio'
		},
		id: 'lines5'
	},
	{
		match: {
			label:"Met Followup ( Over call )"
		},
		id: 'lines1'
	},
	{
		match: {
			label:"Met Followup ( Met Followup )"
		},
		id: 'lines2'
	},
	{
		match: {
			label:"Login ( Over call )"
		},
		id: 'lines2'
	},
	{
		match: {
			label:"Login ( Login )"
		},
		id: 'lines4'
	},
	{
		match: {
			label:"Postponed ( Over call )"
		},
		id: 'lines3'
	},
	{
		match: {
			label:"Postponed ( Postponed )"
		},
		id: 'lines8'
	},
	{
		match: {
			label: 'Rnr ( Over call )'
		},
		id: 'lines4'
	},
	{
		match: {
			label: 'Rnr ( Rnr )'
		},
		id: 'lines1'
	},
	{
		match: {
			label: 'Met ( Face To Face )'
		},
		id: 'lines5'
	},
	{
		match: {
			label: 'Met Followup ( Warm )'
		},
		id: 'lines3'
	},
	{
		match: {
			label: 'Met Followup ( Hot )'
		},
		id: 'lines4'
	},
	{
		match: {
			label: 'Met ( Login )'
		},
		id: 'lines5'
	},
	{
		match: {
			label: 'Met ( Hot )'
		},
		id: 'lines7'
	},
	{
		match: {
			label: 'Met ( Warm )'
		},
		id: 'lines2'
	},
	{
		match: {
			label: 'Met ( Met )'
		},
		id: 'lines5'
	},
	{
		match: {
			label:"Warm"
		},
		id: 'lines1'
	},
	{
		match: {
			label:"Hot"
		},
		id: 'lines2'
	},
	{
		match: {
			label:"Dropped"
		},
		id: 'lines7'
	},
	{
		match: {
			label:"Dropped ( Over call )"
		},
		id: 'lines7'
	},
	{
		match: {
			label:"Dropped ( Dropped )"
		},
		id: 'lines6'
	},
	{
		match: {
			label:"Dropped ( Face To Face )"
		},
		id: 'lines1'
	},
	{
		match: {
			label:"Login"
		},
		id: 'lines3'
	},
	{
		match: {
			label:"CB Full Explanation"
		},
		id: 'lines1'
	},
	{
		match: {
			label:"Exisitng details not collected"
		},
		id: 'lines2'
	},
	{
		match: {
			label: 'CB WOE'
		},
		id: 'lines4'
	},
	{
		match: {
			label: 'CB Half Explained'
		},
		id: 'lines5'
	},
	{
		match: {
			label: 'Portability'
		},
		id: 'lines6'
	},
	{
		match: {
			label: 'ALL'
		},
		id: 'lines6'
	},
	{
		match: {
			label: 'Untouch'
		},
		id: 'lines6'
	},
	{
		match: {
			label: 'Group Policy'
		},
		id: 'lines6'
	},
	{
		match: {
			label:"Warm ( Met Followup )"
		},
		id: 'lines1'
	},
	{
		match: {
			label:"Warm ( Met )"
		},
		id: 'lines2'
	},
	{
		match: {
			label:"Login ( Met )"
		},
		id: 'lines7'
	},
	{
		match: {
			label:"Hot ( Met Followup )"
		},
		id: 'lines6'
	},
	{
		match: {
			label: "Rnr"
		},
		id: 'lines3'
	},
	{
		match: {
			label: 'Hot ( Met )'
		},
		id: 'lines4'
	},
	{
		match: {
			label: 'Postponed( OC )'
		},
		id: 'lines4'
	},
	{
		match: {
			label: 'Postponed'
		},
		id: 'lines4'
	},
];

export const pallette = [ 
	'#F50B2C',
	'#F5190A',
	'#D6310F',
	'#D63B0F',
	'#EB5A00',
	'#EB5A00',
	'#D67706',
	'#D68106', 
	'#FAA700',
	'#FAA700',
	'#FAB800',
]
//==================================== STYLE - END ====================================