import { history } from "../../../history"
import axios from "axios"
import { init_data,sidebar_init,get_token,send_post,get_rslt,get_process_env } from "../a_common/init.js"
const api_url             = "/user";
const login_url           = "/login";
const change_log_url      = "/user_change_log";
const user_permission_url = "/module_permission";

//GET SCHEME 
export const schema_action = () => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});
	return async dispatch => {
		await get_token(null, async function(token_rslt){
			if(token_rslt){
				var user_token     = token_rslt.token
				var pro_data       = init_info.pro_data
				pro_data.token     = user_token
				pro_data.url       = api_url+'/get_schema'
				pro_data.frm       = "get_schema"
				await send_post(pro_data,function(post_rslt){
					dispatch(post_rslt)
				});
			}
		});
    }
}

//GET COMMON DROP 
export const drop_action = () => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});
	return async dispatch => {
		await get_token(null, async function(token_rslt){
			if(token_rslt){
				var user_token     = token_rslt.token
				var pro_data       = init_info.pro_data
				pro_data.token     = user_token
				pro_data.url       = api_url+'/get_common_drop'
				pro_data.frm       = "get_common"
				await send_post(pro_data,function(post_rslt){
					dispatch(post_rslt)
				});
			}
		});
    }
}

async function set_token(token){
	var get_required = [
		localStorage.setItem("token", token)
	];
	await Promise.all(get_required);
}

//LOGIN
export const login_action = (form_data) => {
	return async dispatch => {
		localStorage.removeItem("token");
        await axios({
			method: 'post',
			// url: login_url+'/login',
			url: get_process_env()+login_url+'/login',
			data: form_data
        }).then(response => {
			if(response.data.sts){
				set_token(response.data.token).then(function () {
					const token   = localStorage.getItem('token');
					if(token){
						var loggedInUser = response.data
						dispatch({
							type: "login",
							sts:response.data.sts,
							msg:response.data.msg,
							payload: { loggedInUser, loggedInWith: "jwt" },
							token:response.data.token,
							error:response.data.error
						})
						// history.push("/")
					}else{
						dispatch({
							type: "login",
							sts:false,
							msg:"Unable to process,Please refresh and retry...",
							payload: {},
							token:"",
							error:""
						})
						//history.push("/login")
					}
				});
			}else{
				dispatch({
					type: "login",
					sts:response.data.sts,
					msg:response.data.msg,
					payload: {},
					token:"",
					error:response.data.error
				})
				history.push("/login")
			}			
        }).catch(function (error) {
			localStorage.removeItem("token")
			dispatch({
				type: "login",
				sts:false,
				msg:"Unable to process your request.",
				payload: {},
            	token:"",
				error:""
			})
			history.push("/login")
        });
    }
}

export const get_grands_action = () => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});
	return async dispatch => {
		await get_token(null, async function(token_rslt){
			if(token_rslt){
				var user_token     = token_rslt.token
				var pro_data       = init_info.pro_data
				pro_data.token     = user_token
				pro_data.url       = api_url+'/get_grands'
				pro_data.frm       = "get_grands"
				await send_post(pro_data,function(post_rslt){
					dispatch(post_rslt)
				});
			}
		});
    }
}

export const validate_action = () => {
	return async dispatch => {
		const token   = localStorage.getItem('token');
        await axios({
			headers:{"Authorization":token},
			method: 'post',
			// url: api_url+'/validate',
			url: get_process_env()+api_url+'/validate',
			data: {token:token}
        }).then(response => {
			if(response.data.sts){
				dispatch({ type: "validate" })
			}else{
				localStorage.removeItem("token")
				dispatch({ type: "logout", payload: {} ,token:""})
				history.push("/login")
			}
        }).catch(function (error) {
			localStorage.removeItem("token")
			dispatch({ type: "logout", payload: {} ,token:""})
			history.push("/login")
        });
    }
}

export const logout_action = () => {
	return dispatch => {
	  localStorage.removeItem("token")
	  dispatch({ type: "logout", payload: {},token:""})
	  history.push("/login")
	}
}

//INSERT & UPDATED 
export const addUpdate_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					form_data.pro_by   = user_info.id;

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/addUpdate'
					pro_data.frm       = "add_update"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//ONCAHNGE SEARCH FILTER
export const on_selected_action = (pro_for,pro_val) => {	
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(pro_for){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_on_selected'
					pro_data.frm       = "on_selected"
					pro_data.form_data = {pro_for:pro_for,pro_val:pro_val}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET INFORMATION BY ID
export const getInfo_action = (pro_id) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
	return async dispatch => {
		if(pro_id){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get'
					pro_data.frm       = "get_data"
					pro_data.form_data = {id:pro_id}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
	}
}

//SEARCH
export const search_action = (search_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});
	return async dispatch => {
		if(search_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					if(parseInt(search_data.page_count) > 0){
						search_data.page_count = search_data.page_count - 1;
					}
					search_data.skip = search_data.page_count*search_data.per_page;
				
					search_data.sort_order = -1;
					if(search_data.sort_order === "asc"){
						search_data.sort_order = 1;
					}
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/search'
					pro_data.frm       = "search"
					pro_data.form_data = search_data
					await send_post(pro_data,async function(post_rslt){
						post_rslt.search_value =  search_data.search_value;
						post_rslt.page_count   =  search_data.page_count;
						post_rslt.per_page     =  search_data.per_page;
						post_rslt.sort_by      =  search_data.sort_by;
						post_rslt.sort_order   =  search_data.sort_order;
						await dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
	}
}

//DELETE BY ID (For MULTI RECORDS SEND ID IN ARRAY)
export const delete_action = (delete_ids) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(delete_ids){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/delete'
					pro_data.frm       = "delete"
					pro_data.form_data = {
						id:delete_ids,
						pro_by:user_info.id
					}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//UPLOAD
export const upload_action = (import_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(import_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					import_data.pro_by   = user_info.id;

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/upload'
					pro_data.frm       = "upload"
					pro_data.form_data = import_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//IMPORT
export const import_action = (import_url) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(import_url){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/import'
					pro_data.frm       = "import"
					pro_data.form_data = {import_url:import_url}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//CHANGE PASSWORD
export const change_pwd_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					form_data.id       = user_info.id;

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/changePassword'
					pro_data.frm       = "change_pwd"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//FORGOT PASSWORD GET OTP
export const forgot_sent_otp_action = (form_data) => {	
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			var pro_data       = init_info.pro_data
			pro_data.url       = login_url+'/forgot_sent_otp'
			pro_data.frm       = "forgot_sent_otp"
			pro_data.form_data = form_data
			await send_post(pro_data,function(post_rslt){
				dispatch(post_rslt)
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//FORGOT PASSWORD GET OTP
export const forgot_validate_otp_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			var pro_data       = init_info.pro_data
			pro_data.url       = login_url+'/forgot_validate_otp'
			pro_data.frm       = "forgot_validate_otp"
			pro_data.form_data = form_data
			await send_post(pro_data,function(post_rslt){
				dispatch(post_rslt)
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//RESET PASSWORD
export const resetPwd_action = (pro_id) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(pro_id){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/resetPassword'
					pro_data.frm       = "reset_pwd"
					pro_data.form_data = {userId:pro_id,pro_by:user_info.id}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//In attendance get basic info
export const inattendance_getInfo_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					form_data.pro_by   = user_info.id;
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = login_url+'/get_info_time'
					pro_data.frm       = "inattendance"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//In attendance get basic info
export const attendance_selected_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					form_data.pro_by   = user_info.id;

					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = login_url+'/get_reason_type'
					pro_data.frm       = "attendance_selected"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//In attendance
export const inattendance_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					form_data.pro_by   = user_info.id;
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = login_url+'/check_in_out'
					pro_data.frm       = "check_in_out"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//CHANGE LOG SEARCH BY ID
export const change_log_search_action = (pro_id) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
	return async dispatch => {
		if(pro_id){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = change_log_url+'/search'
					pro_data.frm       = "change_log_search"
					pro_data.form_data = {userId:pro_id}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
	}
}
// CHANGE LOG GET INFO ACTION
export const changelog_getInfo_action = (pro_id) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
	return async dispatch => {
		if(pro_id){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = change_log_url+'/get'
					pro_data.frm       = "change_log_get_info"
					pro_data.form_data = {id:pro_id}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
	}
}
// CHANGE LOG ADD & UPDATE ACTION
export const change_log_addUpdate_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token      = token_rslt.token
					var user_info       = token_rslt.decoded
					form_data.pro_by    = user_info.id;
					form_data.loginCode = user_info.loginCode;
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = change_log_url+'/addUpdate'
					pro_data.frm       = "change_log_addUpdate"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//GET INFORMATION BY ID
export const user_getInfo_action = (pro_id) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
	return async dispatch => {
		if(pro_id){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = user_permission_url+'/get_info_based_userId'
					pro_data.frm       = "user_get_data"
					pro_data.form_data = {userId: pro_id}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
	}
}

//INSERT & UPDATED 
export const user_addUpdate_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					form_data.pro_by   = user_info.id;
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = user_permission_url+'/user_permission_add'
					pro_data.frm       = "user_add_update"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

// GET BIRTHDAY INFO
export const birthdayInfo_action = () => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		await get_token(null,async function(token_rslt){
			if(token_rslt){
				var user_token     = token_rslt.token
				var pro_data       = init_info.pro_data
				pro_data.token     = user_token
				pro_data.url       = login_url+'/birthday_info'
				pro_data.frm       = "birthday_info"
				pro_data.form_data = {}			
				await send_post(pro_data,function(post_rslt){
					dispatch(post_rslt)
				});
			}
		});
    }
}

//SIDEBAR HIDE & SHOW
export const sidebar_action = (pro_data,tr_info) => {
	var pro_mode = "UNK";
	sidebar_init(pro_data,function(init_rslt){
		pro_mode = init_rslt.pro_mode
		tr_info  = init_rslt.tr_info
	});
	
    return async dispatch => {
		if((parseInt(pro_data.pro_mod) === 1) || (parseInt(pro_data.pro_mod) === 2) || (parseInt(pro_data.pro_mod) === 3)){
			dispatch({ type:"sidebar",sidebar:false,pro_mode:pro_mode,pro_id:pro_data.pro_mod })
		}else
		if(parseInt(pro_data.pro_mod) === 4) {
			dispatch({ type:"sidebar",sidebar:true,pro_mode:pro_mode,pro_id:pro_data.pro_mod })
		}else
		if(parseInt(pro_data.pro_mod) === 5) {
			dispatch({ type:"sidebar",sidebar:true,pro_mode:pro_mode,pro_id:tr_info._id })
		}else{
			dispatch({ type:"sidebar",sidebar:false,pro_mode:pro_mode,pro_id:pro_data.pro_mod })
		}
    }
}
  
//TWO STEP AUTHTHENTICATION
export const two_step_authentic_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/process_auth'
					pro_data.frm       = "process_auth"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//OTP AUTHTHENTICATION
export const otp_validate_action = (form_data) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});	
    return async dispatch => {
		if(form_data){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = login_url+'/auth_validate'
					pro_data.frm       = "otp_validate"
					pro_data.form_data = form_data
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}