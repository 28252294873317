import React from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
//import navigationConfig from "../../../../../configs/navigationConfig"
import SideMenuGroup from "./SideMenuGroup"
import { Badge } from "reactstrap"
import { ChevronRight } from "react-feather"
import { history } from "../../../../../history"
import {connect} from "react-redux"
import {grands_action} from "../../../../../redux/actions/grands/index"
import * as Icon from "react-feather"
class SideMenuContent extends React.Component {
  constructor(props) {
    super(props)

    this.parentArr = []
    this.collapsedPath = null
    this.redirectUnauthorized = () => {
      history.push("/misc/not-authorized")
    }
  }
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
    nav_info:false
  }

  handleGroupClick = (id, parent = null, type = "") => {
    let open_group = this.state.activeGroups
    let active_group = this.state.currentActiveGroup
    let temp_arr = this.state.tempArr
    // Active Group to apply sidebar-group-active class
    if (type === "item" && parent === null) {
      active_group = []
      temp_arr = []
    } else if (type === "item" && parent !== null) {
      active_group = []
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length)
      } else {
        temp_arr = []
        temp_arr.push(parent)
      }
      active_group = temp_arr.slice(0)
    } else if (type === "collapse" && parent === null) {
      temp_arr = []
      temp_arr.push(id)
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0)
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length)
      } else {
        temp_arr.push(id)
      }
    } else {
      temp_arr = []
    }

    if (type === "collapse") {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function(obj) {
          return active_group.indexOf(obj) === -1
        })
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function(obj) {
            return !temp.includes(obj)
          })
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0)
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id)
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1)
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0)
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group
    })
  }

  initRender = parentArr => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false
    })
  }

  async componentDidMount(){
    const token   = localStorage.getItem('token');
    var get_required = [
      this.props.grands_action(token),
      this.initRender(this.parentArr[0] ? this.parentArr[0] : [])
		];
    await Promise.all(get_required).then(results => {
			if(this.props.grands.get_grands.sts){
        this.setState({
          nav_info: this.props.grands.get_grands.rslt,
        })
      }
		});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths)
      }
      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      )
    }
  }

  render() {
    // Loop over sidebar items
    // eslint-disable-next-line
    var analytics = [];
    analytics["dashboard"]         = 1;
    analytics["buiz"]              = 2;
	  analytics["jfw_tracker"]       = 3;
	  analytics['activity_chart']    = 4;
    analytics["issuance_tracker"]  = 5;
    analytics["zero_days_tracker"] = 6;

    
    var master = [];
    master["user"]                = 1;
    master["vendor"]              = 2;
    
    // master["common"]              = 3;
    // master["calling"]             = 4;
    // master["lnp"]                 = 5;
    // master["service"]             = 6;

    //common
    master["designation"]         = 3;
    master["gender"]              = 4;
    master["cafs_branch"]         = 5;
    master["cafs_channel"]        = 6;
    master["cafs_location"]       = 7;
    master["cafs_time"]           = 8;
    master["state"]               = 9;
    master["city"]                = 10;
    master["module_list"]         = 11;
    master["module_permission"]   = 12;
    master["mls"]                 = 13;

    //CALLING
    master["category"]            = 14;
    master["sub_category"]        = 15;
    master["annual_income"]       = 16;
    master["base_name"]           = 17;
    master["grade_name"]          = 18;
    master["lead_status"]         = 19;
    master["lead_type"]           = 20;
    master["prospect"]            = 21;
    master["main_disposition"]    = 22;
    master["sub_disposition"]     = 23;
    master["appointment_mode"]    = 24;
    master["appointment_type"]    = 25;
    master["appointment_area"]    = 26;
    master["appointment_status"]  = 27;
    master["met_status"]          = 28;
    master["dial_for"]            = 29;
    master["dial_category"]       = 30;
    master["payment_location"]    = 31;
    
    //LNP
    master["reason"]              = 32;
    master["reason_type"]         = 33;
    master["approval_status"]     = 34;

    //SERVICE 
    master["service_for"]         = 35;
    master["service_mode"]        = 36;
    master["service_status"]      = 37;
    master["service_type"]        = 38;
    master["meeting_review_type"] = 39;
    master["message_content"]     = 40;
    master["band"]                = 41;
    master["grade"]               = 42;
    master["brokerage_plan"]      = 43;
    master["brokerage_info"]      = 44;
    // ADDED NEW
	
    var my_profile = [];
    my_profile['attendance']  = 1
    my_profile['l_and_p']     = 2
    my_profile['resignation'] = 3
  
    var dms = [];
    dms["map_data"]          = 1;
    dms["upload_data"]       = 2;
    dms["untouch_data"]      = 3;
    dms["assign_count"]      = 4;   
    dms["assign_data"]       = 5;
    dms["assign_rollback"]   = 6;
    dms["churning"]          = 7;
    dms["churning_log"]      = 8;
    dms["todo_rollback"]     = 9;
    dms["exist_upload"]      = 10;
    dms["map_vendor"]        = 11;
    dms["upload_limit"]      = 12;
    dms["assign_limit"]      = 13;
    dms["upload_count_list"] = 14;

    var trans = [];
    trans["cold_calling"]  = 1;
    trans["appointment"]   = 2;
    trans["lead_confirm"]  = 3;
    trans["jfw"]           = 4;
    trans["lead_transfer"] = 5;
    trans["met_review"]    = 6;
    trans["service"]       = 7;
    trans["b2b"]           = 8;

    var reports = [];
    reports["usr_login_info"]       = 1;
    reports["vendor_dump_count"]    = 2;
    reports["activity_tracker"]     = 3;
    reports["followup_tracker"]     = 4;
    reports["assign_tracker"]       = 5;
    reports["performance_tracker"]  = 6;
    reports["todo_tracker"]         = 7;
    reports['attendance_tracker']   = 8;
	  reports["mobile_history"]       = 9;
	  reports["lead_type_tracker"]    = 10;
	  reports["vendor_lead_tracker"]  = 11;
    reports["brokerage_tracker"]    = 12;
    reports["time_tracker"]         = 13;
    reports["open_and_met_tracker"] = 14;
	
    var analytics_child = []
    var master_child    = [];
    var myprofile_child = [];
    var dms_child       = [];
    var trans_child     = [];
    var reports_child   = [];
    var can_show_todo   = false;
    var nav_obj = this.state.nav_info;
    Object.keys(nav_obj).forEach(function(nav_index){
      var nav_item   = nav_obj[nav_index]
      var moduleId   = nav_item.moduleId.moduleId
      var moduleName = nav_item.moduleId.moduleName
      var url        = "/"+moduleId

      if(moduleId === "todo"){
        can_show_todo = true;
      }
      if(analytics[moduleId]){
        var analytics_sort = analytics[moduleId];
        var analytics_url  = "/"
        if(moduleId !== "dashboard"){
          analytics_url  = "/"+moduleId
        }
        
        analytics_child.push({
          id: moduleId, title:moduleName, navLink: analytics_url,type: "item", collapsed: true,sort:analytics_sort
        });
      }else
      if(master[moduleId]){
        var master_sort = master[moduleId];
        master_child.push({
          id: moduleId, title:moduleName, navLink: url,type: "item", collapsed: true,sort:master_sort
        });
      }else
	    if(my_profile[moduleId]){
        var my_profile_sort = my_profile[moduleId];
        myprofile_child.push({
          id: moduleId, title:moduleName, navLink: url,type: "item", collapsed: true,sort:my_profile_sort
        });
      }else
      if(dms[moduleId]){
        var dms_sort = dms[moduleId];
        dms_child.push({
          id: moduleId, title:moduleName, navLink: url,type: "item", collapsed: true,sort:dms_sort
        });
      }else
      if(trans[moduleId]){
        var trans_sort = trans[moduleId];
        trans_child.push({
          id: moduleId, title:moduleName, navLink: url,type: "item", collapsed: true,sort:trans_sort
        });
      }else
      if(reports[moduleId]){
        var reports_sort = reports[moduleId];
        var reports_url  = "/reports/"+moduleId
        reports_child.push({
          id: moduleId, title:moduleName, navLink: reports_url,type: "item", collapsed: true,sort:reports_sort
        });
      }
    });

    analytics_child.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
    master_child.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
    myprofile_child.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
    dms_child.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
    trans_child.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
    reports_child.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
  
    var nav_data     = [];
    // nav_data.push({
    //   id:"dashboard",title:"Dashboard",type:"item",icon:<Icon.Home size={16}/>,collapsed:true
    // });
	
    if(can_show_todo){
      nav_data.push({
        id:"todo",title:"Todo",type:"item",icon:<Icon.CheckSquare size={16}/>,navLink:"/todo",collapsed:true
      });
    }
    if(analytics_child.length > 0){
      nav_data.push({
        id:"analytics", title:"Analytics",type:"collapse",icon:<Icon.Activity size={16} />,children:analytics_child
      });
    }

  	if(myprofile_child.length > 0){
      nav_data.push({
        id:"my_profile", title:"My Profile",type:"collapse",icon:<Icon.User size={16} />,children:myprofile_child
      });
    }
	
    if(master_child.length > 0){
      nav_data.push({
        id:"master", title:"Master",type:"collapse",icon:<Icon.Database size={16} />,children:master_child
      });
    }
	
    if(dms_child.length > 0){
      nav_data.push({
        id:"dms", title:"DMS",type:"collapse",icon:<Icon.UploadCloud size={16} />,children:dms_child
      });
    }

    if(trans_child.length > 0){
      nav_data.push({
        id:"transaction", title:"Transaction",type:"collapse",icon:<Icon.Phone size={16} />,children:trans_child
      });
    }

    if(reports_child.length > 0){
      nav_data.push({
        id:"reports", title:"Reports",type:"collapse",icon:<Icon.PieChart size={16} />,children:reports_child
      });
    }
    
    

   // const menuItems = navigationConfig.map(item => { //UDY REMOVED navigationConfig
      const menuItems = nav_data.map(item => {
        const CustomAnchorTag = item.type === "external-link" ? `a` : Link
        // checks if item has groupheader
        if (item.type === "groupHeader") {
          return (
            <li
              className="navigation-header"
              key={`group-header-${item.groupTitle}`}
            >
              <span>{item.groupTitle}</span>
            </li>
          )
        }

        let renderItem = (
          <li
            className={classnames("nav-item", {
              "has-sub": item.type === "collapse",
              open: this.state.activeGroups.includes(item.id),
              "sidebar-group-active": this.state.currentActiveGroup.includes(
                item.id
              ),
              hover: this.props.hoverIndex === item.id,
              active:
                (this.props.activeItemState === item.navLink &&
                item.type === "item" )|| (item.parentOf && item.parentOf.includes(this.props.activeItemState)),
              disabled: item.disabled
            })}
            key={item.id}
            onClick={e => {
              e.stopPropagation()
              if (item.type === "item") {
                this.props.handleActiveItem(item.navLink)
                this.handleGroupClick(item.id, null, item.type)
                if(this.props.deviceWidth <= 1200 && item.type === "item"){
                  this.props.toggleMenu()
                }
              } else {
                this.handleGroupClick(item.id, null, item.type)
              }
            }}
          >
            <CustomAnchorTag
              to={item.filterBase ? item.filterBase : item.navLink && item.type === "item" ? item.navLink :  ""}
              href={item.type === "external-link" ? item.navLink : ""}
              className={`d-flex ${
                item.badgeText
                  ? "justify-content-between"
                  : "justify-content-start"
              }`}
              onMouseEnter={() => {
                this.props.handleSidebarMouseEnter(item.id)
              }}
              onMouseLeave={() => {
                this.props.handleSidebarMouseEnter(item.id)
              }}
              key={item.id}
              onClick={e => {
                return item.type === "collapse" ? e.preventDefault() : ""
              }}
              target={item.newTab ? "_blank" : undefined}
            >
              <div className="menu-text">
                {item.icon}
                <span className="menu-item menu-title">{item.title}</span>
              </div>

              {item.badge ? (
                <div className="menu-badge">
                  <Badge color={item.badge} className="mr-1" pill>
                    {item.badgeText}
                  </Badge>
                </div>
              ) : (
                ""
              )}
              {item.type === "collapse" ? (
                <ChevronRight className="menu-toggle-icon" size={13} />
              ) : (
                ""
              )}
            </CustomAnchorTag>
            {item.type === "collapse" ? (
              <SideMenuGroup
                group={item}
                handleGroupClick={this.handleGroupClick}
                activeGroup={this.state.activeGroups}
                handleActiveItem={this.props.handleActiveItem}
                activeItemState={this.props.activeItemState}
                handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
                activePath={this.props.activePath}
                hoverIndex={this.props.hoverIndex}
                initRender={this.initRender}
                parentArr={this.parentArr}
                triggerActive={undefined}
                currentActiveGroup={this.state.currentActiveGroup}
                permission={this.props.permission}
                currentUser={this.props.currentUser}
                redirectUnauthorized={this.redirectUnauthorized}
                collapsedMenuPaths={this.props.collapsedMenuPaths}
                toggleMenu={this.props.toggleMenu}
                deviceWidth={this.props.deviceWidth}
              />
            ) : (
              ""
            )}
          </li>
        )
      
        if(item.navLink && item.collapsed !== undefined && item.collapsed === true){
          this.collapsedPath = item.navLink
          this.props.collapsedMenuPaths(item.navLink)
        }

        if(item.type === "collapse" || item.type === "external-link" || (item.type === "item" && item.permissions && item.permissions.includes(this.props.currentUser)) || item.permissions === undefined ) {
          return renderItem
        }else 
        if(item.type === "item" && item.navLink === this.props.activePath && !item.permissions.includes(this.props.currentUser)){
          return this.redirectUnauthorized()
        }
        return true
    })
    return <React.Fragment>{menuItems}</React.Fragment>
  }
}

const mapStateToProps = state => {
  return {
    grands: state.grands,
  }
}

export default connect(mapStateToProps, {
	grands_action,
})(SideMenuContent)
//export default SideMenuContent
