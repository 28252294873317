const initialState = {
	get_conversation:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	user_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	add_update:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	get_message:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	group_user_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	group_user_changes:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	exit_group:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	get_msg_details:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	send_message:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	total_unread_messages:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"_id",
		sort_order:-1
	},
}

const chat = (state = initialState, action) => {
	switch (action.type) {
		case "get_conversation":
			return {...state,
				get_conversation:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "user_list":
			return {...state,
				user_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "add_update":
			return {...state,
				add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "get_message":
			return {...state,
				get_message:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "group_user_list":
			return {...state,
				group_user_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "group_user_changes":
			return {...state,
				group_user_changes:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "exit_group":
			return {...state,
				exit_group:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "get_msg_details":
			return {...state,
				get_msg_details:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "send_message":
			return {...state,
				send_message:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "total_unread_messages":
			return {...state,
				total_unread_messages:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
				}
			}
        default:
			return state
	}
}  
export default chat