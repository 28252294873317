import { init_data,get_token,send_post,get_rslt } from "../a_common/init.js"
const api_url = "/user";

//GET GRANDS 
export const grands_action = (new_token) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});
	return async dispatch => {
		if((init_info)&&(new_token)){
			await get_token(new_token,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/get_grands'
					pro_data.frm       = "get_grands"
					pro_data.form_data = {
						userId:user_info.id
					}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}

//CHECK IS GRANTED
export const isGranted_action = (moduleId) => {
	var init_info = ""
	init_data(null,function(init_rslt){
		init_info = init_rslt
	});
	return async dispatch => {
		if(moduleId){
			await get_token(null,async function(token_rslt){
				if(token_rslt){
					var user_token     = token_rslt.token
					var user_info      = token_rslt.decoded
					var pro_data       = init_info.pro_data
					pro_data.token     = user_token
					pro_data.url       = api_url+'/is_granted'
					pro_data.frm       = "is_granted"
					pro_data.form_data = {
						moduleId:moduleId,
						userId:user_info.id
					}
					await send_post(pro_data,function(post_rslt){
						dispatch(post_rslt)
					});
				}
			});
		}else{
			init_info.frm = "invalid_formdata"
			await get_rslt(init_info,function(post_rslt){
				dispatch(post_rslt)
			});
		}
    }
}
