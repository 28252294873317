import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { Disc, X, Circle } from "react-feather"
import classnames from "classnames"
class SidebarHeader extends Component {
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow
    } = this.props
    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/" className="navbar-brand">
              <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD1UlEQVRYhcWXa0wcVRTHfzO7LMimwfogvIqAQYVEqYhANUiaSn1UG2P0g1bxix8aDSa1YvrFV31EpELTBpNaTTS1CammRCN1o6UqtWJbpKWlhQpI0WpNU5GS7q50Z871w26HHVjQ7SCc5CZz7jln/v+5d+4552pEiYiUADXAHcBVzK6cAdqVUk0ul6vTZjEMwy0i9SJiioj6n4cpInWhUEiP/vL6OQCePOoANBEpBToAnbkVAcp0wns+1+BEMGs0ERkGsueBAMBJTUSCQJKTtyilONxzgl1t39HTO8DI6BiJiR5yF2VQUV7MPctux5t8WazQgGMC+w528/wrjXR0HpnW58qFKTz3VDVrVj+GJyFhdggopXi1YSvrN2xBlPpPMSVFhbR80EBmeqpFwB0v8EVZ99om6ps+tM3lXZPFnZVlZKWn4g8E+bG7l2++78QwTACOnRjkp8HhaAIgIsF4z/DHn32pSF1sjasLlqrmFp8yDGOK7+DJU+q+Vc+opOwy5duzb7LdHzcBfyCoMouWW+BZRcvVwM+/zBhjGKbq6RuIZfPbt2D8b5QRiiyNQvN6QXfZXJpbfPx2+gwAmqbR/G4deTlZM26XrmsUXpcX02YjIH1HuPDWOoj8U3p+AZ4XN4FrgsQnn++2nlfeVclttxbNCP5vYsuAelEprqUrJgj192K27rB0pRSdh49b+kP3VzkCh3AtsB/DwHnG11ajzo2GHRIS8Lz5Hlr6IkSE1q/2IhJeoorym7liYYoT/BjHUNNBn1QaNM16dLvdiEhkWsOpTCEQ2taE+mvE0l0rH0VLy7IAn6h5gbMj4dXZ/s4bPPLg3Y4I2D5Vujowv/1iwphfgPuBVZauaRoliwstfWdrmyPwMGh0HggGlDl2Tpljo8oc/VOJEZpydrdu22nlAD2tWO3vOuqkKYk/EZ33B1TGjVUWidySe9XwqdMzxpimqQaGYiar+AmIiGpu8dlSccZNVepT39fKNM0pvr/+/od6+Mla5c1ZovbsPTCFwCVXw7UvNdC45SPb3A35uSyrKCUzLRV/MMiho320te9n/EI4uyYnJ9G6fTOVS265GHLp1XDDy2vwJifx+sb3UZFy3Nc/RF//0LQx11+bQ152pm3OcUPS3tFF7fpGDh46Nq3P5SkLeHb149Q+XU2ixxNtct4RQSRFdx9n1+7oliyB3OxMKsqLWVFVwQJvcqzQwLw3pTrQPk/gAO2aaZrlmqZ1zBOBMt3lcv0AbJwH8AZd1w8A1uW00UFKjXe8bRiGG8BWTyPbEX09n60rmwBnCV/PN0dWHYB/AMXRpBmTcp/oAAAAAElFTkSuQmCC' alt="CAFS360 Logo"/>
              <h2 className="brand-text mb-0">CAFS360</h2>
            </NavLink>
          </li>
          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                <Disc
                  onClick={() => {
                    toggleSidebarMenu(true)
                    toggle()
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark"
                    }
                  )}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                <Circle
                  onClick={() => {
                    toggleSidebarMenu(false)
                    toggle()
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark"
                    }
                  )}
                  size={20}
                />
              )}
              <X
                onClick={sidebarVisibility}
                className={classnames(
                  "toggle-icon icon-x d-block d-xl-none font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark"
                  }
                )}
                size={20}
              />
            </div>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false
          })}
        />
      </div>
    )
  }
}

export default SidebarHeader
