import React, { Component } from "react";
import {connect} from "react-redux"
import * as strap from "reactstrap"
import * as icon from "react-feather"
import classnames from "classnames"
import Avatar from "../../components/@vuexy/avatar/AvatarComponent"
import {get_conversation_action,get_user_list_action,add_update_action,get_message_action,group_user_list_action,group_user_changes_action,exit_group_action,get_msg_details_action,total_unread_messages_action, send_message} from '../../redux/actions/chat'
import  * as custom from "../a_common/init_component"
import  * as chat from "./chat_component"
import {processChange} from "../a_common/init"
import jwt_decode from "jwt-decode";
import "react-toastify/dist/ReactToastify.css"
import "../../assets/scss/plugins/extensions/toastr.scss"
import { toast ,ToastContainer} from "react-toastify"
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import socketConnection from "../a_common/chat_connection"

class module_manage extends Component {
    constructor(props){
        super(props);
        
        const token = localStorage.getItem('token');
        var decoded = {}
        if(token){
            decoded = jwt_decode(token);
        }

        var socket_connect = {}
        if(token){
            socket_connect= socketConnection;
        }

        this.state = {
            userId               : {userId:decoded.id },
            user_info            : decoded,
			grands_info          : false,
			drop_list	         : {groupMember:[]},
            socket               : socket_connect,
            group_val            : {groupName:'', groupMember: [] },
            toggle_chat_tab      : true,
            chat_show            : false,
            grp_create_show      : false,
            menu_show            : false,
            options_show1        : false,
            chat_bubble_show     : true,
            btn_loading          : false,
            activeTab            : '1',
            activeMenuTab        : '1',
            convo_render         : [],
            user_list_render     : [],
            group_user_list      : [],
            get_mess             : [],
            msg_bubble_render    : [],
            chat_name            : '',
            inbox                : '',
            file                 : [],
            user_search          : '',
            grp_search           : '',
            add_search           : '',
            add_user_render      : [],
            group_add_usr        : {groupMember:[]},
            group_remove_usr     : {groupMember:[]},
            group_add_usr_all    : false,
            group_remove_usr_all : false,
            disable_user         : false,
            convo_itm            : [],
            user_itm             : [],
            convo_snd_msg        : false,
            userlist_snd_msg     : false,
            create_grp_snd_msg   : false,
            send_msg_value       : {},
            online_user_list     : {},
            show_online_user     : false,
            conversation_lists   : [],
            msg_details          : {},
            typing_rslt          : [],
            grp_typing_rslt      : [],
            grp_typing_valid_id  : '',
            typing_valid_id      : '',
            showEmoji            : false,
            load_more            : false,
            load_more_count      : 1,
            form_error           : {},
            show_grp_btn         : false,
            grp_created_by       : '',
            msg_detail_toggle     : false,
            unseen_user          : [],
            seen_user            : [],
            preview_render       : [],
            preview_condition    : false
		};

        this.handleInputChange  = this.handleInputChange.bind(this);
		this.initSocket         = this.initSocket.bind(this);
		this.toggleMenu         = this.toggleMenu.bind(this);
		this.convoRowClick      = this.convoRowClick.bind(this);
		this.userRowClick       = this.userRowClick.bind(this);
		this.createGroup        = this.createGroup.bind(this);
		this.conversationList   = this.conversationList.bind(this);
		this.userList           = this.userList.bind(this);
		this.groupDropList      = this.groupDropList.bind(this);
		this.groupMemberList    = this.groupMemberList.bind(this);
		this.onSearch           = this.onSearch.bind(this);
		this.onChatSearch       = this.onChatSearch.bind(this);
		this.onGrpSearch        = this.onGrpSearch.bind(this);
		this.onAddSearch        = this.onAddSearch.bind(this);
		this.onRemoveSearch     = this.onRemoveSearch.bind(this);
		this.sendMsg            = this.sendMsg.bind(this);
		this.onChatBack         = this.onChatBack.bind(this);
		this.updateReadReciept  = this.updateReadReciept.bind(this);
		this.get_msg_details    = this.get_msg_details.bind(this);
		this.typing             = this.typing.bind(this);
		this.addEmoji           = this.addEmoji.bind(this);
		this.loadMore           = this.loadMore.bind(this);
		this.grpMenuClick       = this.grpMenuClick.bind(this);
		this.previewClose       = this.previewClose.bind(this);
	}

    async componentDidMount(){
        var get_required = [
            this.props.get_conversation_action(this.state.userId),
            this.props.get_user_list_action(this.state.userId),
		];
		await Promise.all(get_required).then(async results => {
            var conversation_lists = []; 
            var user_online        = []; 
            var convo_list         = this.props.chat.get_conversation
            if(convo_list.sts){
                if(this.state.socket){
                    this.state.socket.emit("online_user")
                }
                this.state.socket.on("online_user",async(online_usr)=>{
                    user_online = Object.values(online_usr)
                    conversation_lists = await this.conversationList(user_online,convo_list.rslt);
                    this.setState({convo_render:conversation_lists, online_user_list:user_online })
                })
            }
            this.initSocket();
            this.userList();
            this.groupDropList();
            this.updateReadReciept();
            this.msgBubble();
            // if(this.state.user_info.id){
            //     this.interval = setInterval(() => this.state.socket.emit("online_user"), 10000);
            // }
            //HIDE USER FROM CREATING GROUP
            var show_grp_btn = true;
            if((this.state.user_info.user_mode === "super_admin") || (this.state.user_info.user_mode === "area_manager") || (this.state.user_info.user_mode === "team_head")){
                show_grp_btn = true;
            }else
            if(this.state.user_info.user_mode === "user"){
                show_grp_btn = false;
            }
            (async () => {
				await this.setState({
					show_grp_btn:show_grp_btn
				})
			})();
        })
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevState.msg_bubble_render.length !== this.state.msg_bubble_render.length){
            await this.updateReadReciept()
            await this.msgBubble()
        }
    }

    //INIT SOCKET
    initSocket = async() => {
        if(this.state.user_info.id){
            await this.state.socket.on("send_message", (send_msg_value) => {this.socketSendMsg(send_msg_value)});
            await this.state.socket.on("grp_sent_message", (grp_send_msg_value) => this.socketGrpSendMsg(grp_send_msg_value));
            await this.state.socket.on("typing", (type) => {this.socketTyping(type)});
            await this.state.socket.on("group_typing", (type) => {this.groupSocketTyping(type)});
            await this.state.socket.on("get_conversation", (convo_upd) => this.socketConvoUpdate(convo_upd));
            await this.state.socket.on("get_group_activities", (mess) => this.get_group_activities(mess));
            await this.state.socket.on("conversation_page", (convo_page_redirect) => this.socketRedirectUser(convo_page_redirect));
        }
    }

    //SEND MSG - SOCKET FUNC
    async socketSendMsg(send_msg_value){
        var get_msg_req = {};
        var convo_itm = this.state.convo_itm;
        var get_mess  = this.state.get_mess;
        var send_msg_val = send_msg_value.rslt[0];
        if(send_msg_value.sts){
            if(((this.state.convo_itm.type === "chat") && (get_mess.at(-1).conversationId === convo_itm._id)) || this.state.userlist_snd_msg){
                await this.setState({get_mess: [...this.state.get_mess, send_msg_val ]})
                await this.updateReadReciept()
                await this.state.socket.emit("get_conversation",{receiver:send_msg_value.rslt[0].receiver, type:'chat'})
                if(get_mess.length){
                    get_msg_req['conversationId'] = get_mess.at(-1).conversationId
                    get_msg_req['type']           = 'chat';
                    await this.props.get_message_action(get_msg_req).then(async res => {
                        await this.setState({get_mess:this.props.chat.get_message.rslt.data})
                    })
                }
                if(send_msg_value.rslt[0].sender === this.state.userId.userId){
                    this.setState({inbox:'',file:[]})
                }
                this.setState({})
                this.state.socket.emit("total_unread_messages",{receiver:send_msg_value.rslt[0].receiver, type:'chat'})
                this.msgBubble();
                this.scrollToBottom();
            }
        }else{
            toast.error(send_msg_value.msg,{
                position: toast.POSITION.TOP_RIGHT
            })
            this.setState({inbox:'',file:[]})
        }
    }

    //GROUP SEND MSG - SOCKET
    async socketGrpSendMsg(grp_send_msg_value){
        var convo_itm = await this.state.convo_itm;
        var get_mess  = this.state.get_mess;
        if(grp_send_msg_value.sts){
            if(((convo_itm.type === "group") && (grp_send_msg_value.rslt[0].groupId === get_mess[0].groupId)) || this.state.create_grp_snd_msg ){
                await this.setState({get_mess: [...this.state.get_mess, grp_send_msg_value.rslt[0] ]})
                await this.updateReadReciept();
                await this.state.socket.emit("get_conversation",{groupId:grp_send_msg_value.rslt[0].groupId, sender:grp_send_msg_value.rslt[0].sender, type:'group'})
                await this.state.socket.emit("total_unread_messages",{groupId:grp_send_msg_value.rslt[0].groupId, type:'group'})
                this.msgBubble();
                this.scrollToBottom()
                if(grp_send_msg_value.rslt[0].sender === this.state.userId.userId){
                    this.setState({inbox:'',file:[]})
                }
            }
        }else{
            this.setState({inbox:'',file:[]})
            toast.error(grp_send_msg_value.msg,{
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    //REDIRECT USR TO CHAT PAGE WHEN REMOVED FROM GRP - SOCKET
    socketRedirectUser(convo_page_redirect){
        var create_res = this.props.chat.add_update;
        if((this.state.convo_itm.groupId === convo_page_redirect.rslt.groupId) || (this.state.create_grp_snd_msg && create_res.rslt.response._id === convo_page_redirect.rslt.groupId)){
            this.setState({
                chat_show:false, 
                toggle_chat_tab: 
                convo_page_redirect.rslt, 
                menu_show: false,
                activeTab:'1', 
                group_add_usr_all:false,
                group_remove_usr_all:false, 
                convo_snd_msg:false, 
                userlist_snd_msg:false, 
                create_grp_snd_msg:false,
                convo_itm:[],
                inbox:'',
                showEmoji:false,
                load_more:false, 
                load_more_count:1 
            })
            this.scrollToTop();
        }
    }

    //ADD & REMOVE USER NOTIFICATION MESSAGE
    async get_group_activities(mess){
        var convo_itm = this.state.convo_itm;
        if( (convo_itm.type === "group") && (mess.rslt.groupId === convo_itm.groupId) ){
            await this.setState({get_mess: [...this.state.get_mess, mess.rslt ]})
            this.state.socket.emit("get_conversation",{groupId:mess.rslt.groupId, sender:mess.rslt.sender, type:'group'})
            this.msgBubble();
            this.scrollToBottom();
        }
    }

    //CONVERSATION LIST UPDATE - SOCKET
    socketConvoUpdate(convo_upd){
        var convo_upds         = convo_upd;
        var conversation_lists = []; 
        var user_online        = []; 
        if(convo_upds.sts && convo_upds.sts !== 'undefined' ){
            this.state.socket.emit("online_user")
            this.state.socket.on("online_user",async(online_usr)=>{
                user_online = Object.values(online_usr)
                conversation_lists = await this.conversationList(user_online,convo_upds.rslt);
                this.setState({convo_render:conversation_lists, online_user_list:user_online})
            })
        }
    }

    //TYPING RENDER -SOCKET FUNC
    async socketTyping(type){
        var typing_rslt = this.state.typing_rslt
        if(type.receiver){
            if(type.receiver && type.isTyping){
                typing_rslt = <span style={{margin:'5px 0 0px 17px', display:'flex', justifyContent:'flex-start', color:'#33cc33', fontSize:'12px', fontWeight:'500'}}>Typing........</span>;
            }else{
                typing_rslt = [];
            }
            await this.setState({typing_rslt:typing_rslt, typing_valid_id: type.typing_valid_id})
        }
        await this.scrollToBottom()
    }   

    //GRP TYPING RENDER -SOCKET FUNC
    async groupSocketTyping(type){
        var grp_typing_rslt = this.state.grp_typing_rslt
        if(type.sender){
            if(type.sender && type.isTyping){
                grp_typing_rslt = <span style={{margin:'5px 0 0px 17px', display:'flex', justifyContent:'flex-start', color:'#33cc33', fontSize:'12px', fontWeight:'500'}}>{type.senderName} is typing........</span>;
            }else{
                grp_typing_rslt = [];
            }
            await this.setState({ grp_typing_rslt:grp_typing_rslt, grp_typing_valid_id: type.groupId})
        }
        await this.scrollToBottom()
    }

    //CHAT TYPING - SOCKET EMIT
    async typing(isTyping){
        var convo_item   = this.state.convo_itm;
        var user_itm   = this.state.user_itm;
        if(this.state.convo_snd_msg && this.state.convo_itm.type === 'chat'){
            await this.state.socket.emit("typing", {receiver:convo_item.user_rslt._id, isTyping:isTyping, typing_valid_id:convo_item._id })
        }
        if(this.state.userlist_snd_msg){
            await this.state.socket.emit("typing", {receiver:user_itm._id , isTyping:isTyping, typing_valid_id:user_itm.conversationId })
        }
    }

    //GRP CHAT TYPING - SOCKET EMIT
    async groupTyping(isTyping){
        var convo_itm  = this.state.convo_itm
        var create_itm = this.props.chat.add_update
        if(this.state.convo_snd_msg && this.state.convo_itm.type === "group"){
            await this.state.socket.emit("group_typing", {sender:this.state.userId.userId , isTyping: isTyping, groupId:convo_itm.groupId })
        }else
        if(this.state.create_grp_snd_msg){
            await this.state.socket.emit("group_typing", {sender:this.state.userId.userId , isTyping: isTyping, groupId:create_itm.rslt.response._id })
        }     
    }

    scrollToBottom(){
        if(this.state.chat_bubble_show && this.state.chat_show &&!this.state.menu_show){
            this.messagesEnd.scrollIntoView();
        }
    }

    scrollToMenuTop(){
        if(!this.state.chat_bubble_show && this.state.chat_show && this.state.menu_show){
            this.menuStart.scrollIntoView();
        }
    }

    scrollToTop(){
        if(this.state.toggle_chat_tab && this.props.active){
            this.convoStart.scrollIntoView();
        }
    }

    //ON INPUT CHANGE - SEARCH DATA
	handleInputChange = pro_data => event => {
		var group_val   = this.state.group_val;
		var search_drop = this.state.drop_list;
		var upd_form    = processChange(pro_data,event,group_val,search_drop);
		this.setState({group_val:upd_form});
	}

    onFileChange = async(event) => {
        if(event.target.files[0]){
            this.setState({ file: event.target.files[0]});
            //IMAGE PREVIEW
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); //USE BLOB
            reader.onloadend = function async (e){
                var rslt = (reader.result).includes("application/x-zip-compressed") ||  (reader.result).includes("application/vnd.") ||  (reader.result).includes("text/plain") ||  (reader.result).includes("application/octet-stream") || (reader.result).includes("application/json") || (reader.result).includes("text/javascript") || (reader.result).includes("application/pdf")
                var preview_render     = [];
                var preview_condition  = false;
                if(rslt){
                    preview_condition = true
                    preview_render = <chat.previewFile previewClose={this.previewClose} fileName={this.state.file.name} />
                }else{
                    preview_condition = false
                    if((reader.result).includes("video/mp4")){
                        preview_render = <chat.previewVideo previewClose={this.previewClose} fileName={this.state.file.name} />
                    }else{
                        preview_render = <chat.previewImage previewClose={this.previewClose} imageUrl={reader.result}/>
                    }
                }
                this.setState({ preview_render:preview_render, preview_condition:preview_condition })
            }.bind(this);
        }
	};

    async previewClose(){
        await this.setState({preview_render:'', file: '' })
    }

    //MAIN TAB TOGGLE
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    //GRP CHAT MENU -TOGGLE
    toggleMenu(tab) {
        if (this.state.activeMenuTab !== tab) {
            this.setState({ activeMenuTab: tab });
        }
    }

    //UPDATE READ RECIEPT
    async updateReadReciept(){
        var read_recpt_req = {};
        if(this.state.get_mess.length){
            var get_mess  = this.state.get_mess.at(-1)
            var convo_itm = this.state.convo_itm;
            var user_itm = this.state.user_itm;
                if(convo_itm.type === 'group' || this.state.create_grp_snd_msg){
                    read_recpt_req.conversationId = convo_itm._id;
                    read_recpt_req.userId         = this.state.userId.userId
                    read_recpt_req.pro_id         = get_mess._id
                    read_recpt_req.type           = "group"
                }else
                if(convo_itm.type === 'chat' || user_itm.conversationId){
                    read_recpt_req.conversationId = get_mess.conversationId;
                    read_recpt_req.userId         = this.state.userId.userId
                    read_recpt_req.pro_id         = get_mess._id
                    read_recpt_req.type           = "chat"
                }
            await this.state.socket.emit('update_readRecipients',read_recpt_req )
        }
    }

    //GET MSG DETAILS
    async get_msg_details(message){
        var get_msg_details_req = {msgId: message._id}
        await this.props.get_msg_details_action(get_msg_details_req).then( async res => {
            await this.setState({ msg_detail_toggle: !this.state.msg_detail_toggle})
            var msg_details = this.props.chat.get_msg_details.rslt;
            var unseen_user = [];
            if(Object.keys(msg_details).length > 1){
                if(msg_details.unread_user.length){
                    unseen_user = msg_details.unread_user.map(itm => {
                        return(<li key={itm.name} style={{display:'flex', justifyContent:'center', fontSize:'12px', margin:'5px 0'}}>{itm.name}</li>)
                    })
                    this.setState({unseen_user: unseen_user})
                }else{
                    unseen_user = (<li style={{display:'flex', justifyContent:'center', fontSize:'12px', margin:'5px 0'}}>None</li>)
                    this.setState({unseen_user: unseen_user})
                }
            }
            //SEEN USER MAP
            var seen_user = []
            if(Object.keys(msg_details).length > 1){
                if(msg_details.read_user.length){
                    seen_user = msg_details.read_user.map(itm => {
                        return(<li key={itm.name} style={{display:'flex', justifyContent:'center', fontSize:'12px', margin:'5px 0'}}>{itm.name}</li>)
                    })
                    this.setState({seen_user: seen_user})
                }else{
                    seen_user = (<li style={{display:'flex', justifyContent:'center', fontSize:'12px', margin:'5px 0'}}>None</li>)
                    this.setState({seen_user: seen_user})
                }
            }
        })
    }

    
    //CREATE GROUP DROPLIST ITEMS
    async groupDropList(){
        var drop_list = [];
        var user_list = this.props.chat.user_list.rslt
        drop_list.push({value: "all", label:'--- Select All ---'})
        user_list.map( user_itm => {
           return drop_list.push({value:user_itm._id, label:user_itm.display_name })
        })
        await this.setState({drop_list:{groupMember:drop_list} })
    }

    //ERROR VALIDATION FOR FORM - GRP CREATE
    handleError(){
        var form_error = this.state.form_error
        if(this.state.group_val.groupName.length === 0){
            form_error['groupName'] = "Please Enter Name"
        }else{
            form_error['groupName'] = ""
        }

        if(this.state.group_val.groupMember.length === 0){
            form_error['groupMember'] = "Please Select Users"
        }else{
            form_error['groupMember'] = ""
        }
        this.setState({ form_error:form_error });
    }

    //CREATE GROUP SUBMIT
    async createGroup(){
        this.setState({get_mess: {}})
        var group_val   = this.state.group_val
        var group_req   = {}
        var get_msg_req = {}
        if(group_val.groupMember.length === 0 && group_val.groupName.length === 0){
            this.handleError();
        }else{
            group_req.groupName   = group_val.groupName
            group_req.groupMember = group_val.groupMember
            group_req.pro_mode    = "ADD"
            group_req.pro_id      = ""
            group_req.pro_by      = this.state.userId.userId
            this.handleError();
            this.props.add_update_action(group_req).then(async rslt => {
                var grp_res = this.props.chat.add_update;
                if(grp_res.sts){
                    await this.state.socket.emit("get_conversation",{groupId:grp_res.rslt.response._id, sender:grp_res.rslt.response.createdBy, type:'group'})
                    get_msg_req['conversationId'] = grp_res.rslt.conversationId;
                    get_msg_req['type']           = 'group';
                    this.props.get_message_action(get_msg_req).then(async res => {
                        var get_mess = this.props.chat.get_message
                        this.setState({
                            grp_create_show:false, 
                            chat_show:true, 
                            toggle_chat_tab: false, 
                            chat_bubble_show:true, 
                            chat_name:grp_res.rslt.response.groupName, 
                            get_mess:get_mess.rslt.data, 
                            group_val:{groupName:'', groupMember:[]}, 
                            create_grp_snd_msg:true,
                            form_error:{}, 
                            grp_created_by:grp_res.rslt.response.createdBy
                        })
                        toast.success(grp_res.msg,{
                            position: toast.POSITION.TOP_RIGHT
                        })
                        this.msgBubble();
                        this.groupMemberList();
                    })
                }else{
                    toast.error(grp_res.msg,{
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
        }
    }

    //CONVERSATION LIST RENDER
    async conversationList(user_online,convo_list){
        if(convo_list.length){
            return convo_list.map( convo_itm => {
                return(
                    <chat.conversation_list 
                        key={Math.random()}
                        convo_itm={convo_itm}
                        convoRowClick={this.convoRowClick}
                        onlineUser={user_online}
                    />
                )
            })
        }else{
            return <h5 style={{display:'flex', justifyContent:'center', color:'#ff704d'}}>Chat is Empty</h5>
        }
    }

    //CONVO ROW CLICK
    async convoRowClick(convo_val){
        var convo_req   = {};
        var user_online = [];
        this.state.socket.emit("online_user")
        this.state.socket.on("online_user",async(online_usr)=>{
            user_online = Object.values(online_usr).includes(convo_val.user_rslt._id)
            await this.setState({show_online_user:user_online })
        })
        convo_req['conversationId'] = convo_val._id;
        convo_req['type']           = convo_val.type;
        convo_req['count']          = 50;
        convo_req['start']          = this.state.load_more_count ;
        this.props.get_message_action(convo_req).then(async res => {
            if(this.props.chat.get_message.sts){
                var get_mess = this.props.chat.get_message;
                await this.setState({ 
                    chat_show        :true, 
                    toggle_chat_tab  :false,
                    chat_bubble_show :true, 
                    menu_show        :false, 
                    get_mess         :get_mess.rslt.data,
                    load_more        :get_mess.rslt.load_more,
                    chat_name        : convo_val.user_rslt.name,
                    convo_itm        :convo_val ,
                    convo_snd_msg    : true,
                })
            }
            await this.updateReadReciept();
            this.msgBubble()
            this.scrollToBottom()
            this.groupMemberList()
            this.state.socket.emit("total_unread_messages",{receiver: this.state.userId.userId, type:'chat', self_unread: true})
        })
    }

    //CONVO LIST - SEARCH
    onChatSearch(e){
        var search_req = {};
        search_req.userId       = this.state.userId.userId;
        search_req.search_value = e.target.value;
        this.props.get_conversation_action(search_req).then(res => {
            var conversation_lists = []; 
            var user_online        = []; 
            var convo_list         = this.props.chat.get_conversation
            if(convo_list.sts){
                this.state.socket.emit("online_user")
                this.state.socket.on("online_user",async(online_usr)=>{
                    user_online = Object.values(online_usr)
                    conversation_lists = await this.conversationList(user_online,convo_list.rslt);
                    this.setState({convo_render:conversation_lists, online_user_list:user_online })
                }) 
            }
        })
    }
    
    //USER LIST RENDER
    userList(){
        var user_list = this.props.chat.user_list.rslt 
        var user_item = user_list.map( user_itm => {
            return(
                <chat.user_list 
                    key={Math.random()}
                    user_itm={user_itm}
                    userRowClick={this.userRowClick} />
            )
        })
        this.setState({user_list_render:user_item})
    }

    //USER ROW CLICK
    async userRowClick(user_value){
        var user_list_req = {};
        var user_online   = [];
        this.state.socket.emit("online_user")
        this.state.socket.on("online_user",async(online_usr)=>{
            user_online = Object.values(online_usr).includes(user_value._id)
            await this.setState({show_online_user:user_online })
        })
        user_list_req['conversationId'] = user_value.conversationId;
        user_list_req['type']           = "chat";
        user_list_req['count']          = 50;
        user_list_req['start']          = this.state.load_more_count ;
        this.props.get_message_action(user_list_req).then(async res => {
            var get_mess = this.props.chat.get_message
            if(get_mess.sts){
                await this.setState({ 
                    chat_show:true,
                    menu_show:false,
                    toggle_chat_tab:false, 
                    get_mess:get_mess.rslt.data, 
                    chat_name:user_value.name, 
                    user_itm:user_value,
                    userlist_snd_msg:true,
                    chat_bubble_show:true,
                    load_more        :get_mess.rslt.load_more,
                })
            }else{
                await this.setState({ 
                    chat_show:true, 
                    menu_show:false,
                    toggle_chat_tab:false, 
                    chat_bubble_show:true, 
                    get_mess:get_mess.rslt.data, 
                    chat_name:user_value.name, 
                    user_itm:user_value, 
                    userlist_snd_msg:true,
                    load_more        :get_mess.rslt.load_more,
                })
            }
            await this.updateReadReciept();
            this.msgBubble()
            await this.scrollToBottom()
            this.state.socket.emit("total_unread_messages",{receiver: this.state.userId.userId, type:'chat', self_unread: true})
        })
    }

    //USERLIST - SEARCH
    async onSearch(e){
        this.setState({user_search: e.target.value})
        var search_req = {};
        search_req.userId       = this.state.userId.userId;
        search_req.search_value =  e.target.value;
        this.props.get_user_list_action(search_req).then(res => {
            this.userList();
        })
    }

    //CHAT BACK BTN FUNC
    async onChatBack(){
        await this.props.get_conversation_action(this.state.userId)
        var conversation_lists = []; 
        var user_online        = []; 
        var convo_list = this.props.chat.get_conversation.rslt
        await this.state.socket.emit("online_user")
        await this.state.socket.on("online_user",async(online_usr)=>{
            user_online = Object.values(online_usr)
            conversation_lists = await this.conversationList(user_online,convo_list)
            this.setState({convo_render: conversation_lists, online_user_list:user_online})
        }) 
        this.setState({
            chat_show:false, 
            toggle_chat_tab:true,
            menu_show:false,
            activeTab:'1', 
            group_add_usr_all:false, 
            group_remove_usr_all:false, 
            convo_snd_msg:false, 
            userlist_snd_msg:false, 
            create_grp_snd_msg:false,
            convo_itm:[],
            inbox:'',
            showEmoji:false,
            load_more:false, 
            load_more_count:1 
        })
        this.scrollToTop()
    }
    
    // CHAT MSG BUBBLE RENDER
    msgBubble(){
        var get_mess   = this.state.get_mess;
        var msg_bubble = []
        var msg_date   = '';
        var today = new Date()
        var todays = today.toLocaleDateString('en-GB')
        
        msg_bubble = Object.values(get_mess).map( (message, i) => {
            var date         = new Date(message.date)
		    msg_date         = date.toLocaleDateString('en-GB')
            var msg_dataa    = []
            var current_date = ''
            if(todays === msg_date){
                current_date = new Date(get_mess[i].date).toLocaleDateString('en-GB')
                if(get_mess[i-1] && (current_date === new Date(get_mess[i-1].date).toLocaleDateString('en-GB'))){
                    msg_dataa.push(
                    <chat.msg_bubble
                        key={Math.random()}
                        message={message}  
                        user_id={this.state.userId.userId} 
                        get_msg_details={this.get_msg_details} 
                        msg_details={this.state.msg_details} 
                        convo_snd_msg={this.state.convo_snd_msg} 
                        convo_itm={this.state.convo_itm}
                        userlist_snd_msg={this.state.userlist_snd_msg}
                        create_grp_snd_msg={this.state.create_grp_snd_msg}
                    />
                    )
                }else{
                    msg_dataa.push(
                        <>
                        <strap.Row style={{ display:'flex', justifyContent:'center'}}>
                            <span style={{padding:'0 5px 5px 5px', fontWeight:'500'}}> ----------------------- Today ----------------------- </span>
                        </strap.Row>
                        <chat.msg_bubble
                            key={Math.random()}
                            message={message}  
                            user_id={this.state.userId.userId} 
                            get_msg_details={this.get_msg_details} 
                            msg_details={this.state.msg_details} 
                            convo_snd_msg={this.state.convo_snd_msg} 
                            convo_itm={this.state.convo_itm}
                            userlist_snd_msg={this.state.userlist_snd_msg}
                            create_grp_snd_msg={this.state.create_grp_snd_msg}
                        />
                        </>
                    )
                }
            }else
            if(todays !== msg_date){
                current_date = new Date(get_mess[i].date).toLocaleDateString('en-GB')
                if(get_mess[i-1] && current_date === new Date(get_mess[i-1].date).toLocaleDateString('en-GB')){
                    msg_dataa.push(
                        <chat.msg_bubble
                            key={Math.random()}
                            message={message}  
                            user_id={this.state.userId.userId} 
                            get_msg_details={this.get_msg_details} 
                            msg_details={this.state.msg_details} 
                            convo_snd_msg={this.state.convo_snd_msg} 
                            convo_itm={this.state.convo_itm}
                            userlist_snd_msg={this.state.userlist_snd_msg}
                            create_grp_snd_msg={this.state.create_grp_snd_msg}
                        />
                    )
                }else{
                    msg_dataa.push( 
                        <>
                        <strap.Row style={{ display:'flex', justifyContent:'center'}}>
                            <span style={{padding:'0 5px 5px 5px', fontWeight:'500'}}> -------------------- {msg_date} -------------------- </span>
                        </strap.Row>
                        <chat.msg_bubble
                            key={Math.random()}
                            message={message}  
                            user_id={this.state.userId.userId} 
                            get_msg_details={this.get_msg_details} 
                            msg_details={this.state.msg_details} 
                            convo_snd_msg={this.state.convo_snd_msg} 
                            convo_itm={this.state.convo_itm}
                            userlist_snd_msg={this.state.userlist_snd_msg}
                            create_grp_snd_msg={this.state.create_grp_snd_msg}
                        />
                        </>
                    )
                }
            }
            return msg_dataa
        })
        this.setState({ msg_bubble_render: msg_bubble })
    }

    // SEND MSG
    async sendMsg(){
        var send_msg_req = {}
        var user_itm     = this.state.user_itm
        var convo_itm    = this.state.convo_itm
        var create_grp   = this.props.chat.add_update

        //REQ
        send_msg_req.sender = this.state.userId.userId;
        
        var msg_info = []
        if(this.state.file.name && this.state.inbox.length === 0){
            msg_info.push(
                {files:{data:this.state.file, name:this.state.file.name, size:this.state.file.size} },
            )
        }else 
        if(this.state.inbox.length > 0 && !this.state.file.name){
            msg_info.push(
                {inbox:this.state.inbox}
            )
        }else
        if(this.state.file.name && this.state.inbox.length > 0 ){
            msg_info.push(
                {files:{data:this.state.file, name:this.state.file.name, size:this.state.file.size} },
                {inbox:this.state.inbox}
            )
        }
        send_msg_req.msg_info = msg_info;

        if(this.state.convo_snd_msg){
            if(convo_itm.type === 'chat'){
                send_msg_req.receiver = convo_itm.user_rslt._id;
            }else
            if(convo_itm.type === 'group'){
                send_msg_req.groupId = convo_itm.groupId;
            }
        }else
        if(this.state.userlist_snd_msg){
            send_msg_req.receiver = user_itm._id;
        }else
        if(this.state.create_grp_snd_msg){
            send_msg_req.groupId = create_grp.rslt.response._id;
        }

        //SOCKER IO EMIT - SEND MSG
        if(Object.keys(send_msg_req).includes("receiver") && (this.state.inbox.length > 0 || this.state.file.name)){
            this.state.socket.emit("send_message", send_msg_req)
        }else
        if(Object.keys(send_msg_req).includes("groupId") && (this.state.inbox.length > 0 || this.state.file.name)){
            this.state.socket.emit("grp_sent_message", send_msg_req)
        }
        this.setState({showEmoji:false,preview_render:[]})
    }

    //GRP MEMBER LIST
    groupMemberList(){
        var group_users_req = {};
        if(this.state.get_mess.length){
            group_users_req['groupId']      = this.state.get_mess[0].groupId;
            group_users_req['search_value'] = '';
            this.props.group_user_list_action(group_users_req).then(res => {
                var grp_user = this.props.chat.group_user_list;
                var group_user_lists = grp_user.rslt.map( user_itm => {
                    return <chat.group_members key={Math.random()} user_itm={user_itm} onlineUser={this.state.online_user_list} />
                })
                this.setState({group_user_list: group_user_lists})
            })
        }
    }

    //GROUP MEMBER LIST SEARCH
    onGrpSearch(e){
        this.setState({grp_search: e.target.value})
        var grp_search_req          = {};
        grp_search_req.groupId      = this.state.get_mess[0].groupId;
        grp_search_req.search_value = e.target.value;
        this.props.group_user_list_action(grp_search_req).then(res => {
            var grp_user = this.props.chat.group_user_list;
            var group_user_lists = grp_user.rslt.map( user_itm => {
                return <chat.group_members user_itm={user_itm}/>
            })
            this.setState({group_user_list: group_user_lists})
        })
    }

    //ADD USER API 
    async addMembers(){
        var group_users_req = {};
        group_users_req['groupId'] = this.state.get_mess[0].groupId;
        group_users_req['userId']  = this.state.userId.userId
        await this.props.get_user_list_action(group_users_req).then(res => {
            this.props.chat.user_list.rslt.map((add_user_info)=>{
                if(!add_user_info.isdisabled){
                    this.setState({disable_user:true})
                }
                return true
            })
        })     
    }

    //ADD GRP USR TAB - SEARCH
    onAddSearch(e){
        this.setState({add_search: e.target.value})
        var add_search_req = {};
        add_search_req.userId       = this.state.userId.userId;
        add_search_req.groupId      = this.state.get_mess[0].groupId;
        add_search_req.search_value = e.target.value;
        this.props.get_user_list_action(add_search_req)
    }

    //ADD USER SUBMIT
    async addUserSubmit(){
        var get_msg_req   = {};
        var group_add_req = {};
        var groupMember   = this.state.group_add_usr.groupMember;
        group_add_req['groupId']     = this.state.get_mess[0].groupId;
        group_add_req['userId']      = this.state.userId.userId
        group_add_req['pro_mode']    = "ADD"
        group_add_req["groupMember"] = Object.values(groupMember)
        group_add_req['pro_by']      = this.state.userId.userId

        this.props.group_user_changes_action(group_add_req).then(async res => {
            var group_add_usr = this.props.chat.group_user_changes;
            if(group_add_usr.sts){
                await this.state.socket.emit("get_group_activities", {groupId:this.state.get_mess[0].groupId})
                await this.state.socket.emit("get_conversation",{groupId:group_add_usr.rslt._id, sender:this.state.userId.userId, type:'group'})
                this.setState({menu_show:true, chat_bubble_show:false, activeMenuTab:'1', group_add_usr_all:false, group_add_usr:{groupMember:[] }})
                toast.success(group_add_usr.msg,{
					position: toast.POSITION.TOP_RIGHT
				})
                if(this.state.convo_snd_msg){
                    get_msg_req['conversationId'] = this.state.convo_itm._id;
                    get_msg_req['type']           = 'group';
                }else
                if(this.state.create_grp_snd_msg){
                    get_msg_req['conversationId'] = this.props.chat.add_update.rslt.conversationId;
                    get_msg_req['type']           = 'group';
                }
                this.props.get_message_action(get_msg_req).then(async res => {
                    var get_mess = await this.props.chat.get_message.rslt.data
                    await this.setState({get_mess:get_mess})
                    await this.msgBubble()
                })
                this.groupMemberList();
                await this.state.socket.emit("total_unread_messages",{groupId:group_add_usr.rslt._id, type:'group'})
            }
        })
    }

    //REMOVE USER API  
    async removeMembers(){
        var group_users_req = {};
        group_users_req['groupId']      = this.state.get_mess[0].groupId;
        group_users_req['search_value'] = '';
        await this.props.group_user_list_action(group_users_req)
    }

    //REMOVE GRP UST TAB _SEARCH
    onRemoveSearch(e){
        this.setState({remove_search: e.target.value})
        var remo_search_req           = {};
        remo_search_req.groupId       = this.state.get_mess[0].groupId;
        remo_search_req.search_value  = e.target.value;
        this.props.group_user_list_action(remo_search_req)
    }

    //REMOVE USER SUBMIT
    removeUserSubmit(){
        var get_msg_req                 = {};
        var group_remove_req            = {};
        var groupMember                 = this.state.group_remove_usr.groupMember;
        group_remove_req['groupId']     = this.state.get_mess[0].groupId;
        group_remove_req['userId']      = this.state.userId.userId
        group_remove_req['pro_mode']    = "REMOVE"
        group_remove_req["groupMember"] = Object.values(groupMember)
        group_remove_req['pro_by']      = this.state.userId.userId
        var removed_user = Object.values(groupMember)
        this.props.group_user_changes_action(group_remove_req).then(async res => {
            var group_remove_usr = this.props.chat.group_user_changes;
            if(group_remove_usr.sts){
                await this.state.socket.emit("get_group_activities", {groupId:this.state.get_mess[0].groupId})
                this.setState({menu_show:true, chat_bubble_show:false, activeMenuTab:'1',group_remove_usr_all:false, group_remove_usr:{groupMember:[]} })
                toast.success(group_remove_usr.msg,{
                    position: toast.POSITION.TOP_RIGHT
				})
                if(this.state.convo_snd_msg){
                    get_msg_req['conversationId'] = this.state.convo_itm._id;
                    get_msg_req['type']           = 'group';
                }else
                if(this.state.create_grp_snd_msg){
                    get_msg_req['conversationId'] = this.props.chat.add_update.rslt.conversationId;
                    get_msg_req['type']           = 'group';
                }
                this.props.get_message_action(get_msg_req).then(async res => {
                    var get_mess = await this.props.chat.get_message.rslt.data
                    await this.setState({get_mess:get_mess})
                    await this.msgBubble()
                })
                this.groupMemberList()
                //REDIRECT USR TO CHAT PAGE
                await removed_user.map(async usr => {
                    this.state.socket.emit("get_conversation",{receiver:usr,type:'chat'})
                    await this.state.socket.emit("conversation_page",{userId:usr, groupId:this.state.get_mess[0].groupId}) 
                })
                await this.state.socket.emit("total_unread_messages",{groupId:group_remove_usr.rslt._id, type:'group'})
                await this.state.socket.emit("get_conversation",{groupId:group_remove_usr.rslt._id, sender:this.state.userId.userId, type:'group'})
            }
        })
    }

    //ONCHANGE EVENT FOR CHECKBOX ADD USER
    group_add_change = async(pro_data) => {
        var group_add_usr     = this.state.group_add_usr
        var group_add_usr_all = this.state.group_add_usr_all
        var user_list         = this.props.chat.user_list.rslt
        var group_add         = []
        if(pro_data.pro_from === "add"){
            group_add =  chat.group_add_change_comp(pro_data,group_add_usr,user_list,group_add_usr_all);
            await this.setState({ group_add_usr: group_add})
        }
        if(pro_data.pro_from === "all" && user_list.filter(e => e.isdisabled === false)){
            var select_all_value = user_list.filter(obj => {
                return obj.isdisabled === false
            })
            await this.setState({group_add_usr_all:!this.state.group_add_usr_all})
            group_add =  chat.group_add_change_comp(pro_data,group_add_usr,select_all_value,group_add_usr_all);
            await this.setState({ group_add_usr: group_add})
        }
    }
    
    //ONCHANGE EVENT FOR CHECKBOX REMOVE USER
    group_remove_change = async(pro_data) => {
        var group_remove_usr     = this.state.group_remove_usr
        var group_remove_usr_all = this.state.group_remove_usr_all;
        var user_list            = this.props.chat.group_user_list.rslt
        var userId               = this.state.userId.userId
        var group_remove         = [];
        if(pro_data.pro_from === "remove"){
            group_remove = chat.group_remove_change_comp(pro_data,group_remove_usr,user_list,group_remove_usr_all)
            await this.setState({group_remove_usr: group_remove})
        }else
        if(pro_data.pro_from === "all"){
            await this.setState({group_remove_usr_all:!this.state.group_remove_usr_all})
            group_remove = chat.group_remove_change_comp(pro_data,group_remove_usr,user_list,group_remove_usr_all,userId)
            await this.setState({group_remove_usr: group_remove})
        }
    }

    //LEAVE GROUP
    exitGroup(){
        var get_mess            = this.state.get_mess;
        var exit_grp_req        = {};
        exit_grp_req['groupId'] = get_mess[0].groupId;
        exit_grp_req['userId']  = this.state.userId.userId;
        this.props.exit_group_action(exit_grp_req).then(async (res) => {
            var exit_grp_res = this.props.chat.exit_group
            if(exit_grp_res.sts){
                await this.state.socket.emit("get_group_activities",{groupId:this.state.get_mess[0].groupId})
                toast.success(exit_grp_res.msg,{
                    position: toast.POSITION.TOP_RIGHT
                })
                this.setState({chat_show:false, toggle_chat_tab:true, menu_show:false, chat_bubble_show: true, activeTab:'1' })
                await this.props.get_conversation_action(this.state.userId)
                var conversation_lists = []; 
                var user_online        = []; 
                var convo_list  = this.props.chat.get_conversation.rslt
                this.state.socket.emit("online_user")
                this.state.socket.on("online_user",async(online_usr)=>{
                    user_online = Object.values(online_usr)
                    conversation_lists = await this.conversationList(user_online,convo_list)
                    this.setState({convo_render:conversation_lists, online_user_list:user_online })
                })
                await this.state.socket.emit("get_conversation",{groupId:exit_grp_res.rslt._id, sender:this.state.userId.userId,type:'group'})
                await this.state.socket.emit("total_unread_messages",{groupId:exit_grp_res.rslt._id, type:'group'})
            }
        })
    }

    //EMOJI
    addEmoji(e){
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        this.setState({inbox: this.state.inbox + emoji  })
    }

    //LOAD MORE MSG
    async loadMore(){
        var load_more_req = {};
        await this.setState({ load_more_count: this.state.load_more_count + 1, btn_loading:true})
        var convo_itm = this.state.convo_itm;
        var userlist_snd_msg = this.state.userlist_snd_msg;
        var get_mess  = this.state.get_mess;
        if(this.state.convo_snd_msg || userlist_snd_msg){
            if(convo_itm.type === 'chat' || userlist_snd_msg){
                load_more_req.conversationId = get_mess[0].conversationId;
                load_more_req.type           = 'chat';
                load_more_req.count          = 50;
                load_more_req.start          = this.state.load_more_count ;
            }else
            if(convo_itm.type === 'group'){
                load_more_req.conversationId = get_mess[0].conversationId;
                load_more_req.type           = 'group';
                load_more_req.count          = 50;
                load_more_req.start          = this.state.load_more_count;
            }
        }
        await this.props.get_message_action(load_more_req).then(async res => {
            var load_mess = this.props.chat.get_message.rslt
            var new_arr = load_mess.data.concat(get_mess)
            await this.setState({get_mess:new_arr, btn_loading:false, load_more: load_mess.load_more, })
            await this.msgBubble()
        })
    }

    async grpMenuClick(){
        await this.setState({menu_show:!this.state.menu_show, chat_bubble_show:!this.state.chat_bubble_show, showEmoji:false, activeMenuTab:"1",grp_search:'',add_search:'',remove_search:''}); 
        this.groupMemberList();
        this.state.socket.emit("total_unread_messages",{receiver: this.state.userId.userId, type:'chat', self_unread: true})
        if(!this.state.menu_show){
            this.scrollToBottom();
        }else{
            this.scrollToMenuTop();
        }
    }

    render(){
        if(this.state.user_info.name){
            var avatar = chat.avatar(this.state.user_info.name); //AVATAR
        }
        if(this.state.chat_name){
            var chatAvatar = chat.chatAvatar(this.state.chat_name); //AVATAR
        }
        var center_item2 = chat.center_item
        center_item2.width = "90px"
        center_item2.padding = "8px 0"

        // ADD USERS CHECKBOX RENDER
        var group_add = [];
        if(this.props.chat.user_list.rslt.filter(e => e.isdisabled === false).length > 0){
            group_add.push( <chat.select_all_checkbox_add key={Math.random()} group_add_change={this.group_add_change} group_add_usr_all={this.state.group_add_usr_all}/> ) //SELECT ALL ADD CHECKBOX
        }else
        if(this.props.chat.user_list.rslt.filter(e => e.isdisabled === true).length > 0){
            group_add.push(
                <span style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', margin:'85px 0'}}>
                    <icon.UserX size='30' color='#ff704d' />
                    <h5 style={{color:'#ff704d',fontWeight:'500'}}>No User to Add</h5>
                </span>
            )
        }
        this.props.chat.user_list.rslt.map((add_user_info)=>{
            var display_user = add_user_info.display_name
            var icon         = add_user_info.icon
            var user_id      = add_user_info._id
            var disable_user = add_user_info.isdisabled
            var user_name    = display_user.split("-") //SPLIT NAME AND LOGIN CODE
            if(!disable_user){
                group_add.push( 
                    <chat.checkbox_add 
                        key={Math.random()}
                        user_name={user_name}
                        icon={icon} 
                        user_id={user_id} 
                        group_add_change={this.group_add_change} 
                        group_add_usr={this.state.group_add_usr} 
                    />
                )
            }
            return true
        })
        
        //REMOVE USERS CHECKBOX RENDER
        var group_remove = [];
        if(this.props.chat.group_user_list.rslt.length > 1){
            group_remove.push(<chat.select_all_checkbox_remove key={Math.random()} group_remove_change={this.group_remove_change} group_remove_usr_alls={this.state.group_remove_usr_all} /> ) //SELECT ALL - REMOVE CHECKBOX
        }else
        if(this.props.chat.group_user_list.rslt.length < 2){
            group_remove.push(
                <span style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', margin:'85px 0'}}>
                    <icon.UserX size='30' color='#ff704d' />
                    <h5 style={{color:'#ff704d',fontWeight:'500'}}>No User to Remove</h5>
                </span>
            )
        }
        this.props.chat.group_user_list.rslt.map((remove_user_info)=>{
            var display_user = remove_user_info.display_name
            var icon         = remove_user_info.icon
            var user_id      = remove_user_info._id
            var user_name    = display_user.split("-") //SPLIT NAME AND LOGIN CODE
            if(user_id !== this.state.userId.userId){
                group_remove.push(
                    <chat.checkbox_remove 
                        key={Math.random()}
                        user_name={user_name} 
                        user_id={user_id} 
                        icon={icon} 
                        group_remove_change={this.group_remove_change} 
                        group_remove_usr={this.state.group_remove_usr} 
                    />
                )
            }
            return true
        })

        return(
            <strap.Card size="small" style={{backgroundColor:'#ebebe0',right:'20px',width:'380px',height:'490px',position:'fixed',bottom:'50px',zIndex:'99', overflowY:'auto',overflowX:'hidden',padding:'20px 0px 50px 0px',boxShadow:'0 0 5px #999'}}>
                
                {/*MAIN TAB VIEW */}
                {this.state.toggle_chat_tab ? 
                    <div md='12' sm='12' xs='12' style={{height:'100vh', marginTop:'-20px'}}>
                        {/* SCROLL */}
                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.convoStart = el; }}>
                        </div>
                        {/* HEADER */}
                        <div md='12' sm='12' xs='12' style={{position:'fixed',height:'50px',width:'380px',background:'#ff704d',boxShadow:'0 0 5px #999', borderRadius:'5px 5px 0 0',zIndex:'100'}}>
                            <strap.Row style={{width:'100%',margin:'15px 0 15px 0'}}>
                                <strap.Col md='1' sm='1' xs='1' >
                                    <div style={{margin:'-10px 0'}}>
                                        <Avatar color='white' content={avatar} className='custom-avatar' />
                                    </div>
                                </strap.Col>
                                <strap.Col md='10' sm='10' xs='10' style={{display:'flex', justifyContent:'flex-start', marginLeft:'20px'}}>
                                    <h6 style={{color:'white',marginTop:'2px'}}>{this.state.user_info.name}</h6>
                                </strap.Col>
                            </strap.Row>
                        </div>
                        {/* TAB CONTENT */}
                        <div md='12' sm='12' xs='12' style={{backgroundColor:'#ebebe0', width:'100%',margin:'50px 0 7px 0'}}>
                            <strap.Nav tabs style={{display:'flex', justifyContent:'center',backgroundColor:'white'}}>
                                <strap.NavItem>
                                    <strap.NavLink className={classnames({ active: this.state.activeTab === "1"  })} onClick={() => { this.toggle("1") }} >
                                        Chat
                                    </strap.NavLink>
                                </strap.NavItem>
                                <strap.NavItem>
                                    <strap.NavLink className={classnames({ active: this.state.activeTab === "2" })}  onClick={() => { this.toggle("2") }} >
                                        Contacts
                                    </strap.NavLink>
                                </strap.NavItem>
                            </strap.Nav>
                            <strap.TabContent activeTab={this.state.activeTab}>
                                <strap.TabPane tabId="1">
                                    <strap.Row style={{margin:'0'}}>
                                    <strap.Col md="12" sm='12' xs='12'>
                                        <strap.Row style={{display:'flex',justifyContent:'center'}}>
                                            <strap.Col md='12' sm='12' xs='12' style={{margin:'0 0 10px 0',display:'flex',justifyContent:'center'}}>
                                                <strap.Input placeholder="Search Chat" name='chat_search' autoComplete='off' value={this.state.chat_search} onChange={this.onChatSearch} />
                                            </strap.Col>
                                        </strap.Row>
                                        {this.state.convo_render}
                                    </strap.Col>
                                    </strap.Row>
                                </strap.TabPane>
                                <strap.TabPane tabId="2">
                                    <strap.Row style={{margin:'0'}}>
                                    <strap.Col md='12' sm="12" xs='12'>
                                        <strap.Row>
                                            <strap.Col md='7' sm='7' xs='7'>
                                                <h5 style={{margin:'5px',color:'#ff5c33',display:'flex',justifyContent:'flex-start',fontWeight:'500'}}>Team Members</h5>
                                            </strap.Col>
                                            <strap.Col md='5' sm='5' xs='5' className={`${this.state.show_grp_btn ? "show" : 'hidden'}`}>
                                                <strap.Button color="success" size="sm" style={{ padding:'0',width:'120px',height:'30px', fontSize:'12px',fontWeight:'500'}} onClick={() => this.setState({chat_show:false,toggle_chat_tab:false,grp_create_show:true })}>Create Group</strap.Button>
                                            </strap.Col>
                                        </strap.Row>
                                        <strap.Row style={{display:'flex',justifyContent:'center'}}>
                                            <strap.Col md='12' sm='12' xs='12' style={{margin:'7px 5px 8px 5px',display:'flex',justifyContent:'flex-end'}}>
                                                <strap.Input placeholder="Search Users" name='user_search' autoComplete='off' value={this.state.user_search} onChange={this.onSearch} />
                                            </strap.Col>
                                        </strap.Row>
                                        {this.state.user_list_render}
                                    </strap.Col>
                                    </strap.Row>
                                </strap.TabPane>
                            </strap.TabContent>       
                        </div>
                    </div>
                : null }

                {/* GROUP CREATE */}
                {this.state.grp_create_show ?
                    <div md='12' sm='12' xs='12' style={{height:'100vh', marginTop:'-20px'}}>
                        {/* GROUP HEADER */}
                        <div md='12' sm='12' xs='12' style={{position:'fixed',width:'380px',height:'50px',background:'#ff704d',boxShadow:'0 0 5px #999', borderRadius:'5px 5px 0 0',zIndex:'99'}}>
                            <strap.Row style={{width:'100%',margin:'15px 0 15px 0'}}>
                                <strap.Col md='1' sm='1' xs='1' >
                                    <div style={{margin:'-10px 0'}}><Avatar color='white' content={avatar} /></div>
                                </strap.Col>
                                <strap.Col md='10' sm='10' xs='10' style={{display:'flex', justifyContent:'flex-start', marginLeft:'20px'}} >
                                    <h6 style={{color:'white',marginTop:'2px'}}>{this.state.user_info.name}</h6>
                                </strap.Col>
                            </strap.Row>
                        </div>

                        <div md='12' sm='12' xs='12' style={{backgroundColor:'#ebebe0', width:'100%',margin:'57px 0 7px 0'}}>
                            <strap.Badge style={{padding:'0px',right:'1%',width:'100%',height:'50px',overflow:'hidden',zIndex:'99',background:'#ebebe0'}} >
                                <strap.Row style={{margin:'10px 0 15px 5px'}}>
                                    <strap.Col md='1' sm='1' xs='1' style={{padding:'0'}} onClick={() => this.setState({grp_create_show:false, chat_show:false, toggle_chat_tab: true, activeTab: '2'}) } ><icon.ArrowLeft color='#ff704d' size={18} /></strap.Col>
                                    <strap.Col md='10' sm='10' xs='10' style={{padding:'0'}}><h5 style={{color:'#ff704d'}}><icon.Users /> Create Group</h5></strap.Col>
                                </strap.Row>
                            </strap.Badge>

                            {/* GROUP CREATE FORM */}
                            <strap.Card style={{margin:'-5px 12px 12px 12px', padding:'22px 8px 8px 8px',boxShadow:'0 0 5px #999'}} >
                                <strap.Form id="add_upd_form" name='add_upd_form' style={{padding:'5px'}} onSubmit={(e) => {e.preventDefault();e.stopPropagation();this.createGroup();}}>
                                    <div className="row" >
                                        <strap.Col md="12" sm="12">
                                            <strap.FormGroup>
                                                <strap.Label for="groupName">Group Name</strap.Label>
                                                    <strap.Input
                                                        type="text" 
                                                        name="groupName" 
                                                        className="form-control" 
                                                        id="groupName"  
                                                        placeholder="Group Name"
                                                        autoComplete='off'
                                                        onChange={this.handleInputChange({pro_for:"groupName",pro_as:"input"})}
                                                    />
                                                    <span style={{color:"red",fontSize:"12px"}}>{this.state.form_error.groupName}</span>
                                            </strap.FormGroup>
                                        </strap.Col>
                                        <strap.Col md="12" sm="12" xs='12'>
                                            <strap.FormGroup>
                                                <strap.Label for="groupMember">Group Members</strap.Label>
                                                    <custom.CustomSelect 
                                                        multi             = {true}
                                                        id_name           = {"groupMember"}
                                                        place_holder      = {"-- Select Group Members --"} 
                                                        select_val        = {this.state.group_val.groupMember}
                                                        option            = {this.state.drop_list.groupMember}
                                                        handleInputChange = {this.handleInputChange}
                                                    />
                                                    <span style={{color:"red",fontSize:"12px"}}>{this.state.form_error.groupMember}</span>
                                            </strap.FormGroup>
                                        </strap.Col>
                                        <strap.Col md="12" sm="12" xs='12' style={{display:'flex', justifyContent:'center',marginTop:'-15px'}}>
                                            <strap.Button.Ripple color="success" type="submit" className="mt-1">Create Group</strap.Button.Ripple>
                                        </strap.Col>
                                    </div>
                                </strap.Form>
                            </strap.Card>
                        </div>
                    </div>
                : null }

                {/* CHAT MESSAGE */}
                {this.state.chat_show ?
                    <div md='12' sm='12' xs='12' style={{ marginTop:'-20px'}} onClick={()=> this.setState({msg_detail_toggle:false})} >
                        <div md='12' sm='12' xs='12' style={{position:'fixed',width:'380px',height:'50px',background:'#ff704d',boxShadow:'0 0 5px #999', borderRadius:'5px 5px 0 0',zIndex:'99'}}>    
                            <strap.Row style={{margin:'15px 0 15px 5px'}}>
                                <strap.Col md='1' sm='1' xs='1' style={{padding:'0', display:'flex', justifyContent:'center',margin:'2px 0 0 10px'}}>
                                    <icon.ArrowLeft style={{cursor:'pointer'}} size={18} color='white' onClick={() =>this.onChatBack()} />
                                </strap.Col>
                                <strap.Col md='9' sm='9' xs='9' style={{padding:'0', display:'flex', justifyContent:'flex-start', margin:' 0px 0 0 15px'}}>
                                    <div style={{margin:'-10px 0 -10px -15px'}}>
                                        <div className={`${(this.state.convo_itm.type === "chat") || (this.state.userlist_snd_msg) ? "show" : "hidden" }`} >
                                            {this.state.show_online_user ?
                                                <span style={{margin:'3px',height:'11px',width:'11px',backgroundColor:'#39e600',borderRadius:'50%',display:'inline-block', position:'absolute',zIndex:'99'}} />
                                            :  
                                                <span style={{margin:'3px',height:'11px',width:'11px',backgroundColor:'#ff1a1a',borderRadius:'50%',display:'inline-block', position:'absolute',zIndex:'99',border:'1px solid white'}} /> }
                                        </div>
                                        <Avatar color='white' content={chatAvatar} />
                                    </div>
                                    <h6 style={{color:'white',margin:'3px 0 0 6px'}}>{this.state.chat_name}</h6>
                                </strap.Col>
                                <strap.Col md='1' sm='1' xs='1' style={{padding:'0',textAlign:'left',cursor:'pointer'}}>
                                    <div className={`${((this.state.convo_snd_msg && this.state.convo_itm.type === "group") || (this.state.create_grp_snd_msg)) ? "show" : "hidden" }`}>
                                        <strap.UncontrolledTooltip placement='top' target='menu'>Menu</strap.UncontrolledTooltip>
                                        <icon.Menu id='menu' color='white' size={18} onClick={() => this.grpMenuClick() }/>
                                    </div>
                                </strap.Col>
                            </strap.Row>
                            {this.state.msg_detail_toggle 
                                ?   <div style={{position:'relative', minHeight:'fit-content', maxHeight:'250px', width:'350px', background:'white', borderRadius:'0 0 5px 5px', border:'1px solid #a6a6a6', overflowY:'auto',overflowX:'hidden',zIndex:'95', marginLeft:'7px' }}>
                                        <strap.Row md='12' sm='12' xs='12'>
                                            <strap.Col md='6' sm='6' xs='6' style={{borderRight:'1px solid grey'}}>
                                                <h7 style={{display:'flex',justifyContent:'center', fontWeight:'600', color:'#ff704d', borderBottom:'1px solid grey'}}>Seen User</h7>
                                                {this.state.seen_user}
                                            </strap.Col>
                                            <strap.Col md='6' sm='6' xs='6'>
                                                <h7 style={{display:'flex',justifyContent:'center', fontWeight:'600', color:'#ff704d', borderBottom:'1px solid grey'}}>Unseen User</h7>
                                                {this.state.unseen_user}
                                            </strap.Col>
                                        </strap.Row>
                                    </div> 
                                : null}
                        </div>
                        {/* GRP MENU OPTIONS TAB */}
                        {this.state.menu_show ? 
                            <>
                            <div style={{ float:"left", clear: "both" }}
                                ref={(el) => { this.menuStart = el; }}>
                            </div>
                            <div md='12' sm='12' xs='12' style={{backgroundColor:'#ebebe0', width:'100%',margin:'58px 0 7px 0'}}>
                                <strap.Nav tabs style={{display:'flex', justifyContent:'center',backgroundColor:'white',marginTop:'-7px',width:'380px'}}>
                                    <strap.NavItem>
                                        <strap.NavLink className={classnames({ active: this.state.activeMenuTab === "1"  })} onClick={() => { this.groupMemberList(); this.toggleMenu("1") }} >
                                            Members
                                        </strap.NavLink>
                                    </strap.NavItem>
                                    <strap.NavItem >
                                        <strap.NavLink className={`classnames({ active: this.state.activeMenuTab === "2" }) ${(this.state.convo_itm.createdBy === this.state.userId.userId) || this.state.grp_created_by === this.state.userId.userId ? "show" : "hidden" }`}  onClick={() => { this.toggleMenu("2"); this.addMembers() }} >
                                            Add 
                                        </strap.NavLink>
                                    </strap.NavItem>
                                    <strap.NavItem>
                                        <strap.NavLink className={`classnames({ active: this.state.activeMenuTab === "3" }) ${this.state.convo_itm.createdBy === this.state.userId.userId || this.state.grp_created_by === this.state.userId.userId  ? "show" : "hidden" }`}  onClick={() => { this.toggleMenu("3"); this.removeMembers() }} >
                                            Remove
                                        </strap.NavLink>
                                    </strap.NavItem>
                                    <strap.NavItem>
                                        <strap.NavLink className={`classnames({ active: this.state.activeMenuTab === "4" })  ${(this.state.convo_itm.createdBy === this.state.userId.userId) || this.state.grp_created_by === this.state.userId.userId ? "hidden" : "show" }`} onClick={() => { this.toggleMenu("4") }} >
                                            Leave
                                        </strap.NavLink>
                                    </strap.NavItem>
                                </strap.Nav>
                                <strap.TabContent activeTab={this.state.activeMenuTab}>
                                    <strap.TabPane tabId="1">
                                        <strap.Row style={{margin:'0'}}>
                                        <strap.Col md="12" sm='12' xs='12'>
                                            <h5 style={{margin:'5px', display:'flex', justifyContent:'center', color:'#ff704d'}}>Group Members</h5>
                                            <strap.Row>
                                                <strap.Col md='12' sm='12' xs='12' style={{margin:'5px 0px',display:'flex',justifyContent:'flex-end'}}>
                                                    <strap.Input placeholder="Search Users" name='grp_search' autoComplete='off' style={{width:'380px'}} value={this.state.grp_search} onChange={this.onGrpSearch} />
                                                </strap.Col>
                                            </strap.Row>
                                            {this.state.group_user_list}
                                        </strap.Col>
                                        </strap.Row>
                                    </strap.TabPane>
                                    <strap.TabPane tabId="2">
                                        <strap.Row style={{margin:'0'}}>
                                        <strap.Col md="12" sm='12' xs='12'>
                                            <strap.Row className={`${this.props.chat.user_list.rslt.filter(e => e.isdisabled === false).length > 0 ? 'show' : 'hidden'}`}>
                                                <strap.Col md='8' sm='8' xs='8'><h5 style={{margin:'5px',color:'#ff704d',display:'flex',justifyContent:'flex-start'}}>Add Members</h5></strap.Col>
                                                <strap.Col md='2' sm='2' xs='2'><strap.Button color='success' style={center_item2} onClick={() => this.addUserSubmit()}>ADD</strap.Button></strap.Col>
                                            </strap.Row>
                                            <strap.Row>
                                                <strap.Col md='12' sm='12' xs='12' className={`${this.props.chat.user_list.rslt.filter(e => e.isdisabled === false).length > 0 ? 'show' : 'hidden'}`} style={{margin:'5px 0px',display:'flex',justifyContent:'flex-end'}}>
                                                    <strap.Input placeholder="Search Users" name='add_search' autoComplete='off' style={{width:'380px'}} value={this.state.add_search} onChange={this.onAddSearch} />
                                                </strap.Col>
                                            </strap.Row>
                                            {group_add}
                                        </strap.Col>
                                        </strap.Row>
                                    </strap.TabPane>
                                    <strap.TabPane tabId="3">
                                        <strap.Row style={{margin:'0'}}>
                                        <strap.Col md="12" sm='12' xs='12'>
                                            <strap.Row className={`${this.props.chat.group_user_list.rslt.length > 1 ? 'show' : 'hidden'}`}>
                                                <strap.Col md='8' sm='8' xs='8'><h5 style={{margin:'5px',color:'#ff704d',display:'flex',justifyContent:'flex-start'}}>Remove Members</h5></strap.Col>
                                                <strap.Col md='4' sm='4' xs='4'><strap.Button color='danger' style={chat.center_item} onClick={() => this.removeUserSubmit()}>REMOVE</strap.Button></strap.Col>
                                            </strap.Row>
                                            <strap.Row>
                                                <strap.Col md='12' sm='12' className={`${this.props.chat.group_user_list.rslt.length > 1 ? 'show' : 'hidden'}`} sx='12' style={{margin:'5px 0px',display:'flex',justifyContent:'flex-end'}}>
                                                    <strap.Input placeholder="Search Users" name='remove_search' autoComplete='off' style={{width:'380px'}} value={this.state.remove_search} onChange={this.onRemoveSearch} />
                                                </strap.Col>
                                            </strap.Row>
                                            {group_remove}
                                        </strap.Col>
                                        </strap.Row>
                                    </strap.TabPane>
                                    <strap.TabPane tabId="4">
                                        <strap.Row style={{margin:'0'}}>
                                        <strap.Col md="12" sm='12' xs='12'>
                                            <strap.Card>
                                                <strap.CardBody>
                                                    <strap.CardTitle tag="h5">Leave Group</strap.CardTitle>
                                                    <strap.CardText>Do you want to leave the group ?</strap.CardText>
                                                    <strap.Button style={{width:'140px',marginRight:'5px'}} color='success' onClick={() =>this.exitGroup()}>Yes</strap.Button>
                                                    <strap.Button style={{width:'144px'}} color='danger' onClick={() => this.setState({menu_show:false,chat_bubble_show:true,activeMenuTab:"1" }) }>No</strap.Button>
                                                </strap.CardBody>
                                            </strap.Card>
                                        </strap.Col>
                                        </strap.Row>
                                    </strap.TabPane>
                                </strap.TabContent>
                            </div></>
                        : null }
                        {/* MSG BUBBLE */}
                        {this.state.chat_bubble_show ?
                            <div md='12' sm='12' xs='12' style={{backgroundColor:'#ebebe0', width:'100%',margin:'55px 0 7px 0'}}>
                                {/* CHAT BUBBLE */}
                                <div className ={`${this.state.load_more ? "show" : "hidden" }`} style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0'}}>
                                    <strap.Button color='success' size="sm" style={{width:'150px',height:'20px'}} onClick={()=>this.loadMore()}>
                                        {this.state.btn_loading 
                                            ? <div style={{bottom:'5px'}}><strap.Spinner color='white' size='sm'/></div>
                                            : "Load more message"}
                                    </strap.Button>
                                </div>
                                {this.state.msg_bubble_render}
                                <div style={{ float:"left", clear:  "both" }}
                                    ref={(el) => { this.messagesEnd = el;}}>
                                </div>

                                {/* TYPING */}
                                {(this.state.convo_itm.type === 'chat' && (this.state.typing_valid_id === this.state.convo_itm._id)) ? this.state.typing_rslt : null}
                                {( this.state.userlist_snd_msg && (this.state.typing_valid_id === this.state.user_itm.conversationId) ) ? this.state.typing_rslt : null}
                                {(this.state.convo_itm.type === 'group' && (this.state.grp_typing_valid_id === this.state.convo_itm.groupId)) || this.state.create_grp_snd_msg ? this.state.grp_typing_rslt : null }
                                
                                {/* SEND MSG INPUT */}
                                <div className ="col-md-12 col-sm-12" style={{padding:"0px",bottom:'65px',width:'360px',position:'fixed'}}>
                                    <div>
                                        {this.state.showEmoji ? <Picker onSelect={this.addEmoji} style={{position:'absolute',width:'346px', right:'4px',marginTop:'-405px' }}/> : null } {/* EMOJI */}
                                    </div>
                                    {this.state.preview_condition ? this.state.preview_render : this.state.preview_render} {/* FILE PREVIEW */}
                                    <strap.Form onSubmit={(e)=>{e.preventDefault();this.sendMsg();}}>
                                        <div className="row">
                                            <strap.Col md='10' sm='10' xs='10' style={{padding:"0 0 0 25px"}}>
                                            <strap.FormGroup className="has-icon-left">
                                                <strap.Input type="text" name="inbox" onFocus={()=>{this.typing(true);this.groupTyping(true);}} onBlur={()=>{this.typing(false);this.groupTyping(false);}} autoComplete='off' id="inbox" placeholder="Chat" value={this.state.inbox} onChange={(e) => this.setState({ inbox: e.target.value}) } style={{paddingLeft:'46px', paddingRight:'2px'}} />
                                                <div className="form-control-position" style={{marginLeft:'3px'}}>
                                                    <icon.Smile style={{cursor:'pointer'}} color='#ff704d' size={15} onClick={async() =>{await this.setState({showEmoji: !this.state.showEmoji})}} />
                                                    <label>
                                                        <icon.Paperclip style={{marginLeft:'0',cursor:'pointer'}} color='#ff704d' size={15}/>
                                                        <input type="file" id="file" style={{display:"none"}} name="file" onChange={this.onFileChange}></input>
                                                    </label>
                                                </div>
                                            </strap.FormGroup>
                                            </strap.Col>
                                            <strap.Col md='2' sm='2' xs='2' style={{padding:"0px"}}>
                                                <strap.Button color="primary" type="submit" style={{height:'36px',display:'flex', justifyContent:'center', alignItems:'center'}}><icon.Send size={18}/></strap.Button>
                                            </strap.Col>
                                        </div>
                                    </strap.Form>
                                </div>
                            </div>
                        : null }
                    </div>
                : null} 
            <ToastContainer />
            </strap.Card> 
        )
    }
}

const mapStateToProps = state => {
	return { 
		chat: state.chat,
	}
}

export default connect(mapStateToProps, {get_conversation_action,get_user_list_action,add_update_action,get_message_action,group_user_list_action,group_user_changes_action,exit_group_action,get_msg_details_action,total_unread_messages_action,send_message})(module_manage)