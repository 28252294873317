const initialState = {
	schema_info:[],
	lead_confirm_schema:[],
	check_todo:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	add_update:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	search:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"ncd",
		sort_order:-1
	},
	delete:{
		ids:[],
		sts: "",
		msg:"",
		error:""
	},
	sidebar:{
		sidebar: false,
		pro_mode:"ADD",
		pro_id:null,
	},
	jfw_sidebar:{
		sidebar: false,
		pro_mode:"ADD",
		pro_id:null,
	},
	on_selected:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	log_data:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		review_schema:[],
		log_schema:[],
	},
	appointment_search:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"_id",
		sort_order:-1
	},
	lead_confirm_search:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"_id",
		sort_order:-1
	},
	jfw_search:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"_id",
		sort_order:-1
	},
	prospect_status_update:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	expected_add_update:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	expected_search:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		header:0,
	},
	msg_content:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	msg_log_upd:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
}

const cold_calling = (state = initialState, action) => {
	switch (action.type) {
		case "get_schema":
			return {...state,
				schema_info:action.rslt,
			}
		case "lead_confirm_schema":
			return {...state,
				lead_confirm_schema:action.rslt,
			}
		case "check_todo":
			return {...state,
				check_todo:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "get_common":
			return {...state,
				drop_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "add_update":
			return {...state,
				add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "get_data":
			return {...state,
				add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}		
		case "search":
			return {...state,
				search:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
				}
			}
		case "delete":
			return {...state,
				delete:{
					sts:action.sts,
					msg:action.msg,
					error:action.error
				}
			}
		case "sidebar":
			return {...state,
				sidebar:{
					sts: action.sidebar,
					pro_mode:action.pro_mode,
					pro_id:action.pro_id,
				}
			}
		case "jfw_sidebar":
			return {...state,
				jfw_sidebar:{
					sts: action.sidebar,
					pro_mode:action.pro_mode,
					pro_id:action.pro_id,
				}
			}
		case "on_selected":
			return {...state,
				on_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "log_action":
			return {...state,
				log_data:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,
					log_schema:action.log_schema,
					review_schema:action.review_schema,
				}
			}
		case "appointment_search":
			return {...state,
				appointment_search:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
				}
			}
		case "lead_confirm_search":
			return {...state,
				lead_confirm_search:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
				}
			}
		case "jfw_schema":
			return {...state,
				jfw_schema:action.rslt,
			}
		case "jfw_search":
			return {...state,
				jfw_search:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
				}
			}			
		case "prospect_status_update":
			return {...state,
				prospect_status_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "expected_add_update":
			return {...state,
				expected_add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "expected_search":
			return {...state,
				expected_search:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					header:action.header,
				}
			}
		case "expected_get_data":
			return {...state,
				expected_add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "msg_content":
			return {...state,
				msg_content:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "msg_log_upd":
			return {...state,
				msg_log_upd:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		default:
			return state
	}
}  
export default cold_calling