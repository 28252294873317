import React            from "react"
import * as strap       from "reactstrap"
import { toast }        from "react-toastify"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios            from "axios"
import * as Icon        from "react-feather"
import { history }      from "../../../history"
import { connect }      from "react-redux"
import {logout_action}  from "../../../redux/actions/user/index"
import Avatar           from "../../../components/@vuexy/avatar/AvatarComponent"
import jwt_decode       from "jwt-decode";
import classnames       from "classnames"
import whatsapp         from "../../../assets/img/svg/whatsapp.svg"
import socketConnection from "../../../views/a_common/chat_connection"
import 'bootstrap/dist/css/bootstrap.min.css';
import {todo_count_action,mls_status_count_action}from "../../../redux/actions/reports/index";
import { get_process_env } from "../../../redux/actions/a_common/init"

const api_url = get_process_env().slice(0, -4);

// UDY: REMOVED
var txt_month = ["January","February","March","April","May","June","July","August","September","October","November","December",];
var d         = new Date();
var thisMonth = txt_month[d.getMonth()];

const UserDropdown = ({logoutAction,socketLogout}) => {
  return (
    <strap.DropdownMenu right>
      <strap.DropdownItem tag="a" href="/change_pwd">
        <Icon.Key size={14} className="mr-50" />
        <span className="align-middle">Change Password</span>
      </strap.DropdownItem>
      <strap.DropdownItem divider />
      <strap.DropdownItem tag="a"  href="/two_step_auth">
        <Icon.Lock size={14} className="mr-50" />
        <span className="align-middle">Two Step Auth</span>
      </strap.DropdownItem>
      <strap.DropdownItem divider />
      <strap.DropdownItem tag="a"  href="#"  onClick={() => logoutFunc(logoutAction,socketLogout) }>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </strap.DropdownItem>
    </strap.DropdownMenu>
  )
}

const logoutFunc = async(logoutAction,socketLogout) => {
  logoutAction()
  socketLogout.emit("logout")
  socketLogout.emit("online_user")
}

var today = new Date();
// UDY: NOT USED CHECK & REMOVE 
var date  = String(today.getDate()).padStart(2, '0');
var month = String(today.getMonth() + 1).padStart(2, '0');
var year  = today.getFullYear();
var today_date = date+'-'+month+'-'+year

    
    class NavbarUser extends React.PureComponent {
      state = {
        activeTab    : "1",
        active       : "1",
        navbarSearch : false,
        suggestions  : [],
        user_info    : [],
        serach_info  : [],
        user_name    : "CA",
        user_img     : null,
        isExist      : null,
        user_icon    : [],
        today        : today_date
      }
      
      async componentDidMount() {
        var today   = new Date();
        var toDate  = today.toISOString().split('T')[0];
        const token = localStorage.getItem('token');
        if(token){
          var decoded   = jwt_decode(token);
          var user_name = "CA"
          var user_img  = null
          if(decoded){
            var str     = decoded.name;
            var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            user_name   = matches.join(''); // JSON
            user_img    = decoded.image.slice(9);
        }
      var socketConnect = socketConnection//IMPORTED SOCKET CONNECTION
      this.setState({ 
          user_info        : decoded,
          user_img         : user_img,
          user_name        : user_name.toUpperCase(),
          serach_info :{
            date   : toDate,
            userId : decoded.id,
          },
          todo_show         : false,
          todo_load         : false,
          count_load        : false,
          todo_rlst         : {},
          todo_header       : {},
          pro_mode          : false,
          last_update_day   : '',
          last_update_month : '',
          next_update_day   : '',
          next_update_month : '',
          count_head        : [],
          count_rslt        : [],
          socket            : socketConnect
      })
      this.whatsapp_link = this.whatsapp_link.bind(this);

      var get_required = [
        axios.get("/api/main-search/data").then(({ data }) => {
          this.setState({ suggestions: data.searchResult })
        }),
      ];  
      await Promise.all(get_required).then(results => {});
    }
  }

  toggleTab = tab => {
		if(this.state.activeTab !== tab) {
			this.setState({ activeTab: tab })
		}
	}

	toggle = tab => {
		if(this.state.active !== tab) {
			this.setState({ active: tab })
		}
	}

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  haldelListView(pro_data){
    this.setState({todo_load:true,todo_show:false})
    this.props.todo_count_action(this.state.serach_info).then(()=>{
      var todo_info = this.props.reports.todo_count
      if(todo_info.sts){
        this.setState({
            todo_show         : true,
            todo_load         : false,
            todo_rlst         : todo_info.rslt,
            todo_header       : todo_info.header,
            pro_mode          : todo_info.pro_mode,
            last_update_day   : todo_info.rslt.time.last_update_day,
            last_update_month : todo_info.rslt.time.last_update_month,
            next_update_day   : todo_info.rslt.time.next_update_day,
            next_update_month : todo_info.rslt.time.next_update_month
        });
      }else{
        this.setState({
          todo_load : false,
          todo_show : false,
        });
        toast.error(todo_info.msg,{
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }     
    }).catch(function(error){
      this.setState({todo_load:false,});
      toast.error("Unable to process, refresh and retry",{
        position: toast.POSITION.BOTTOM_RIGHT
      })
    });
  }

  // MLS COUNT
  mlsStatusCount(pro_data){
    this.setState({count_load:true})
    this.props.mls_status_count_action(this.state.serach_info).then(()=>{
      var review_count = this.props.reports.review_count
      if(review_count.sts){
        this.setState({
          count_load : false,
          count_rslt : review_count.rslt
        })
      }  
    }).catch(function(error){
      this.setState({ count_load:false,})
      toast.error("Unable to process, refresh and retry",{
        position: toast.POSITION.BOTTOM_RIGHT
      })
    });
  }

  makeid(length) {
		var result           = [];
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for(var i=0; i<length;i++){
			result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
		}
		return result.join('');
	}

  async whatsapp_link(){
    var todo_rslt  = this.state.todo_rlst;
    var today_rslt = todo_rslt.today_rslt;
    var month_rslt = todo_rslt.month_rslt;
    //today var
    var today_fresh_met_commit   = '';
    var today_fresh_met_achieve  = '';
    var today_jfw_commit         = '';
    var today_jfw_achieve        = '';
    var today_lifebuiz_commit    = '';
    var today_lifebuiz_achieve   = '';
    var today_healthbuiz_commit  = '';
    var today_healthbuiz_achieve = '';
    //month var
    var month_fresh_met_commit   = '';
    var month_fresh_met_achieve  = '';
    var month_jfw_commit         = '';
    var month_jfw_achieve        = '';
    var month_lifebuiz_commit    = '';
    var month_lifebuiz_achieve   = '';
    var month_healthbuiz_commit  = '';
    var month_healthbuiz_achieve = '';
    Object.values(today_rslt).map( item => {
      if(item.info === 'Fresh Met'){
        today_fresh_met_commit  = item.committed;
        today_fresh_met_achieve = item.achieved;
        }
      if( item.info === 'JFW'){
         today_jfw_commit  = item.committed
         today_jfw_achieve = item.achieved
      }
      if( item.info === 'Life Buiz'){
        today_lifebuiz_commit  = item.committed
        today_lifebuiz_achieve = item.achieved
      }
      if(item.info === 'Health Buiz'){
        today_healthbuiz_commit  = item.committed
        today_healthbuiz_achieve = item.achieved
      }
      return true
    })

    Object.values(month_rslt).map( item => {
      if(item.info === 'Fresh Met'){
        month_fresh_met_commit  = item.committed;
        month_fresh_met_achieve = item.achieved;
        }
      if( item.info === 'JFW'){
         month_jfw_commit  = item.committed
         month_jfw_achieve = item.achieved
      }
      if( item.info === 'Life Buiz'){
        month_lifebuiz_commit  = item.committed
        month_lifebuiz_achieve = item.achieved
      }
      if(item.info === 'Health Buiz'){
        month_healthbuiz_commit  = item.committed
        month_healthbuiz_achieve = item.achieved  
      }
      return true
    })
    window.open(`https://api.whatsapp.com/send?text=${this.state.user_info.name +" - "+ this.state.user_info.user_designation}%0A %0AToday%20(${this.state.today})%0AFresh%20Met%0AComitted%3A%20${today_fresh_met_commit}%3B%20%0AAchieved%3A%20${today_fresh_met_achieve}%3B%0A%0AJFW%20%20%20%20%20%20%20%20%20%0AComitted%3A%20${today_jfw_commit}%3B%0AAchieved%3A%20${today_jfw_achieve}%3B%0A%0ALife%20Buiz%20%20%20%20%0AComitted%3A%20${today_lifebuiz_commit}%3B%20%0AAchieved%3A%20${today_lifebuiz_achieve}%3B%0A%0AHealth%20Buiz%20%20%0AComitted%3A%20${today_healthbuiz_commit}%3B%20%0AAchieved%3A%20${today_healthbuiz_achieve}%3B%0A%0AMonth%20(${thisMonth})%0AFresh%20Met%20%20%20%20%0AComitted%3A%20${month_fresh_met_commit}%3B%0AAchieved%3A%20${month_fresh_met_achieve}%3B%0A%0AJFW%20%20%20%20%20%20%20%20%20%0AComitted%3A%20${month_jfw_commit}%3B%20%0AAchieved%3A%20${month_jfw_achieve}%3B%0A%0ALife%20Buiz%20%20%20%20%0AComitted%3A%20${month_lifebuiz_commit}%3B%20%0AAchieved%3A%20${month_lifebuiz_achieve}%3B%0A%0AHealth%20Buiz%20%20%0AComitted%3A%20${month_healthbuiz_commit}%3B%20%0AAchieved%3A%20${month_healthbuiz_achieve}%3B`);
  }
  
  render() {   
    let todo_th          = [];
    let todo_2ndth       = [];
    let todo_body        = [];
    let todo_month_body  = [];
    if(this.state.todo_show){
      var todo_header = this.state.todo_header
      if(todo_header.length>0){
        todo_header.map(head_val => {
          var name       = head_val.name
          var sub_header = head_val.sub_header
          var todo_col = 0;
          if(sub_header){
            todo_col = Object.keys(sub_header).length
            Object.keys(sub_header).map(function(sub_key) {
              todo_2ndth.push(<th key={this.makeid(5)}>{sub_header[sub_key]}</th>)
              return true
            }.bind(this));
            todo_th.push(
              <th colSpan={todo_col} key={this.makeid(5)}>{name}</th>
            )
          }else{
            todo_th.push(
              <th rowSpan="2" key={this.makeid(5)}>{name}</th>
            )
          }
          return true
        })
      }
      todo_th   = <thead style={{fontWeight:"500"}}>
                    <tr key={this.makeid(5)}>{todo_th}</tr>
                    <tr key={this.makeid(5)}>{todo_2ndth}</tr>
                  </thead>
      var todo_rslt  = this.state.todo_rlst
      var today_rslt = todo_rslt.today_rslt
      var month_rslt = todo_rslt.month_rslt
      //month_rslt,today_rslt
     todo_body = Object.values(today_rslt).map((row_info,td_count) =>{
        var hide_info  = row_info.hide_info
        var info       = row_info.info
        var committed  = row_info.committed
        var achieved   = row_info.achieved
        var can_show   = false
        if(this.state.pro_mode){
          if(hide_info[this.state.pro_mode]){
            can_show   = hide_info[this.state.pro_mode]
          }
        }
        if(can_show){
          return (<tr key={this.makeid(5)}>
                    <td key={this.makeid(5)}>{info}</td>
                    <td key={this.makeid(5)}>{committed}</td>
                    <td key={this.makeid(5)}>{achieved}</td>
                  </tr>)
        }
        return true
      })

      todo_month_body = Object.values(month_rslt).map((row_info,td_count) =>{
        var hide_info  = row_info.hide_info
        var info       = row_info.info
        var committed  = row_info.committed
        var achieved   = row_info.achieved
        var can_show   = false
        if(this.state.pro_mode){
          if(hide_info[this.state.pro_mode]){
            can_show   = hide_info[this.state.pro_mode]
          }          
        }
        if(can_show){
          return (<tr key={this.makeid(5)}>
                    <td key={this.makeid(5)}>{info}</td>
                    <td key={this.makeid(5)}>{committed}</td>
                    <td key={this.makeid(5)}>{achieved}</td>
                  </tr>)
        }
        return true
      })
    }
    todo_body       = <tbody key={this.makeid(5)}>{todo_body}</tbody>
    todo_month_body = <tbody key={this.makeid(5)}>{todo_month_body}</tbody>
    
    //mls count
    var count_rlst = this.state.count_rslt
    var count_body = []
    var count_head = []
    if(count_rlst){
      count_rlst.map((row_info,row_count)=>{
        var mls    = row_info.MLS
        var count  = row_info.Count
        count_head =  Object.keys(row_info).map((row_head) =>{
          if(row_head !=="mls_id"){
              return( <th  key={this.makeid(5)}>{row_head}</th> )
          }else{
            return  true
          }
        })
        count_body.sort((a, b) => ((a.count) > (b.count)) ? -1 : 1)
          count_body.push( 
                  <tr key={this.makeid(5)}>
                  <td key={this.makeid(5)}>{mls}</td>
                  <td key={this.makeid(5)}>{count}</td>
                  </tr>
          )
          return true
      })
    }
    
      count_head = <thead key={this.makeid(5)}><tr key={this.makeid(5)}>{count_head}</tr></thead>
      count_body = <tbody key={this.makeid(5)}>{count_body}</tbody>
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <strap.UncontrolledDropdown  tag="li" className="dropdown-notification nav-item"  >
          <strap.DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <strap.Badge pill color="primary" className="badge-up"> {" "}1{" "}</strap.Badge>
          </strap.DropdownToggle>
          <strap.DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">1 New</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar  className="media-list overflow-hidden position-relative" options={{ wheelPropagation: false }} >
              <div className="d-flex justify-content-between">
                <strap.Media className="d-flex align-items-start">
                  <strap.Media left href="#">
                    <Icon.File className="font-medium-5 warning" size={21} />
                  </strap.Media>
                  <strap.Media body>
                    <strap.Media heading className="warning media-heading" tag="h6">
                      Generate monthly report
                    </strap.Media>
                    <p className="notification-text">
                      Reminder to generate monthly report
                    </p>
                  </strap.Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Last month
                    </time>
                  </small>
                </strap.Media>
              </div>
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <strap.DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">Read all notifications</span>
              </strap.DropdownItem>
            </li>
          </strap.DropdownMenu>
        </strap.UncontrolledDropdown>
        <strap.UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <strap.DropdownToggle tag="a" className="nav-link nav-link-label" onClick={()=>{this.mlsStatusCount()}}>
            <Icon.Eye size={21} />
            <strap.Badge pill color="primary" className="badge-up" />
          </strap.DropdownToggle>
          <strap.DropdownMenu tag="ul" right className="dropdown-menu-media">
            {this.state.count_load ? 
                  <div className="col-md-12" style={{padding:"30px",textAlign:"center"}}>
                    <strap.Spinner color='primary' size='md' />
                    <p>Please wait... We are fetching your MLS status</p>
                  </div>
                :
                <div>
                    <p className="text-primary" style={{margin:"0px"}}>Met review count</p>
                    <table className='table table-sm table-bordered table-hover table-fixed' style={{textAlign:"center",marginTop:"5px"}}>
                      {count_head}
                      {count_body}
                    </table>
                </div>
            }
          </strap.DropdownMenu>
        </strap.UncontrolledDropdown>
        <strap.UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <strap.DropdownToggle tag="a" className="nav-link nav-link-label" onClick={() => {
							this.haldelListView({pro_for:"todo_count"})
					}}>
              <Icon.CheckSquare size={21} />
          </strap.DropdownToggle>
          <strap.DropdownMenu right className="dropdown-menu-media">
            <div className="row">
              {this.state.todo_load ? 
                <div className="col-md-12" style={{padding:"30px",textAlign:"center"}}>
                  <strap.Spinner color='primary' size='md' />
                  <p>Please wait... We are  fetching your committed info</p>
                </div>
              :
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-10">
                      <p className="text-primary" style={{fontSize:'14px',marginTop:"13px"}}>{this.state.user_info.name +" - "+ this.state.user_info.user_designation}</p>
                      </div>
                      <div className="col-md-2" style={{display:'flex', justifyContent:'flex-end'}}>
                      <img src={whatsapp} alt="whatsapp" style={{cursor:'pointer'}} width="40" height="40" onClick={this.whatsapp_link}/>
                    </div>
                  </div>
                    <strap.Nav tabs style={{marginBottom:"0px"}}>
                      <strap.NavItem >
                        <strap.NavLink className={classnames({ active: this.state.active === "1"  })} onClick={() => { this.toggle("1") }} >Today</strap.NavLink>
                      </strap.NavItem>
                      <strap.NavItem>
                        <strap.NavLink className={classnames({ active: this.state.active === "2" })}  onClick={() => { this.toggle("2")  }} >Month</strap.NavLink>
                      </strap.NavItem>
                    </strap.Nav>
                    <strap.TabContent className="py-50" activeTab={this.state.active} style={{padding:"0px !important"}}>                     
									    <strap.TabPane tabId="1">
                        <table className="table table-sm table-bordered table-hover table-fixed" style={{textAlign:"center"}}>
                            {todo_th}
                            {todo_body}
                        </table>
                        <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{color:'red',marginBottom:'15px'}}>- Last update date & time : <b>{this.state.last_update_day}</b></div>
                        <div style={{color:'green'}}>- Next update date & time : <b>{this.state.next_update_day}</b> </div>
                        </div>
                      </strap.TabPane>
                      <strap.TabPane tabId="2">
                        <table className="table table-sm table-bordered table-hover table-fixed" style={{textAlign:"center"}}>
                            {todo_th}
                            {todo_month_body}
                        </table>
                        <div style={{display:'flex', flexDirection: 'column'}}>
                        <div style={{color:'red',marginBottom:'15px'}}>- Last update date & time : <b>{this.state.last_update_month}</b></div>
                        <div style={{color:'green'}}>- Next update date & time : <b>{this.state.next_update_month}</b> </div>
                        </div>
                      </strap.TabPane>
                    </strap.TabContent>
                </div>
              }
            </div>
          </strap.DropdownMenu>
        </strap.UncontrolledDropdown>
        <strap.UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <strap.DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.state.user_info.loginCode}
              </span>
              <span className="user-status">Available</span>
            </div>
            <span data-tour="user">
              {this.state.user_img ? 
                <Avatar  img={api_url+this.state.user_img} />
              :
                <Avatar color="primary" className="mr-1" content={this.state.user_name}/>
              }
            </span>
          </strap.DropdownToggle>
          <UserDropdown logoutAction={this.props.logout_action} socketLogout={this.state.socket} />
        </strap.UncontrolledDropdown>
      </ul>
    )
  }
}
const mapStateToProps = state => {
  return {
    user    : state.user,
    reports : state.reports,
  }
}
export default connect(mapStateToProps, {
  logout_action,
  todo_count_action,
  mls_status_count_action,
  history ,
})(NavbarUser)