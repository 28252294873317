const initialState = {
	schema_info:[],
    drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[],
		userId:"",
	},
    on_selected:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	search:{
		sts:"",
		msg:"",
		loading:true,
		rslt:[],
		header:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"_id",
		sort_order:-1,
		error:{},
		userId:"",
		user_mode:"",
	},
}

const churning_log = (state = initialState, action) => {
	switch (action.type) {
		case "get_schema":
			return {...state,
				schema_info:action.rslt,
			}
            case "drop_list":
                return {...state,
                    drop_list:{
                        sts:action.sts,
                        msg:action.msg,
                        rslt:action.rslt,
                        error:action.error,
                        userId:action.userId,
                    }
            }
            case "on_selected":
			return {...state,
				on_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "search":
			return {...state,
				search:{
					sts:action.sts,
					msg:action.msg,
					error:action.error,
					loading:false,	
					rslt: action.rslt,
					header:action.header,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
					userId:action.userId,
					user_mode:action.user_mode,
				}
			}
            default:
			return state
	}
}  
export default churning_log    
        