const initialState = {
    schema_info:[],
	drop_list:{
		sts:"",
		msg:"",
		rslt:[],
		error:[]
	},
	add_update:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	search:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		search_value:"",
		page_count:1,
		per_page :10,
		sort_by:"ncd",
		sort_order:-1
	},
	delete:{
		ids:[],
		sts: "",
		msg:"",
		error:""
	},
	sidebar:{
		sidebar: false,
		pro_mode:"ADD",
		pro_id:null,
	},
	on_selected:{
		sts:"",
		msg:"",
		rslt:"",
		error:""
	},
	log_data:{
		sts:"",
		msg:"",
		loading:false,
		rslt:[],
		total_count:0,
		review_schema:[],
		log_schema:[],
	},
	msg_content:{
		sts:"",
		msg:"",
		rslt:{},
		error:{}
	},
	user_mode:{
		sts:"",
		msg:"",
		rslt:"",
		error:[],
	},
	msg_log_upd:{
		sts:"",
		msg:"",
		rslt:"",
		error:[],
	}
}

const b2b = (state = initialState, action) => {
	switch (action.type) {
        case "get_schema":
			return {...state,
				schema_info:action.rslt,
			}
		case "get_common":
			return {...state,
				drop_list:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "add_update":
			return {...state,
				add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "get_data":
			return {...state,
				add_update:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
        case "sidebar":
            return {...state,
                sidebar:{
                    sts: action.sidebar,
                    pro_mode:action.pro_mode,
                    pro_id:action.pro_id,
                }
            }
		case "search":
			return {...state,
				search:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,	
					search_value: action.search_value,
					page_count:action.page_count,
					per_page :action.per_page, 
					sort_by:action.sort_by,
					sort_order:action.sort_order,
				}
			}
		case "on_selected":
			return {...state,
				on_selected:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "log_action":
			return {...state,
				log_data:{
					sts:action.sts,
					msg:action.msg,
					loading:false,	
					rslt: action.rslt,
					total_count:action.total_count,
					log_schema:action.log_schema,
					review_schema:action.review_schema,
				}
			}
		case "msg_content":
			return {...state,
				msg_content:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}
		case "user_mode":
			return {...state,
				user_mode:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}		
		case "msg_log_upd":
			return {...state,
				msg_log_upd:{
					sts:action.sts,
					msg:action.msg,
					rslt:action.rslt,
					error:action.error
				}
			}		
		default:
			return state
	}
}  
export default b2b