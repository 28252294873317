
// INPUT ON CHANGE PROCESS
export function processChange(pro_data,event,form_data,drop_list){
    var pro_for   = pro_data.pro_for;
	var pro_as    = pro_data.pro_as;
    if(pro_as === "input"){
        form_data[pro_for] = event.target.value;
    }else
    if(pro_as === "select"){
        form_data[pro_for] = event.value;
    }else
    if(pro_as === "mulit_select"){
        var select_ids = [];
        if(event){
            event.map((event_val, i) => {
                if(event_val.value === "all"){
                    select_ids = []
                    drop_list[pro_for].map((drop_val, m) => {
                        if((drop_val.value) && (drop_val.value !== "all")){
                            select_ids.push(drop_val.value)
                        }
                        return true
                    });
                }else{
                    select_ids.push(event_val.value)
                }
                return true
            })
        }			
        form_data[pro_for] = select_ids;
    }
    return form_data
}

export function makeid(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for(var i=0; i<length;i++){
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
}

// HTML TABLE SORT
export const table_sort=(sort_config,search_rslt,render_table,select_tbl)=>{
    var elements = document.querySelectorAll(".thSort");
	elements.forEach((el)=> el.addEventListener("click", (event)=>{
		var dataset  	= event.currentTarget.dataset;
		var key 	 	= dataset.subKey;
		var head_key 	= dataset.headerVal;
		var no_sort  	= dataset.noSort.split(",");
		var no_sort_col = dataset.noSortCol;
		var data_type   = dataset.type;
		var tbl_select  = dataset.tblSelect;
		var sorted_data = search_rslt;
		var call_back   = render_table;
		// for jfw tracker module to select which table to sort
		if(tbl_select){
			sorted_data = search_rslt[tbl_select]
			call_back   = render_table[tbl_select]
		}
		var direction = "ascending";
		// if table has subheader
		if(key){
			if(sort_config && sort_config.key === key && sort_config.direction === "ascending"){
				direction = "descending"
			}
			sorted_data.sort((a,b) => {
				if(no_sort_col && !no_sort.includes(a[no_sort_col]) && !no_sort.includes(b[no_sort_col])){
					if(a[head_key][key].value === null || a[head_key][key].value === ""){
						return 1;
					}
					if(b[head_key][key].value === null || b[head_key][key].value === ""){
						return -1;
					}
					if(a[head_key][key] === null || a[head_key][key] === "" || a[head_key][key] === "-"){
						return 1;
					}
					if(b[head_key][key] === null || b[head_key][key] === "" || b[head_key][key] === "-"){
						return -1;
					}
					// if data is want to accessed from value
					if(a[head_key][key].value!==undefined && b[head_key][key].value!==undefined){
						if(a[head_key][key].value!==null && b[head_key][key].value!==null){
							if(data_type==="number"){
								if(Number(a[head_key][key].value) < Number(b[head_key][key].value)){
									return direction === "ascending" ? -1 : 1;
								}
								if(Number(a[head_key][key].value) > Number(b[head_key][key].value)){
									return direction === "ascending" ? 1 : -1;
								}
							}else
							if(data_type==="date"){
								const a_date = a[head_key][key].value.split("-").reverse().join("-");
								const b_date = b[head_key][key].value.split("-").reverse().join("-");
								if(new Date(a_date) < new Date(b_date)){
									return direction === 'ascending' ? -1 : 1;
								}
								if(new Date(a_date) > new Date(b_date)){
									return direction === 'ascending' ? 1 : -1;
								}
							}else{
								if(a[head_key][key].value < b[head_key][key].value){
									return direction === "ascending" ? -1 : 1;
								}
								if(a[head_key][key].value > b[head_key][key].value){
									return direction === "ascending" ? 1 : -1;
								}
							}
						}
					}else{
						if(a[head_key][key]!==null && b[head_key][key]!==null){
							if(data_type==="number"){
								if(Number(a[head_key][key]) < Number(b[head_key][key])){
									return direction === "ascending" ? -1 : 1;
								}
								if(Number(a[head_key][key]) > Number(b[head_key][key])){
									return direction === "ascending" ? 1 : -1;
								}
							}else
							if(data_type==="date"){
								const a_date = a[head_key][key].split("-").reverse().join("-");
								const b_date = b[head_key][key].split("-").reverse().join("-");
								if(new Date(a_date) < new Date(b_date)){
									return direction === 'ascending' ? -1 : 1;
								}
								if(new Date(a_date) > new Date(b_date)){
									return direction === 'ascending' ? 1 : -1;
								}
							}else{
								if(a[head_key][key] < b[head_key][key]){
									return direction === "ascending" ? -1 : 1;
								}
								if(a[head_key][key] > b[head_key][key]){
									return direction === "ascending" ? 1 : -1;
								}
							}
						}
					}
				}
				return 0;
			})
		}else{
			// for table with one header
			if(sort_config && sort_config.head_key === head_key && sort_config.direction === "ascending"){
				direction = "descending"
			}
			sorted_data.sort((a, b) => {
				if(no_sort_col && !no_sort.includes(a[no_sort_col]) && !no_sort.includes(b[no_sort_col])){
					if(a[head_key] === null || a[head_key] === "-" || a[head_key] === undefined || a[head_key] === ""){
						return 1;
					}
					if(b[head_key] === null || b[head_key] === "-" || b[head_key] === undefined || b[head_key] === ""){
						return -1;
					}
					// for table with one header want to access data from value
					if(a[head_key].value!==undefined && b[head_key].value!==undefined){
						if(a[head_key].value !==null && b[head_key].value!==null && head_key!=="info"){
							if(data_type==="number"){
								if(Number(a[head_key].value) < Number(b[head_key].value)){
									return direction === "ascending" ? -1 : 1;
								}
								if(Number(a[head_key].value) > Number(b[head_key].value)){
									return direction === "ascending" ? 1 : -1;
								}
							}else
							if(data_type==="date"){
								const a_date = a[head_key].value.split("-").reverse().join("-");
								const b_date = b[head_key].value.split("-").reverse().join("-");
								if(new Date(a_date) < new Date(b_date)){
									return direction === 'ascending' ? -1 : 1;
								}
								if(new Date(a_date) > new Date(b_date)){
									return direction === 'ascending' ? 1 : -1;
								}
							}else{
								if(a[head_key].value && b[head_key].value && (a[head_key].value.toLowerCase() < b[head_key].value.toLowerCase())){
									return direction === 'ascending' ? -1 : 1;
								}
								if(a[head_key].value && b[head_key].value && (a[head_key].value.toLowerCase() > b[head_key].value.toLowerCase())){
									return direction === 'ascending' ? 1 : -1;
								}
							}
						}
					}else{
						if(a[head_key] !==null && b[head_key]!==null && head_key!=="info"){
							if(data_type==="number"){
								if(Number(a[head_key]) < Number(b[head_key])){
									return direction === "ascending" ? -1 : 1;
								}
								if(Number(a[head_key]) > Number(b[head_key])){
									return direction === "ascending" ? 1 : -1;
								}
							}else
							if(data_type==="date"){
								const a_date = a[head_key].split("-").reverse().join("-");
								const b_date = b[head_key].split("-").reverse().join("-");
								if(new Date(a_date) < new Date(b_date)){
									return direction === 'ascending' ? -1 : 1;
								}
								if(new Date(a_date) > new Date(b_date)){
									return direction === 'ascending' ? 1 : -1;
								}
							}else{
								if(a[head_key] && b[head_key] && (a[head_key].toLowerCase() < b[head_key].toLowerCase())){
									return direction === 'ascending' ? -1 : 1;
								}
								if(a[head_key] && b[head_key] && (a[head_key].toLowerCase() > b[head_key].toLowerCase())){
									return direction === 'ascending' ? 1 : -1;
								}
							}
						}
					}
				}
				return 0;
			});
		}
		if(select_tbl===tbl_select){
			call_back({key,direction,head_key},sorted_data)
		}
	}))
}