import React from "react"
import * as strap from "reactstrap"
import * as icon from "react-feather"
import ScrollToTop from "react-scroll-up"
import { Button } from "reactstrap"
import { Heart, ArrowUp } from "react-feather"
import classnames from "classnames"
import {connect} from "react-redux"
import Chat from "../../../views/chat"
import {total_unread_messages_action} from '../../../redux/actions/chat'
import jwt_decode from "jwt-decode";
import  * as chat from "../../../views/chat/chat_component"
import socketConnection from "../../../views/a_common/chat_connection"


class Footer extends React.Component{
    constructor(props){
    super(props);
        
    const token = localStorage.getItem('token');
    var decoded = {}
    if(token){
        decoded = jwt_decode(token);
    }
    var socket = socketConnection
    
    this.state ={
      decoded: decoded,
      total_count: 0,
      socket:socket,
    }
  }

  async componentDidMount(){
    this.get_total_unread();
    if(this.state.decoded.id && this.state.socket !== null){
      await this.state.socket.on("total_unread_messages", (count) => this.total_unread_messages(count))
    }
  }

  //SOCKET FUCTION
  async total_unread_messages(count){
    await this.setState({total_count:count.rslt})
  }

  async get_total_unread(){
    var user = {};
    user.userId = this.state.decoded.id
    this.props.total_unread_messages_action(user).then(results => {
      this.setState({total_count:this.props.chat.total_unread_messages.rslt})
    })
  }

  render(){
    let footerTypeArr = ["sticky", "static", "hidden"]
    return (
        <footer
            className={classnames("footer footer-light", {
            "footer-static": this.props.footerType === "static" || !footerTypeArr.includes(this.props.footerType),
            "d-none": this.props.footerType === "hidden"
            })}
        >
        <p className="mb-0 clearfix">
          <span className="float-md-left d-block d-md-inline-block mt-25">
            COPYRIGHT © {new Date().getFullYear()}
            <a href="https://cafsinfotech.com/" target="_blank"  rel="noopener noreferrer">CafsInfotech</a>
            All rights reserved
          </span>
          <span className="float-md-right d-none d-md-block">
            <span className="align-middle">Made with</span>{" "}
            <Heart className="text-danger" size={15} />
          </span>
        </p>

        <div style={{position:"relative",bottom:"12px",zIndex:999,float:"right",right:"95px"}}>
            <ChatIcon total={this.state.total_count} />
        </div>

        {this.props.hideScrollToTop === false ? (
          <ScrollToTop showUnder={160}>
            <Button color="primary" className="btn-icon scroll-top">
              <ArrowUp size={15} />
            </Button>
          </ScrollToTop>
        ) : null}
      </footer>
  )}
}

//CHAT ICON - RENDER
class ChatIcon extends React.Component{
  constructor(props){
    super(props)
      this.state={
        active:false,
        flow:1,
      }
      this.getChat = this.getChat.bind(this)
  }

  getChat(){
    if(this.state.flow===1){
      if(chat.style.show){
        this.setState({flow:2, active: true });
      }
    } 
    if(this.state.flow===2){
      if(chat.style.showNo){
        this.setState({flow:1,active:false})
      }
    }
  }
  render(){
    var total_count = [];
    if(this.props.total>0){
      total_count = <strap.Badge pill color="primary" className="badge-up" style={{margin:'5px 12px 0 0 '}}>{this.props.total}</strap.Badge>
    }
    return(
      <div>
        <div style={this.state.active === true ? chat.style.show : chat.style.showNo}>
          <Chat active={this.state.active}/>
        </div>
        <Button color="success" className={`mr-1 mb-1 rounded-circle `} onClick={this.getChat} style={{position:"fixed",bottom:"12px",zIndex:999,float:"left",}} >
          {this.state.active === true ? <icon.XCircle size={21} /> : <icon.MessageCircle size={21} />}
          {total_count}
        </Button>
      </div>
    )
  }
}
const mapStateToProps = state => {
	return {
		chat: state.chat,
	}
}

export default connect(mapStateToProps,{total_unread_messages_action})(Footer)
